import { get } from 'lodash-es';

// eslint-disable-next-line import/prefer-default-export
export const SME_SHIPPER_FORM_SEGMENTS = {
  DETAILS_FORM: 0,
  WAREHOUSE_LOCATION: 1,
  REVIEW: 2
};

export const CONTRACT_DOCUMENT_TYPE = {
  CONTRACT_WORK_ORDER: 'Work Order',
  CONTRACT_AGREEMENT: 'Agreement'
};

export const RETURN_ADDRESS_OPTIONS = [
  { label: 'Company Address', value: 'Company Address' },
  { label: 'Billing Address', value: 'Billing Address' },
  { label: 'Other', value: 'others' },
];

export const SOURCE_LEADS_OPTIONS = [
  { label: 'Telesales', value: 'TELESALES' },
  { label: 'Canvasser', value: 'CANVASSER' },
  { label: 'Project Telesales', value: 'PROJECT_TELESALES' },
  { label: 'Project Canvasser', value: 'PROJECT_CANVASSER' },
  { label: 'Internal Referral', value: 'INTERNAL_REFERRAL' },
];

export const INDUSTRY = [
  { label: 'Automotif', value: 'Automotif' },
  { label: 'Bahan Daur Ulang', value: 'Bahan Daur Ulang' },
  { label: 'Bangunan', value: 'Bangunan' },
  { label: 'Cosmetic/kecantikan', value: 'Cosmetic/kecantikan' },
  { label: 'Elektronik', value: 'Elektronik' },
  { label: 'Furniture', value: 'Furniture' },
  { label: 'FMCG', value: 'FMCG' },
  { label: 'Hotel', value: 'Hotel' },
  { label: 'Kayu', value: 'Kayu' },
  { label: 'Kesehatan', value: 'Kesehatan' },
  { label: 'Logistic', value: 'Logistic' },
  { label: 'Pakaian & Aksesoris', value: 'Pakaian & Aksesoris' },
  { label: 'Pertanian', value: 'Pertanian' },
  { label: 'Pribadi', value: 'Pribadi' },
  { label: 'Textile & Garment', value: 'Textile & Garment' },
  { label: 'Lainnya', value: 'others' }
];

export const PAYMENTS_SCHEME = [
  {
    label: 'Split_Payment (200k upfront via Manual)',
    value: 'DP Rp 200,000 (pelunasan di muat)'
  },
  {
    label: 'Split_Destination (200k upfront via Manual)',
    value: 'DP Rp 200,000 (pelunasan di bongkar)'
  },
  {
    label: 'Full_Payment, 100% Upfront (Manual)',
    value: '100% di Muka'
  },
  {
    label: 'Origin_Payment, 100% After Loading (Manual)',
    value: '100% di Muat'
  },
  {
    label: 'Destination_Payment, 100% After Unloading (Manual)',
    value: '100% di Bongkar'
  },
  {
    label: '50:50_Payment (Origin - Destination)',
    value: '50% di Muat - 50% di Bongkar'
  },
  {
    label: 'Pembayaran Invoice',
    value: 'invoice'
  }
];

export const SHIPPER_TYPES = ['company', 'individual'];

export const AGREEMENT_STATUS_TYPE = {
  AGREEMENT_SIGNED_BY_BOTH: 'Agreement Signed by Both',
  AGREEMENT_SIGNED_BY_KARGO: 'Agreement Signed by Kargo',
  DRAFT_AGREEMENT: 'Draft Agreement',
  QUOTATION_SIGNED_BY_BOTH: 'Quotation Signed by Both',
  QUOTATION_SIGNED_BY_KARGO: 'Quotation Signed by Kargo',
  UNDOCUMENTED: 'Undocumented',
  WORK_ORDER_SIGNED_BY_BOTH: 'Work Order Signed by Both',
  WORK_ORDER_SIGNED_BY_KARGO: 'Work Order Signed by Kargo'
};

export const TERM_OF_PAYMENT = {
  CALENDAR_DAYS: 'Calendar Days',
  WORKING_DAYS: 'Working Days'
};

export const PAYMENT_METHOD_OPTIONS = {
  CASH: 'Cash',
  CHECK: 'Check',
  BANK_TRANSFER: 'Bank Transfer',
  GIRO: 'Giro'
};

export const CLAIMS_TYPE = [
  'claimDamage',
  'claimMissing',
  'claimPackagingDamage',
  'claimNearExpiryDate',
  'claimWrongGoods',
  'claimTooMany',
  'claimTooFew'
];

export const CUSTOMER_PIC_TYPE = {
  LOGISTICS: 'Logistics',
  PROCUREMENT: 'Procurement',
  FINANCE: 'Finance',
  TREASURY: 'Treasury',
  CLAIMS: 'Claims'
};

export const CUSTOMER_PIC_DESCRIPTION = {
  LOGISTICS: 'In charge of operations, monitoring, and DO ordering',
  PROCUREMENT: 'In charge of price negotiations and creating Purchase Orders (PO).',
  FINANCE: 'In charge of account payables. ',
  TREASURY: 'In charge of disbursing payments.',
  CLAIMS: 'In charge of handling claims.'
};

export const COMPANY_DOCUMENT_TYPE_ATTRIBUTE = {
  TDP: ['number', 'expiryDate'],
  KTP: ['number', 'name'],
  'SIUP/SIUJPT': ['number', 'expiryDate']
};

export const COMPANY_DOCUMENT_TYPE = {
  TDP: 'TDP',
  KTP: 'KTP',
  'SIUP/SIUJPT': 'SIUP'
};

export const OVERNIGHT_PROOF_TYPE = {
  SHIPPER_APPROVAL: 'SHIPPER_APPROVAL',
  FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY',
  WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS',
  DRIVER_APP_GPS_TIMESTAMPS: 'DRIVER_APP_GPS_TIMESTAMPS',
  OTHER: 'OTHER'
};

export const CANCELLATION_FEE_TYPES = [
  'Cancellation After Truck Arrival at Origin',
  'Cancellation after Truck Details are Confirmed'
];

export const CANCELLATION_FEE_TYPE_TO_BACKEND = {
  'Cancellation After Truck Arrival at Origin': 'TRUCK_ARRIVAL',
  'Cancellation after Truck Details are Confirmed': 'TRUCK_DETAILS_CONFIRMED'
};

export const OVERNIGHT_FEE_TYPES = [
  'Overnight Loading Fee',
  'Overnight Unloading Fee'
];

export const OVERNIGHT_FEE_TYPE_TO_BACKEND = {
  'Overnight Loading Fee': 'LOADING',
  'Overnight Unloading Fee': 'UNLOADING'
};

export const MULTIPLE_SHIPMENT_FEE_TYPES = [
  'Multiple Shipment Fee'
];

export const FEE_TYPES = [
  ...CANCELLATION_FEE_TYPES,
  ...OVERNIGHT_FEE_TYPES,
  ...MULTIPLE_SHIPMENT_FEE_TYPES
];

export const CANCELLATION_FEE_FEE_TYPE = {
  FIXED_AMOUNT: 'common:fixed_amount',
  PERCENTAGE_OF_SHIPPER_PRICE: 'common:percentage_of_shipper_price'
};

export const PROOF_OF_CANCELLATION_TYPE = {
  WHATSAPP_APPROVAL: 'common:proof_of_cancellation_type:WHATSAPP_APPROVAL',
  EMAIL_APPROVAL: 'common:proof_of_cancellation_type:EMAIL_APPROVAL',
  DRIVER_APP_GPS: 'common:proof_of_cancellation_type:DRIVER_APP_GPS'
};

export const OVERNIGHT_FEE_PRICING_TYPE = {
  FLAT: 'common:flat_pricing_type',
  PROGRESSIVE: 'common:progressive_pricing_type'
};

export const SPECIAL_DAY_INCLUSION_TYPE = {
  SUNDAYS: 'SUNDAYS',
  NATIONAL_HOLIDAYS: 'NATIONAL_HOLIDAYS'
};

export const SHOW_CLAIMS_OPTIONS = {
  POD: 'POD',
  BERITA_ACARA: 'Berita Acara',
  LOAD_ITEMS: 'Load Items'
};

export const ALTERNATIVE_KIR_OPTIONS = [
  'EXPIRED_KIR',
  'VALID_PROOF_OF_RENEWAL',
  'EXPIRED_PROOF_OF_RENEWAL',
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET'
];

export const ALTERNATIVE_STNK_OPTIONS = [
  'EXPIRED_STNK',
  'VALID_PROOF_OF_RENEWAL',
  'TRAFFIC_TICKET_PHOTOCOPY',
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET'
];

export const ALTERNATIVE_LEMBAR_PAJAK_OPTIONS = [
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET',
  'PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL',
  'PROOF_OF_EXPIRED_TAX'
];

export const ALTERNATIVE_SIM_OPTIONS = [
  'EXPIRED_SIM',
  'PHOTOCOPY_OF_SIM',
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET'
];

export const feeTypeToLabel = (t, feeType) => {
  if (CANCELLATION_FEE_TYPES.includes(feeType)) {
    return t(`common:additional_fee_type:cancellation:${CANCELLATION_FEE_TYPE_TO_BACKEND[feeType]}`);
  }
  if (MULTIPLE_SHIPMENT_FEE_TYPES.includes(feeType)) {
    return t(`common:additional_fee_type:multishipment`);
  }
  if (OVERNIGHT_FEE_TYPES.includes(feeType)) {
    return t(`common:additional_fee_type:overnight:${OVERNIGHT_FEE_TYPE_TO_BACKEND[feeType]}`);
  }
  return '';
};

export const locationName = (t, truckRequirementData, position) => {
  if (get(truckRequirementData, 'truckSubType.value')) {
    return `${get(truckRequirementData, 'truckType.label')} ${get(truckRequirementData, 'truckSubType.label')}`;
  }
  return `${t('common:location')} ${position + 1}`;
};

export const majorAnchors = {
  BASIC_PROFILE: '#BasicProfile',
  LOCATION: '#Location',
  REVIEW: '#ReviewShipper',
  SKIP: '#SkipTruckRequirements'
};

export const anchorLinks = [
  {
    id: SME_SHIPPER_FORM_SEGMENTS.DETAILS_FORM,
    href: majorAnchors.BASIC_PROFILE,
    title: 'basic_profile',
    subAnchors: [
      {
        id: 'CompanyDetails',
        href: '#CompanyDetails',
        title: 'company_details'
      },
      {
        id: 'Payments',
        href: '#Payments',
        title: 'payments'
      },
      {
        id: 'PODReturns',
        href: '#PODReturns',
        title: 'pod_return'
      },
      {
        id: 'Claims',
        href: '#Claims',
        title: 'claims'
      },
      {
        id: 'Contacts',
        href: '#Contacts',
        title: 'contacts'
      }
    ]
  },
  {
    id: SME_SHIPPER_FORM_SEGMENTS.WAREHOUSE_LOCATION,
    href: majorAnchors.LOCATION,
    title: 'location',
    subAnchors: []
  },
  {
    id: SME_SHIPPER_FORM_SEGMENTS.REVIEW,
    href: majorAnchors.REVIEW,
    title: 'review_shipper',
    subAnchors: [
      {
        id: 'EditBasicProfile',
        href: majorAnchors.BASIC_PROFILE,
        title: 'edit_basic_profile',
        className: 'edit-nav'
      },
      {
        id: 'EditLocation',
        href: majorAnchors.LOCATION,
        title: 'edit_location',
        className: 'edit-nav'
      }
    ]
  }
];

export const REGISTER_TRANSPORTER_ERROR_CODE = {
  PHONE_NUMBER_EXIST: '4301',
  EMAIL_EXIST: '4302',
};

export const TRANSPORTER_REGISTRATION_FIELD = {
  COMPANY_NAME: 'companyName',
  OWNER_NAME: 'ownerName',
  ACCOUNT_EMAIL: 'accountEmail',
  ACCOUNT_PHONE_NUMBER: 'accountPhoneNumber',
  PASSWORD: 'password',
};

export const REGISTRATION_INFO_KEY_TO_LOCALE = {
  [TRANSPORTER_REGISTRATION_FIELD.COMPANY_NAME]: 'company_name',
  [TRANSPORTER_REGISTRATION_FIELD.OWNER_NAME]: 'owner_name',
  [TRANSPORTER_REGISTRATION_FIELD.ACCOUNT_EMAIL]: 'email',
  [TRANSPORTER_REGISTRATION_FIELD.ACCOUNT_PHONE_NUMBER]: 'phone_number',
  [TRANSPORTER_REGISTRATION_FIELD.PASSWORD]: 'password',
};

export const OWNER_TYPE = {
  PERSONAL: 'PERSONAL',
  COMPANY: 'COMPANY',
};
