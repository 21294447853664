/* eslint-disable max-len */

import {
  DOCUMENT_CHECKING_ENUM,
  DOCUMENT_REJECTION_CATEGORY,
  ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE,
  FINANCING_PROJECT_ASSESSMENT_ACTIONS,
  FINANCING_SUBMITTED_DOCUMENT_ACTIONS,
  PAYMENT_PROJECT_SCHEME,
  PAYMENT_PROJECT_STATUSES,
  PROJECT_DOCUMENT_STATUSES,
  QUICK_PAY_FINANCING_DOCUMENT_TYPE,
  SHAREHOLDER_CHECKBOX_GROUP,
} from '~/Configurations/constants';

export default {
  action: 'Action',
  activate_project: 'Aktifkan Proyek',
  add_data: 'Add Data',
  additional_documents: 'Dokumen Tambahan',
  akta_pendirian_number: 'Nomor Akta Pendirian',
  akta_perubahan_terakhir_number: 'Nomor Akta Perubahan Terakhir',
  approved_limits: 'Limit yang disetujui',
  assessment_result_report: 'Laporan Hasil Penilaian',
  back: 'Kembali',
  cancel: 'Batalkan',
  cancel_share: 'Batal Bagikan',
  cancel_text: 'Simpan data saja',
  change: 'Ubah',
  check_again: 'Cek Kembali',
  check_and_save: 'Check & Save',
  check_assessment_report: 'Cek Kembali Laporan Hasil Penilaian',
  check_assessment_report_info: 'Hasil Penilaian akan dikirim ke Transporter. Pastikan Informasi yang di input sudah sesuai dan mohon perhatikan kembali apabila terdapat alasan penolakan',
  check_document_suitable_info: 'Kelengkapan dokumen akan diperiksa oleh pihak Kargo dan Anda mungkin akan diminta untuk mengunggah dokumen tambahan. Cek kembali semua dokumen untuk memperlancar proses pengajuan.',
  check_document_suitablity_again: 'Cek Kembali Kesesuaian Dokumen',
  check_result: 'Hasil Pengecekan',
  confirm: 'Konfirmasi',
  copy: 'Salin',
  created_date: 'Tanggal Dibuat',
  currently_revising: 'Sedang Direvisi',
  customer_data: 'Data Customer',
  customer_documents: 'Dokumen Customer',
  day: 'Hari',
  deactivate_project: 'Nonaktifkan Proyek',
  description: 'Keterangan',
  dialog_body: 'Data customer Anda tetap tersimpan dan Anda perlu melampirkan beberapa dokumen untuk pendaftaran program pembayaran cepat.',
  dialog_title: 'Apakah ingin mendaftarkan customer sebagai Program Pembayaran Cepat?',
  document_checking_response: {
    [DOCUMENT_CHECKING_ENUM.COMPANY_NPWP_NUMBER_ALREADY_EXIST]: 'No. NPWP Perusahaan sudah pernah terdaftar di account lain',
    [DOCUMENT_CHECKING_ENUM.COMPANY_NPWP_NUMBER_NOT_EXIST]: 'No. NPWP Perusahaan belum terdaftar',
    [DOCUMENT_CHECKING_ENUM.DEED_OF_INCORPORATION_NUMBER_ALREADY_EXIST]: 'No. Akta Pendirian sudah pernah terdaftar di account lain',
    [DOCUMENT_CHECKING_ENUM.DEED_OF_INCORPORATION_NUMBER_NOT_EXIST]: 'No. Akta Pendirian belum terdaftar',
    [DOCUMENT_CHECKING_ENUM.KEMENKUMHAM_NUMBER_ALREADY_EXIST]: 'No. SK Kemenkumham sudah pernah terdaftar di account lain',
    [DOCUMENT_CHECKING_ENUM.KEMENKUMHAM_NUMBER_NOT_EXIST]: 'No. SK Kemenkumham belum terdaftar',
    [DOCUMENT_CHECKING_ENUM.LATEST_DEED_OF_AMENDMENT_NUMBER_ALREADY_EXIST]: 'No. Akta Perubahan sudah pernah terdaftar di account lain',
    [DOCUMENT_CHECKING_ENUM.LATEST_DEED_OF_AMENDMENT_NUMBER_NOT_EXIST]: 'No. Akta Perubahan belum terdaftar',
    [DOCUMENT_CHECKING_ENUM.NIB_NUMBER_ALREADY_EXIST]: 'NIB sudah pernah terdaftar di account lain',
    [DOCUMENT_CHECKING_ENUM.NIB_NUMBER_NOT_EXIST]: 'NIB belum terdaftar',
    [DOCUMENT_CHECKING_ENUM.NIK_ALREADY_EXIST]: 'NIK {{sequence}} sudah pernah terdaftar di account lain',
    [DOCUMENT_CHECKING_ENUM.NIK_MATCH_ON_DEED_INCORPORATION_AND_AMENDMENT]: 'NIK {{sequence}} tercatat pada Akta Perubahan dan Akta Pendirian',
    [DOCUMENT_CHECKING_ENUM.NIK_NOT_EXIST]: 'NIK {{sequence}} belum terdaftar',
    [DOCUMENT_CHECKING_ENUM.NIK_NOT_MATCH_ON_DEED_INCORPORATION_OR_AMENDMENT]: 'NIK {{sequence}} tidak tercatat pada Akta Perubahan atau Akta Pendirian',
    [DOCUMENT_CHECKING_ENUM.NPWP_NUMBER_ALREADY_EXIST]: 'No. NPWP {{sequence}} sudah pernah terdaftar di account lain',
    [DOCUMENT_CHECKING_ENUM.NPWP_NUMBER_NOT_EXIST]: 'No. NPWP {{sequence}} belum terdaftar',
  },
  document_details: 'Detail Dokumen',
  document_file_format: 'Format File Dokumen',
  document_file_size_info: 'Ukuran file dokumen tambahan yang akan diupload maksimum 10 MB',
  document_name: 'Nama Dokumen',
  document_name_placeholder: 'Contoh “Surat Izin”',
  document_notes: 'Ketentuan Dokumen',
  document_notes_placeholder: 'Contoh “Wajib asli, berwarna, & terbaru dalam 3 bulan”',
  document_rejection_category: {
    [DOCUMENT_REJECTION_CATEGORY.DOCUMENT_IS_BLURRY]: 'Document is Blurry',
    [DOCUMENT_REJECTION_CATEGORY.DOCUMENT_IS_EXPIRED]: 'Document is Expired',
    [DOCUMENT_REJECTION_CATEGORY.DOCUMENT_IS_INCOMPLETE]: 'Document is Incomplete',
    [DOCUMENT_REJECTION_CATEGORY.WRONG_DOCUMENT]: 'Wrong Document',
    [DOCUMENT_REJECTION_CATEGORY.OTHER]: 'Other',
  },
  document_saved_successfully: 'Dokumen berhasil disimpan',
  document_sent_successfully: 'Dokumen berhasil dikirim',
  document_status: 'Dokumen Status',
  document_suitability: 'Kesesuaian Dokumen',
  done: 'Selesai',
  download_all: 'Unduh Semua',
  due_days: 'hari jatuh tempo',
  page_change_warning_description: 'Tetap lanjutkan proses untuk menyelesaikan.',
  page_change_warning_title: 'Anda dalam proses upload dokumen',
  enter: 'Masukkan {{placeholder}}',
  enter_name: 'Masukan nama {{name}}',
  enter_rejection_reason: 'Masukkan alasan reject',
  enter_total: 'Masukan jumlah {{total}}',
  enterprise_referral: 'Enterprise Referral',
  fast_payment_project: 'Proyek Pembayaran Cepat',
  fast_payment_project_approved_status_info: 'Limit yang di setujui {{approvedLimit}} oleh Kargo dengan TOP (Terms of Payment) dalam {{approvedTermsOfPayment}} hari',
  fast_payment_project_rejected_status_info: 'Alasan Tidak Ada. Mohon hubungi tim sales',
  fast_payment_project_status: {
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Pembayaran Cepat Ditolak',
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Terdaftar Pembayaran Cepat {{scheme}} - {{approvedPrograms}}',
  },
  fast_payment_scheme: 'Skema Pembayaran Cepat',
  file_delete_confirmation_message: 'Yakin menghapus file ini?',
  file_size_mb: '{{fileSize}}MB',
  file_upload_description: 'Taruh dokumen untuk mengunggah cepat, atau',
  financial_documents: 'Dokumen Finansial',
  generating: 'Generating...',
  generate_link: 'Generate Link',
  get_private_link: 'Get Private Link',
  get_private_link_description: 'Dapatkan link dokumen yang hanya bisa dilihat oleh P2PL secara private',
  go_out: 'Keluar',
  jpg_jpeg_png: 'jpg, jpeg, png',
  keep_continue: 'Tetap Lanjutkan',
  last_update: 'Tanggal Update',
  legal_documents: 'Dokumen Legal',
  link_expiration_date: 'Tanggal Kedaluwarsa Link',
  link_expiration_date_info: 'Link Dokumen akan tersedia hingga {{ expirationDate }}, jam 23:59',
  limit: 'limit',
  main_documents: 'Dokumen Utama',
  maximum_file_upload_count: 'Unggahan maksimal {{maxFileCount}} file',
  maximum_file_size_with_extensions: 'Format {{fileExtensions}} dengan ukuran maks. {{maxFileSize}}',
  notify_document_revision_message: 'Dokumen Revisi berhasil diberitahu',
  notifiy_project_revision_title: 'Apakah yakin ingin memberitahu Dokumen yang telah di revisi ke Transporter?',
  notifiy_project_revision_description: 'Cek Kembali Dokumen Lainnya yang Tidak Sesuai, Sistem akan memberitahu Dokumen yang tidak sesuai untuk Transporter upload ulang',
  notify_revision: 'Beritahu Revisi',
  number_document_rejected: '{{documentCount}} dok. tidak sesuai',
  number_document_resubmitted: '{{documentCount}} dok. diupload ulang',
  no: 'No',
  ok_text: 'Ya, Daftarkan',
  other_documents: 'Dokumen Lainnya',
  password: 'password',
  password_link: 'Password Link',
  payment_project_list: 'Daftar Proyek Pembayaran',
  payment_scheme: 'Skema Pembayaran',
  payment_scheme_map: {
    [PAYMENT_PROJECT_SCHEME.QUICK_PAY]: 'Quick Pay',
    [PAYMENT_PROJECT_SCHEME.ENTERPRISE_REFERRAL]: 'Enterprise Referral',
  },
  payment_scheme_note: 'Perlu diketahui. Skema Pembayaran ini dapat disesuaikan setelah proses assessment, apabila terdapat hasil assessment yang mempengaruhi skema',
  payment_scheme_preference: 'Preferensi Skema Pembayaran',
  pdf: 'pdf',
  pic_detail: 'Data Pengurus {{number}}',
  project: 'Proyek',
  project_assessment_approved: 'Hasil Penilaian Proyek berhasil diterima',
  project_assessment_rejected: 'Hasil Penilaian Proyek ditolak',
  project_assessment_submitted: 'Hasil Penilaian Proyek berhasil diterima',
  project_detail: 'Detail Proyek',
  p2p_lending: 'P2P Lending',
  quick_pay: 'Quick Pay',
  rating_input: 'Input Penilaian',
  rating_result: 'Hasil Penilaian',
  reason_for_rejection: 'Alasan Rejected',
  registered_fast_payment_program: 'Terdaftar Program Pembayaran Cepat',
  registered_fast_payment_program_info: 'Disesuaikan dengan pengajuan transporter melalui tim sales',
  request_additional_document: 'Request Dokumen Tambahan',
  request_additional_document_info: 'Masukan spesifikasi dokumen yang diperlukan',
  register_project: 'Daftarkan Proyek',
  required_documents: 'Dokumen Wajib',
  required_field: 'Required',
  review: 'Review',
  scheme: 'Skema {{scheme}}',
  search_shipper_name: 'Cari Nama Shipper',
  send: 'Kirim',
  send_inquiry: 'Kirim Permintaan',
  share_documents: 'Bagikan Dokumen',
  shareholder_checkbox_group: {
    [SHAREHOLDER_CHECKBOX_GROUP.MANAGEMENT]: 'Pengurus',
    [SHAREHOLDER_CHECKBOX_GROUP.SHAREHOLDER]: 'Pemegang Saham',
  },
  shareholder_detail: 'Pengurus & Pemegang Saham {{number}}',
  shipper_documents: 'Dokumen Shipper',
  shipper_name: 'Nama Shipper',
  sk_kemenkumham_number: 'Nomor SK Kemenkumham',
  skip: 'Lewati',
  status_update_action: {
    [FINANCING_SUBMITTED_DOCUMENT_ACTIONS.APPROVE]: 'Sesuai',
    [FINANCING_SUBMITTED_DOCUMENT_ACTIONS.REJECT]: 'Tidak Sesuai'
  },
  status_update_message: {
    textarea_placeholder: 'Masukkan alasan dokumen tidak sesuai',
    [FINANCING_SUBMITTED_DOCUMENT_ACTIONS.APPROVE]: 'Dokumen dinyatakan sesuai',
    [FINANCING_SUBMITTED_DOCUMENT_ACTIONS.REJECT]: 'Dokumen dinyatakan tidak sesuai'
  },
  submit_date: 'Tanggal Submit',
  terms_of_payment: 'TOP (Terms of Payment)',
  transporter_main_documents_verified: 'Dokumen Utama berhasil di verifikasi',
  transporter_name: 'Nama Transporter',
  update_financing_scheme_title: 'Apakah yakin ingin mengubah Preferensi Skema Pembayaran ?',
  update_financing_scheme_info: 'Beberapa Dokumen yang telah Anda upload akan terhapus dan Anda diperlukan untuk mengupload ulang dokumen yang baru',
  verify_main_documents: 'Verifikasi Dokumen Utama',
  yes_delete: 'Ya, Hapus',
  yes_notify: 'Ya, Beritahu',
  yes_its_appropriate: 'Ya, Sudah sesuai',
  yes_update_scheme: 'Ya, Ubah Skema',
  yes_verify: 'Ya, Verifikasi',
  verify_main_documents_dialog: {
    title: 'Apakah Anda yakin untuk verifikasi Dokumen Utama Transporter?',
    message: 'Selanjutnya sistem akan memberitahu Transporter untuk melanjutkan proses pengajuan',
  },
  finance_project_status_info_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Aktif',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Tidak Aktif',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Ditolak',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Dalam proses assessment',
  },
  // Not sorted on purpose
  finance_project_rating_map: {
    [FINANCING_PROJECT_ASSESSMENT_ACTIONS.APPROVE]: 'Approved',
    [FINANCING_PROJECT_ASSESSMENT_ACTIONS.REJECT]: 'Rejected',
  },
  project_document_status_map: {
    [PROJECT_DOCUMENT_STATUSES.APPROVED]: 'Sesuai',
    [PROJECT_DOCUMENT_STATUSES.DRAFT]: 'Dalam Proses',
    [PROJECT_DOCUMENT_STATUSES.NOT_UPLOADED]: 'Belum Diunggah',
    [PROJECT_DOCUMENT_STATUSES.PENDING]: 'Dalam Penilaian',
    [PROJECT_DOCUMENT_STATUSES.REJECTED]: 'Tidak Sesuai',
    [PROJECT_DOCUMENT_STATUSES.REUPLOADED]: 'Dokumen Diupload Ulang',
    [PROJECT_DOCUMENT_STATUSES.REQUESTED]: 'Permintaan Dokumen Tambahan',
    [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: 'Sedang Diverifikasi',
  },
  payment_project_details_status_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Aktif',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Tidak Aktif',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Ditolak',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Dalam Penilaian',
    [PAYMENT_PROJECT_STATUSES.DRAFT]: 'Draft Pengajuan',
    [PAYMENT_PROJECT_STATUSES.UNDER_VERIFICATION]: 'Dalam Proses Verifikasi',
    [PAYMENT_PROJECT_STATUSES.NEED_REVISION]: 'Perlu Revisi',
    [PAYMENT_PROJECT_STATUSES.VERIFIED]: 'Verifikasi Berhasil',
  },
  payment_status_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Aktif',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Tidak Aktif',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Ditolak',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Diajukan',
    [PAYMENT_PROJECT_STATUSES.DRAFT]: 'Draft Pengajuan',
    [PAYMENT_PROJECT_STATUSES.UNDER_VERIFICATION]: 'Dalam Proses Verifikasi',
    [PAYMENT_PROJECT_STATUSES.NEED_REVISION]: 'Perlu Revisi',
    [PAYMENT_PROJECT_STATUSES.VERIFIED]: 'Verifikasi Berhasil',
  },

  quick_pay_financing_document_map: {
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NPWP_COMPANY]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan Transporter',
      note: 'Pastikan dokumen berwarna, terlihat jelas (tidak buram), dan mudah dibaca ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PENDIRIAN]: {
      bannerTitle: 'Akta Pendirian Perusahaan Beserta Pengesahan dari Kemenkumham',
      title: 'Akta Pendirian Perusahaan + SK Kemenkumham',
      note: 'Pastikan dokumen berwarna, terlihat jelas (tidak buram), dan mudah dibaca',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Pendirian Perusahaan',
      title: 'SK Kemenkumham',
      description: 'Wajib asli & terbaru sesuai Akta Pendirian Perusahaan',
      note: 'Harus sesuai dengan Akta Pendirian Perusahaan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_TERAKHIR]: {
      bannerTitle: 'Akta Perubahan Perusahaan Terakhir Beserta Pengesahan dari Kemenkumham',
      title: 'Akta Perubahan (Terakhir) + SK Kemenkumham',
      note: 'Hanya perlu dilampirkan jika memiliki Akta Perubahan Perusahaan karena terjadi pergantian direksi atau hal yang berkaitan dengan internal perusahaan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_PERUSAHAAN]: {
      bannerTitle: 'Akta Perubahan Perusahaan (Semua) Beserta Pengesahan dari Kemenkumham',
      title: 'Akta Perubahan (Semua)',
      note: 'Jika pendirian sudah lebih dari 5 tahun, terjadi pergantian direksi, atau hal yang berkaitan dengan internal perusahaan, wajib menyertakan Akta Perubahan Perusahaan Terakhir',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM_AKTA_PERUBAHAN]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Pendirian Perusahaan',
      title: 'SK Kemenkumham dari Akta Perubahan',
      description: 'Wajib asli & terbaru sesuai Akta Pendirian Perusahaan',
      note: 'Harus sesuai dengan Akta Pendirian Perusahaan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NPWP_CUSTOMER]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan dari Customer',
      description: 'Wajib asli dan berwarna',
      note: 'Pastikan dokumen unggahan berwarna, dapat terlihat jelas, dan mudah dibaca ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BANK_STATEMENT]: {
      bannerTitle: 'Rekening Koran 6 Bulan Terakhir',
      title: 'Rekening Koran (Bank Statement)',
      note: 'Pastikan semua transaksi terlihat jelas dalam laporan bank. Jika ada transaksi yang dilakukan melalui rekening bank pribadi, wajib melampirkan dari rekening bank pribadi',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.INVOICE_EXAMPLE]: {
      bannerTitle: 'Riwayat Invoice Customer Lunas dalam 6 Bulan Terakhir',
      title: 'Riwayat Invoice Customer',
      note: 'Lampirkan min. 5 invoice yang sudah dibayarkan. Pastikan semua invoice sudah dicap atau ditandatangani, dan sesuai dengan daftar transaksi rekening antara Transporter dengan Customer',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SALES_REPORT]: {
      bannerTitle: 'Laporan Penjualan (Sales Report) wajib terbaru 6 bulan terakhir',
      title: 'Laporan Penjualan (Sales Report)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Pastikan laporan disimpan dengan format PDF',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.INCOME_STATEMENT]: {
      bannerTitle: 'Laporan Laba Rugi (Income Statement) wajib terbaru 6 bulan terakhir',
      title: 'Laporan Laba Rugi (Income Statement)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Lihat contoh template laporan yang sudah tersedia di kanan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BALANCE_SHEET]: {
      bannerTitle: 'Neraca (Balance Sheet) wajib terbaru 6 bulan terakhir',
      title: 'Neraca (Balance Sheet)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Lihat contoh template laporan yang sudah tersedia di kanan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.CASH_FLOW_STATEMENT]: {
      bannerTitle: 'Laporan Arus Kas (Cash Flow Statement) wajib terbaru 6 bulan terkahir',
      title: 'Laporan Arus Kas (Cash Flow Statement)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Pastikan laporan disimpan dengan format PDF',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.DEBT_REPORT]: {
      bannerTitle: 'Laporan Daftar Hutang & Piutang',
      title: 'Laporan Daftar Hutang & Piutang',
      note: 'Wajib dalam 6 bulan terakhir dan sesuai dengan usia hutang',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.CREDIT_REPORT]: {
      bannerTitle: 'Laporan Daftar Piutang',
      title: 'Laporan Daftar Piutang',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Wajib dalam 6 bulan terakhir dan sesuai dengan usia piutang',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NIB_OR_TDP]: {
      bannerTitle: 'NIB (Nomor Induk Berusaha) Beserta Lampirannya',
      title: 'NIB (Nomor Induk Berusaha)',
      note: 'Dokumen NIB beserta lampirannya yang diterbitkan oleh Sistem OSS dari Kementerian Investasi/BKPM. Lampiran seperti kode KLBI, jenis perdangan atau yang serupa',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.KTP_NPWP_DIRECTOR]: {
      bannerTitle: 'KTP & NPWP Pengurus Perusahaan (Terdaftar dalam Akta Perubahan Terakhir)',
      title: 'KTP & NPWP Pengurus Perusahaan ',
      note: 'Nama yang tertera harus sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto KTP dan NPWP pengurus berwarna dan terlihat jelas (tidak buram)',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.KTP_NPWP_COMMISSIONER]: {
      bannerTitle: 'KTP & NPWP Komisaris',
      title: 'KTP & NPWP Komisaris',
      description: 'Wajib berwarna, sesuai Akta Pendirian dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AGREEMENT_COOPERATION]: {
      bannerTitle: 'Surat Perjanjian Kerjasama',
      title: 'Surat Perjanjian Kerjasama',
      description: 'Antara pihak Transporter dengan pihak Customer',
      note: 'Perjanjian asli antara pihak Transporter dengan pihak Shipper. Pastikan dokumen unggahan yang discan atau difoto dapat terlihat jelas dan mudah dibaca',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AGREEMENT_CREDIT_CHECK]: {
      bannerTitle: 'Surat Persetujuan Pemeriksaan Kredit',
      title: 'Surat Persetujuan Pemeriksaan Kredit',
      description: 'Wajib menyertakan surat asli dengan materai Rp10.000 & tanda tangan',
      note: 'Wajib menyertakan surat asli dengan materai Rp10.000 & tanda tangan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SIUP]: {
      bannerTitle: 'SIUP (Surat Izin Usaha Perdagangan) atau TDP',
      title: 'SIUP (Surat Izin Usaha Perdagangan) atau TDP',
      note: 'Dokumen yang masih berlaku dalam 5 tahun terakhir dan diterbitkan oleh Sistem OSS Kementerian Investasi/BKPM atau Tanda Daftar Perusahaan (TDP) yang masih berlaku ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SKDU]: {
      bannerTitle: 'SKDU (Surat Keterangan Domisili Usaha)',
      title: 'SKDU (Surat Keterangan Domisili Usaha)',
      note: 'Dokumen asli yang masih berlaku dan dikeluarkan oleh kecamatan setempat',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BUKTI_PELUNASAN_HUTANG]: {
      bannerTitle: 'Bukti Pelunasan Hutang',
      title: 'Bukti Pelunasan Hutang',
      note: 'Wajib dokumen asli dan disertai dengan Surat Bukti Lunas yang diberikan oleh instansi peminjam',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.FINANCIAL_REPORT]: {
      bannerTitle: 'Laporan Keuangan 2 Tahun Terakhir dan 6 Bulan Terakhir',
      title: 'Laporan Keuangan (Financial Report)',
      note: 'Sudah termasuk Laporan Laba Rugi, Neraca, dan Laporan Arus Kas. Lampirkan laporan dalam 2 periode yang berbeda, yaitu 2 tahun terakhir dan 6 bulan terakhir',
    },
  },
  enterprise_referral_financing_document_map: {
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NPWP_CUSTOMER]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan dari Customer',
      description: 'Wajib asli dan berwarna',
      note: 'Pastikan dokumen unggahan berwarna, dapat terlihat jelas, dan mudah dibaca ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NPWP_COMPANY]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan',
      description: 'Wajib berwarna sesuai Akta Pendirian Perusahaan dan Akta Perubahan',
      note: 'Pastikan dokumen unggahan berwarna, dapat terlihat jelas, dan mudah dibaca ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AKTA_PENDIRIAN]: {
      bannerTitle: 'Akta Pendirian Perusahaan',
      title: 'Akta Pendirian Perusahaan',
      description: 'Wajib terbaru 5 tahun terakhir',
      note: 'Jika pendirian sudah lebih dari 5 tahun atau terjadi pergantian direksi, organisasi atau hal yang berkaitan dengan internal perusahaan, diwajibkan menyertakan Akta Perubahan Perusahaan',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Pendirian Perusahaan',
      title: 'SK Kemenkumham',
      description: 'Wajib asli & terbaru sesuai Akta Pendirian Perusahaan',
      note: 'Harus sesuai dengan Akta Pendirian Perusahaan',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_PERUSAHAAN]: {
      bannerTitle: 'Akta Perubahan Perusahaan',
      title: 'Akta Perubahan Perusahaan',
      description: 'Wajib terbaru & apabila ada perubahan kepengurusan perusahaan',
      note: 'Jika pendirian sudah lebih dari 5 tahun atau terjadi pergantian direksi, organisasi atau hal yang berkaitan dengan internal perusahaan, diwajibkan menyertakan Akta Perubahan Perusahaan terakhir/terbaru',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM_AKTA_PERUBAHAN]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Pendirian Perusahaan',
      title: 'SK Kemenkumham dari Akta Perubahan',
      description: 'Wajib asli & terbaru sesuai Akta Pendirian Perusahaan',
      note: 'Harus sesuai dengan Akta Pendirian Perusahaan',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.BANK_STATEMENT]: {
      bannerTitle: 'Rekening Koran wajib terbaru 6 bulan terakhir',
      title: 'Rekening Koran (Bank Statement)',
      description: 'Wajib terbaru dalam 6 bulan terakhir',
      note: 'Pastikan semua transaksi terlihat jelas dalam laporan bank. Jika ada transaksi yang dilakukan melalui rekening bank pribadi, wajib melampirkan dari rekening bank pribadi',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.INVOICE_EXAMPLE]: {
      bannerTitle: 'Contoh Invoice atau Bukti Bayar wajib terbaru 6 bulan terakhir',
      title: 'Contoh Invoice atau Bukti Bayar',
      description: 'Terbaru dalam 6 bulan terakhir dengan Shipper A1-A2',
      note: 'Lampirkan minimal 5 invoice untuk beberapa DO. Pastikan semua bukti bayar sudah dicap atau ditanda tangan & sesuai dengan daftar transaksi rekening antara Transporter dengan Shipper',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.FINANCIAL_STATEMENT]: {
      bannerTitle: 'Laporan Keuangan 6 bulan terakhir',
      title: 'Laporan Keuangan (Financial Statement)',
      description: 'Wajib terbaru dalam 6 bulan terakhir',
      note: 'Isi laporan yang sudah di-audit termasuk Laporan Penjualan (Sales Report), Laporan Laba Rugi (Income Statement), Neraca (Balance Sheet), dan Laporan Arus Kas (Cash Flow Statement)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AGREEMENT_CUSTOMER_COOPERATION]: {
      bannerTitle: 'Laporan Keuangan 6 bulan terakhir',
      title: 'Surat Perjanjian Kerjasama dengan Customer',
      description: 'Antara pihak Shipper A1 dengan A2',
      note: 'Isi laporan yang sudah di-audit termasuk Laporan Penjualan (Sales Report), Laporan Laba Rugi (Income Statement), Neraca (Balance Sheet), dan Laporan Arus Kas (Cash Flow Statement)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NIB_OR_TDP]: {
      bannerTitle: 'NIB atau TDP',
      title: 'NIB atau TDP',
      description: 'Wajib yang masih berlaku',
      note: 'Dokumen Nomor Induk Berusaha (NIB) yang diterbitkan oleh Sistem OSS dari Kementerian Investasi/BKPM atau Tanda Daftar Perusahaan (TDP) yang masih berlaku ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SIUP]: {
      bannerTitle: 'SIUP (Surat Izin Usaha Perdagangan)',
      title: 'SIUP (Surat Izin Usaha Perdagangan)',
      description: 'Atau Surat Izin Usaha yang masih berlaku',
      note: 'Dokumen yang masih berlaku dalam 5 tahun terakhir dan diterbitkan oleh Sistem OSS Kementerian Investasi/BKPM',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SKDU]: {
      bannerTitle: 'SKDU (Surat Keterangan Domisili Usaha)',
      title: 'SKDU (Surat Keterangan Domisili Usaha)',
      description: 'Atau Surat Izin Lokasi yang masih berlaku',
      note: 'Dokumen asli yang masih berlaku dan dikeluarkan oleh kecamatan setempat',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_NPWP_DIRECTOR]: {
      bannerTitle: 'KTP & NPWP Direktur',
      title: 'KTP & NPWP Direktur',
      description: 'Wajib berwarna, sesuai Akta Pendirian dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_NPWP_COMMISSIONER]: {
      bannerTitle: 'KTP & NPWP Komisaris',
      title: 'KTP & NPWP Komisaris',
      description: 'Wajib berwarna sesuai Akta Pendirian dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AGREEMENT_COOPERATION]: {
      bannerTitle: 'Surat Perjanjian Kerjasama',
      title: 'Surat Perjanjian Kerjasama',
      description: 'Antara pihak Shipper dengan pihak Shipper lain',
      note: 'Perjanjian asli antara pihak Shipper A1 dengan Shipper A2. Pastikan dokumen unggahan yang discan atau difoto dapat terlihat jelas dan mudah dibaca',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_CUSTOMER]: {
      bannerTitle: 'KTP Customer',
      title: 'KTP Customer',
      description: 'KTP pengurus perusahaan, dewan direksi, dan komisaris sesuai Akta Pendirian Perusahaan dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
  },
  xls_xlsx: 'xls, xlsx',
};
