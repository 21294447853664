// eslint-disable-next-line import/prefer-default-export
export const ACTIVE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const ANNOUNCEMENT_STATUS_COLORS = {
  [ACTIVE_STATUS.ACTIVE]: 'primary',
  [ACTIVE_STATUS.INACTIVE]: 'darkGrey'
};
