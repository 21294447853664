/* eslint-disable max-len */
import { AR_AGING_LABELS, SHIPMENT_POINT_TYPES, SHIPMENT_STATUSES, } from '~/Configurations/constants';

export default {
  account_deactivation_warning_message: 'Anda tidak akan bisa gunakan rekening bank ini untuk membuat invoice',
  account_successfully_updated_as_default: 'Rekening {{account_number}} berhasil disimpan sebagai rekening utama',
  activity_log_time: 'Aktivitas: {{time}} {{timezone}}',
  add_additional_detail: 'Detail {{payment_type}}',
  add_additional_fee: 'Tambah {{payment_type}}',
  add_additional_fee_for_shipment: 'Tambah Biaya Lain untuk {{shipment_number}}',
  add_employee: 'Tambah Karyawan',
  additional_fee_successfully_added: '{{payment_type}} berhasil ditambahkan.',
  additional_fee_successfully_updated: '{{payment_type}} berhasil diperbaharui.',
  against_the_invoice_value: '{{percentage}} terhadap nilai invoice',
  ar_aging_label_map: {
    [AR_AGING_LABELS.AGING_DUE]: 'Jatuh tempo dalam {{days}} hari',
    [AR_AGING_LABELS.AGING_DUE_TODAY]: 'Jatuh tempo pada hari ini',
    [AR_AGING_LABELS.AGING_OVERDUE]: 'Lewat jatuh tempo selama {{days}} hari',
  },
  cancel_adding_fee: 'Batal menambahkan biaya ini?',
  cancel_do: 'Batalkan {{do_number}}?',
  change_invoice_status: 'Ubah status Invoice {{invoice_number}}?',
  change_invoice_status_message: 'Ubah status menjadi {{status}}?',
  change_shipment_status: 'Ubah status {{do_number}}?',
  change_status_time: 'Ubah waktu status {{do_number}}?',
  choose_shipment_from_shipper: 'Pilih pengiriman dari {{shipper}}',
  confirm_revise_invoice: 'Konfirmasi Revisi Invoice ke-{{sequence}}',
  contact_kargo_to_change_status_of_fast_payment: 'Hubungi Kargo untuk mengubah status Pembayaran Cepat',
  created_at: 'Dibuat {{date}}',
  custom_invoice_edit_fee_warning: 'Ubahan detail biaya ini akan mengubah rincian tagihan invoice {{invoice}} secara otomatis',
  custom_invoice_total_fee_message: 'Total Biaya dihitung dari Nominal Biaya x {{unit}}',
  customer_division: 'Divisi {{division}}',
  customer_restrict_create_do: 'Tidak bisa membuat pengiriman untuk {{name}}',
  customer_successfully_created: '{{name}} berhasil ditambahkan.',
  customer_successfully_updated: '{{name}} berhasil diubah.',
  date_range_invalid: 'Rentang tanggal {{field_start}} dan {{field_end}} tidak valid',
  delete_entity_warning: 'Apakah Anda ingin menghapus {{entity}}',
  delete_payment_from_invoice: 'Hapus {{payment_type}} dari {{shipment_number}}?',
  delete_payment_warning: 'Biaya ini akan dihapus dari pengiriman {{shipment_number}}',
  delete_shipment_from_invoice: 'Hapus {{shipment_number}} dari Invoice ini?',
  delete_this_bank_account: 'Hapus rekening bank ini?',
  delivery_approved: '{{percentage}}% Pengiriman Disetujui',
  detail_of: 'Detail {{object}}',
  detail_of_shipment: 'Detail {{shipment_number}}',
  disbursement_date_label: 'Tanggal pencairan {{invoice_number}}',
  disbursement_scheme_successfully_deactivate: '{{name}} berhasil dinonaktifkan',
  disbursementScheme_successfully_added: '{{name}} berhasil Ditambahkan',
  do_assigned_success: 'DO Successfully Assigned',
  document_uploaded_successfully: 'Dokumen berhasil diunggah',
  dof2_eligibility_message_map: {
    1: 'Pengiriman belum selesai',
    2: 'Pengiriman sudah terdaftar pada {{dof_2_number}}',
    3: 'Pengiriman sudah terdaftar pada {{do_invoice_number}}',
    4: 'DOF pencairan pertama belum selesai',
    5: 'UNDEFINED_BACKEND_CODE',
    6: 'Pengiriman belum terdaftar pada DOF'
  },
  dof2_invoice_keyword_not_found: 'Tidak menemukan invoice {{keyword}} di Vendor Central',
  dof2_request_review_successfully_submitted: 'Hasil review {{dof2_number}} berhasil dikirim ke {{applicant}}',
  dof2_request_successfully_disbursed: 'Detail Pencairan {{dof2_number}} berhasil dikirim ke {{applicant}}',
  dof2_request_successfully_submitted: 'Pengajuan {{dof2_number}} Berhasil ditambahkan.',
  dof2_third_disbursement_proceed: 'Pencairan ke-3 {{dof2Number}} sedang diproses',
  dof2_total_first_disbursement_formula: '{{firstDisbursementPercentage}}% Pengiriman - Kargo Fee - VAT',
  dof2_total_second_disbursement_formula: '{{secondDisbursementPercentage}}% Pengiriman + {{additionalFeePercentage}}% Additional Fee',
  dof2_total_third_disbursement_formula: '{{thirdInovicePercentage}}% Invoice + PPN {{pphSign}} PPH {{adjustmentSign}} Adjustment {{kargoFeeAdjustmentSign}} Kargo Fee Adjustment - Late Fee',
  dof_first_disbursement_summary: '{{percentage}} dari {{total}} Pengiriman',
  dof_request_review_successfully_submitted: 'Hasil review {{dof_number}} berhasil dikirim ke {{applicant}}',
  dof_request_successfully_disbursed: 'Detail Pencairan {{dof_number}} berhasil dikirim ke {{applicant}}',
  dof_request_successfully_submitted: 'Pengajuan {{dof_number}} Berhasil ditambahkan.',
  dof_third_disbursement_formula: '{{invoicePercentage}}% invoice + PPN - PPH + Adjustment {{adjustmentFeeSign}} Kargo Fee Adjustment - Late Fee',
  done_review_with_progress: '{{progress}}/{{total}} Selesai Review',
  driver_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  edit_employee: 'Ubah Karyawan',
  driver_deactivation_warning_message: 'Apakah kamu yakin?',
  email_revised_invoice: 'Email Revisi Invoice ke-{{sequence}}',
  expires_on: 'Expires {{date}}',
  file_error: {
    1: 'Perusahaan tidak ditemukan',
    9: 'Gudang tidak ditemukan',
    13: 'Lokasi alamat tidak valid',
    45: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
    46: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
    55: 'ID jenis kendaraan tidak valid',
    60: 'Alamat ({{column_name}}) tidak ditemukan',
    61: 'Alamat ({{column_name}}) berlokasi di luar Indonesia',
    62: 'Tanggal muat tidak valid',
    63: 'Tanggal bongkar tidak valid',
    64: 'Armada tidak ditemukan',
    65: 'Jenis barang tidak valid',
    66: 'Customer tidak ditemukan',
    67: 'Supir tidak ditemukan',
    96: 'BL not found',
    97: 'Invalid container status',
    98: 'Invalid container type',
    99: 'Warehouse not found',
    474: 'Header file CSV tidak valid',
    475: 'Kolom {{column_name}} tidak valid',
    476: 'File CSV tidak valid',
    477: 'Header file CSV kosong',
    478: 'Kesalahan yang tidak diketahui',
    479: 'Urutan pelolosan yang tidak valid ditemukan dalam satu baris',
    480: 'Panjang kolom tidak valid untuk satu baris',
    481: 'Baris memiliki kutipan yang menyimpang',
    483: 'Nomor Lisensi Kendaraan sudah terdaftar di perusahaan',
    2501: 'Location ID tidak ditemukan',
    2502: 'Fee type tidak valid',
    2503: 'Shipment type tidak valid',
    2504: 'Max amount tidak valid',
    2505: 'Reimbursement percentage tidak valid',
    2506: 'Reimbursement requirement tidak valid',
    2507: 'Truck type/subtype tidak ditemukan',
    2508: 'Reimbursement type tidak valid'
  },
  fill_disbursement_details_dof2_request: 'Isi Detail Pencairan ke-2 {{request_name}}',
  fill_disbursement_details_dof_request: 'Mengisi Detail Pencairan {{request_name}}',
  fill_disbursement_details_podf_request: 'Mengisi Detail Pencairan {{request_name}}',
  fill_first_disbursement_details_podf_request: 'Mengisi Detail Pencairan Pertama {{request_name}}',
  fill_in_the_truck_details: 'Isi Detail Truk',
  first_disbursement_calculation: '{{shipment_fee_percentage}}% dari Pengiriman - Kargo Fee - VAT',
  form_dialog: {
    fill_in_this_CSV_file_to_upload_a_entity: 'Isi file CSV ini untuk menunggah {{entity}}.',
    upload_entity_from_csv_file: 'Unggah {{entity}} dari file CSV'
  },
  form_error_message: {
    choose_category: 'Pilih {{field}}',
    document_is_required: 'This document has to be uploaded.',
    invalid_format: 'Masukkan {{field}} yang valid',
    mandatory: 'Anda wajib melengkapi {{field}}',
    max_length: 'Maksimum panjang {{field}} adalah {{max}}',
    max_value: '{{field}} harus kurang dari {{max}}',
    min_length: 'Minimum panjang {{field}} adalah {{min}}',
    percentage_length: 'Total Persentase harus 100%',
    required: 'Masukkan {{field}}',
    strong_password_rule: '{{field}} setidaknya harus memiliki satu karakter huruf besar, huruf kecil, angka, dan karakter spesial'
  },
  free_trial_logs: {
    end_date: 'Berakhir: {{date}}',
    start_date: 'Mulai: {{date}}',
  },
  general_activate: 'Activate {{name}}',
  general_activated: '{{name}} activated',
  general_activation_confirmation: 'Aktifkan {{name}}?',
  general_activation_success_message: '{{name}} berhasil aktifkan',
  general_activation_warning_message: 'Anda yakin ingin mengaktifkan {{name}}',
  general_add: 'Tambah {{name}}',
  general_againts: 'Terhadap {{entity}}',
  general_change: 'Ubah {{name}}',
  general_change_details: 'Ubah Detail {{name}}',
  general_choose: 'Pilih {{name}}',
  general_confirmation: 'Konfirmasi {{name}}',
  general_create: 'Buat {{name}}',
  general_deactivated: '{{name}} deactivated',
  general_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  general_deactivation_success_message: '{{name}} berhasil dinonaktifkan',
  general_deactivation_warning_message: 'Anda yakin ingin menonaktifkan {{name}}',
  general_edit: 'Ubah {{name}}',
  general_edit_confirmation: 'Edit {{name}}\'s Shipper Status',
  general_edit_detail: 'Ubah Detail {{name}}',
  general_edit_pic: 'Edit {{name}} PIC',
  general_edit_success_message: '{{name}} berhasil diubah.',
  general_gmv_history: 'Riwayat GMV {{name}}',
  general_new_item: '{{name}} Baru',
  general_removal_confirmation: 'Hapus {{name}}?',
  general_removal_success_message: '{{name}} berhasil dihapus',
  general_removal_warning_message: 'Anda yakin ingin menghapus {{name}}?',
  general_remove: 'Hapus {{name}}',
  general_request: 'Permintaan {{name}}',
  general_save: 'Save {{name}}',
  general_search: 'Cari {{name}}',
  general_search_placeholder: 'Cari {{fields}}',
  general_see: 'Lihat {{object}}',
  general_shipper: '{{name}} Enterprise Shipper',
  general_sme_shipper: '{{name}} SME Shipper',
  general_submit_success_message: '{{name}} berhasil terkirim.',
  general_summary: 'Ringkasan {{name}}',
  generic_edit: 'Ubah {{name}}',
  generic_failed_uploaded: '{{name}} gagal diunggah',
  generic_successfully_uploaded: '{{name}} berhasil diunggah',
  generic_text_input_placeholder: 'Ketik {{field}}',
  initial_kargo_fee: 'Kargo Fee Awal ({{percentage}}%)',
  initial_vat_kargo_fee: 'VAT Awal ({{percentage}}%)',
  invoice_date_is: 'Tanggal Invoice {{date}}',
  invoice_percentage: '{{percentage}}% Invoice',
  invoice_sent_successfully: 'Invoice {{invoice_number}} berhasil terkirim.',
  invoice_subject_template: 'Proforma Invoice No. {{invoice_number}} dari {{publisher}}',
  invoice_successfully_added: '{{invoice_number}} Berhasil Ditambahkan',
  invoice_value: '{{value}} Terhadap nilai Invoice',
  invoicing_without_tax_applied: 'Lanjut meng-Invoice customer ini tanpa detail pajak?',
  invoicing_without_tax_applied_desc: 'Invoice ini belum termasuk pemotongan pajak karena Anda belum menambahkan detail pembayaran dan pajak untuk {{name}}.Apakah Anda ingin lanjut?',
  KTP_uploaded_successfully: 'KTP berhasil diunggah',
  last_update_at: 'Update terakhir {{datetime}}',
  last_updated_at_and_by: 'Terakhir diubah {{updated_at}} oleh {{updated_by}}',
  leave_page_warning_msg: 'Setiap biaya dan detail di proforma invoice ini akan hilang.',
  load_at: 'Muat {{date}}',
  load_time: 'Muat {{datetime}}',
  location_count: '{{count}} Locations',
  log_changes: {
    active_at_period: 'Aktif di Bulan ke-{{amountCycle}}',
    commitment_number: 'Komitmen: {{amountDo}} DO',
    contract_period_number: 'Periode Kontrak: {{amountCycle}} Bulan',
    contract_period_with_date: 'Periode Kontrak: {{date}}',
    date_changes: 'Diubah tanggal {{date}}',
    date_changes_en: 'Changed in {{date}}',
    kuota_upgrade_number: 'Upgrade Kuota: {{amountQuota}}',
    quota_do_number: 'Kuota DO: {{amountDo}}',
    quota_free_number: 'Kuota Gratis: {{amountDo}}',
    user_commitment_do_changes: 'Perubahan Komitmen DO oleh {{user}}',
    user_commitment_made_by: 'Komitmen dibuat oleh {{user}}',
    user_commitment_period_changes: 'Perubahan Periode Komitmen dilakukan oleh {{user}}',
    user_commitment_period_inactive_changes: 'Periode Komitmen dinonaktifkan oleh {{user}}',
    user_contract_changes_by: 'Perubahan Kontrak dilakukan oleh {{user}}',
    user_contract_inactive_made_by: 'Kontrak dinonaktifkan oleh {{user}}',
    user_contract_made_by: 'Kontrak dibuat oleh {{user}}',
    user_contract_period_changes_by: 'Perubahan Periode Kontrak dilakukan oleh {{user}}',
    user_increment_do_changes: 'Perubahan Increment DO oleh {{user}}',
    user_upgrade_increment_changes: 'Increment DO: {{amountDo}} DO',
    user_upgrade_period_from_to_changes: 'Bulan ke {{firstPeriod}} hingga {{secondPeriod}}',
    user_upgrade_quota_changes: 'Upgrade Kuota DO oleh {{user}}',
  },
  lost_fee_warning_msg: 'Biaya ini akan hilang.',
  mark_as_payment_received: 'Menandakan Penerimaan Pembayaran {{title}}',
  multi_shipment_activity_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di lokasi bongkar {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Sampai di lokasi muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Selesai proses muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Selesai proses bongkar {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Dalam perjalanan ke lokasi bongkar {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Dalam perjalanan ke lokasi muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai proses muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai proses bongkar {{shipmentPoint}}',
  },
  // Group NFS translations under `nfs` namespace below because NFS use english.
  // TODO: find a better way to combine english and Indonesian translations
  nfs: {
    add_x: 'Add {{x}}',
    do_plan_change_log: {
      committed_do: 'Committed DO {{committedDo}}',
      free_do: 'Free DO {{freeDo}}',
      period: 'Period {{period}}',
      start_date: 'Start Date {{startDate}}',
    },
    edit_x: 'Edit {{x}}',
    plan_created_by: 'Plan created by <strong>{{name}}</strong>',
    x_successfully_removed: '{{x}} successfully removed',
    x_successfully_saved: '{{x}} successfully saved',
  },
  nft: {
    create_contract_title: 'Buat Kontrak Berlangganan - {{contractType}}',
    incremental_month: 'Bulan Ke-{{cycleMonth}}',

  },
  not_listed: 'Tidak Terdaftar',
  NPWP_uploaded_successfully: 'NPWP berhasil diunggah',
  paid_on: 'Dibayar tanggal {{date}}',
  pagination_indicator: 'Halaman {{page}} dari {{total}}',
  payment_type_ppn: 'PPN {{ppn_percentage}}%',
  percentage_additional_fee: '{{percentage}}% Biaya Tambahan',
  percentage_additional_fees: '{{percentage}}% Additional Fee',
  percentage_approved_shipment: '{{percentage}}% Pengiriman Disetujui',
  percentage_from_kargo_fee: '{{percentage}}% dari Kargo Fee',
  percentage_kargo_fee: '{{percentage}}% Kargo Fee',
  percentage_of_x: '{{percentage}}% Terhadap {{x}}',
  percentage_vat_kargo_fee: '{{percentage}}% VAT',
  pic_sequence: 'PIC {{sequence}}',
  pod_from_source: 'POD dari {{source}}',
  podf_invoice_keyword_not_found: 'Tidak menemukan invoice {{keyword}} di Vendor Central',
  podf_request_review_successfully_submitted: 'Hasil review {{podf_number}} berhasil dikirim ke {{applicant}}',
  podf_request_successfully_disbursed: 'Detail Pencairan {{podf_number}} berhasil dikirim ke {{applicant}}',
  podf_request_successfully_submitted: 'Pengajuan {{podf_number}} Berhasil ditambahkan.',
  podf_second_disbursement_formula: '{{inovicePercentage}}% Invoice {{adjustmentSign}} Adjustment {{kargoFeeAdjustmentSign}} Kargo Fee Adjustment - Late Fee',
  podf_second_disbursement_proceed: 'Pencairan ke-2 {{podfNumber}} sedang diproses',
  resend_otp_countdown_message: 'Kirim ulang kode dalam {{countdown}}',
  review_entity: 'Review {{entity}}',
  review_request_submission: 'Review Pengajuan {{request_name}}',
  revise_invoice: 'Revisi Invoice ke-{{sequence}}',
  settings_changed_by: 'Settings changed by <strong>{{name}}</strong>',
  shipment_point_multi_map: {
    [SHIPMENT_POINT_TYPES.PICKUP]: 'Muat {{pointName}}',
    [SHIPMENT_POINT_TYPES.DROPOFF]: 'Bongkar {{pointName}}',
    [SHIPMENT_POINT_TYPES.PICKUP_DROPOFF]: 'Bongkar + Muat {{pointName}}',
  },
  shipment_status_manual_update_log: 'Status diubah menjadi {{updated_status}} oleh {{actor}}',
  shipment_status_tracker_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di lokasi bongkar',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Sampai di lokasi muat',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truk & supir dipilih',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Customer Membatalkan, alasan: {{inactive_reason}}',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Dibatalkan',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Waktu Konfirmasi Habis',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Selesai muat',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Selesai bongkar',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Dalam perjalanan ke lokasi bongkar',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Dalam perjalanan ke lokasi muat',
    [SHIPMENT_STATUSES.PLANNED]: 'Truk Belum Dipilih',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Mengalihkan Penugasan',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Pengiriman selesai',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai muat',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai bongkar',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.UPCOMING]: 'Pengiriman dibuat oleh {{actor}}'
  },
  SPPKP_uploaded_successfully: 'SPPKP berhasil diunggah',
  status_logs: {
    date_changes: 'Diubah pada tanggal {{date}} WIB',
    date_changes_en: 'Changed in {{date}} WIB',
    user_changes: 'Perubahan dilakukan oleh {{user}}',
  },
  submit_truck_details: 'Kirim Detail Truk',
  submitted_at: 'Dikirim pada {{timestamp}}',
  submitted_on: 'Diajukan {{date}}',
  top_due_date: 'Jatuh Tempo: {{date}}',
  total_cost_is: 'Total Biaya {{cost}}',
  total_first_disbursement_formula_with_percentage: '{{percentage}} dari Pencairan Pertama - Kargo Fee - VAT',
  total_second_disbursement_formula_with_percentage: '{{percentage}} Pengiriman - Pencairan Pertama - Additional Fee',
  truck_and_driver_details: 'Detail Truk Dan Supir',
  truck_deactivation_warning_message: 'Anda yakin ingin menonaktifkan {{name}}',
  update_additional_fee: 'Ubah {{payment_type}}',
  update_additional_fee_for_shipment: 'Ubah Biaya Lain untuk {{shipment_number}}',
  upload_pod_for_shipment: 'Unggah POD untuk {{shipment_number}}',
  upload_receipt_with_maximum_size: 'Unggah kwitansi dengan ukuran maksimal {{max_size}}.',
  upload_the_KTP_with_a_maximum_size: 'Unggah KTP dengan ukuran maksimal {{size}}.',
  upload_the_NPWP_with_a_maximum_size: 'Unggah NPWP dengan ukuran maksimal {{size}}.',
  upload_the_SPPKP_with_a_maximum_size: 'Unggah SPPKP dengan ukuran maksimal {{size}}.',
  uploaded_file_medium_subtitle: 'Diunggah pada {{datetime}} dari {{source}}',
  uploaded_file_short_subtitle: 'Diunggah pada {{datetime}}',
  user_activation_confirmation: 'Aktifasi {{name}}?',
  user_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  user_deactivation_success_message: '{{name}} berhasil dinonaktifkan',
  user_deactivation_warning_message: '{{name}} tidak akan dapat mengakses Vendor Central, namun informasinya akan tetap disimpan. Anda tidak dapat mengaktifkannya kembali.',
  user_successfully_created: '{{name}} berhasil ditambahkan.',
  user_successfully_updated: '{{name}} berhasil diubah.',
  vehicle_successfully_created: '{{license_plate}}  berhasil ditambahkan.',
  vehicle_successfully_updated: '{{license_plate}} berhasil diubah.',
  warehouse_successfully_created: '{{name}} berhasil ditambahkan.',
  warehouse_successfully_updated: '{{name}} berhasil diubah.',
  warehouse_successfully_uploaded: '{{name}} berhasil diunggah',
  x_container_selected: '{{total_selected}} container terpilih',
  x_DOF: '{{x}} DOF',
  x_invoice: '{{x}} Invoice',
  x_invoices: '{{x}} Invoice',
  x_out_of_y_shipments: '{{x}} dari {{y}} Pengiriman',
  x_shipment_from_y_dof_selected: '{{x}} Pengiriman dari {{y}} DOF Terpilih',
  x_shipment_selected: '{{total_selected}} pengiriman dari {{shipper}} terpilih',
  x_shipment_selected_short: '{{x}} Pengiriman Terpilih',
  x_shipments: '{{x}} Pengiriman',
  x_shipments_cant_be_disbursed_yet: '{{x}} pengiriman belum dapat dicairkan',
  x_thing_for_y: '{{x}} untuk {{y}}'
};
