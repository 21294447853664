import common from './id_common';
import compose from './id_compose';
import firstMile from './id_first_mile';
import documentCollection from './id_document_collection';
import message from './id_message';
import announcement from './id_announcement';
import notification from './id_notification';

export default {
  announcement,
  common,
  compose,
  document_collection: documentCollection,
  first_mile: firstMile,
  message,
  notification,
};
