import { CONTAINER_STATUSES, FIRST_MILE_CONTAINER_SIZES, FIRST_MILE_DOCUMENT_TYPES } from '~/Configurations/constants';

export default {
  bill_of_lading: 'Bill of Lading',
  bulk_edit: 'Edit Bulk',
  container_arrival_eta: 'Perkiraan Tiba di Store',
  container_detail: 'Detail Container',
  container_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.CONTAINER_RENTAL]: 'Surat Peminjaman Kontainer',
    [FIRST_MILE_DOCUMENT_TYPES.LOLO]: 'LOLO',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]: 'Material & Safety Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.PACKING_LIST]: 'Packing List',
    [FIRST_MILE_DOCUMENT_TYPES.PROOF_OF_DELIVERY]: 'Surat Jalan',
    [FIRST_MILE_DOCUMENT_TYPES.SHIPPING_LINE_DELIVERY_ORDER]: 'Shipping Line Delivery Order',
    [FIRST_MILE_DOCUMENT_TYPES.TILA]: 'TILA'
  },
  container_holding_area: 'Lokasi Penitipan',
  container_number: 'No. Container',
  container_return_depo: 'Return Depo',
  container_sizes: {
    [FIRST_MILE_CONTAINER_SIZES['20_FEET']]: '20"',
    [FIRST_MILE_CONTAINER_SIZES['20_FR']]: '20" FR',
    [FIRST_MILE_CONTAINER_SIZES['20_GP']]: '20" GP',
    [FIRST_MILE_CONTAINER_SIZES['20_OT']]: '20" OT',
    [FIRST_MILE_CONTAINER_SIZES['20_OW']]: '20" OW',
    [FIRST_MILE_CONTAINER_SIZES['40_FEET']]: '40"',
    [FIRST_MILE_CONTAINER_SIZES['40_FR']]: '40" FR',
    [FIRST_MILE_CONTAINER_SIZES['40_GP']]: '40" GP',
    [FIRST_MILE_CONTAINER_SIZES['40_HC']]: '40" HC',
    [FIRST_MILE_CONTAINER_SIZES['40_OT']]: '40" OT',
    [FIRST_MILE_CONTAINER_SIZES['40_OW']]: '40" OW',
    [FIRST_MILE_CONTAINER_SIZES['45_FEET']]: '45"'
  },
  container_status: {
    [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'Sampai di Pelabuhan',
    [CONTAINER_STATUSES.INVOICED]: 'Ditagih',
    [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'Dipindah ke penitipan',
    [CONTAINER_STATUSES.NO_NOA]: 'NOA belum diterima',
    [CONTAINER_STATUSES.NOA]: 'NOA',
    [CONTAINER_STATUSES.ON_SHIPMENT]: 'Dalam Perjalanan',
    [CONTAINER_STATUSES.PAID]: 'Dibayar',
    [CONTAINER_STATUSES.PAYMENT_PENDING]: 'Pembayaran Pending',
    [CONTAINER_STATUSES.PIB]: 'PIB',
    [CONTAINER_STATUSES.QUARANTINED]: 'Dikarantina',
    [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'Selesai',
    [CONTAINER_STATUSES.SPPB]: 'SPPB',
    [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'Menunggu Original Doc',
    [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'Menunggu SPPB'
  },
  container_type: 'Jenis Container',
  container_unloading_capacity: 'Kapasitas Bongkar Harian',
  container_use_holding: 'Penitipan',
  container_using_holding: 'Container Dititip',
  container_weight: 'Berat Container',
  date_placed_in_holding: 'Tanggal dititip',
  location_detail: 'Detail Lokasi',
  other_documents: 'Dokumen Lain',
  port_address: 'Alamat Pelabuhan',
  port_detail: 'Detail Pelabuhan',
  seal_number: 'No. Seal',
  terminal: 'Terminal'
};
