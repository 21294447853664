export default {
  add_notification: 'Tambah Notifikasi',
  channel: 'Channel',
  channel_config: 'Channel Config',
  check_notification: 'Cek Notifikasi',
  check_notification_message:
  '*Tekan tombol \'Cek Notifikasi\' untuk memverifikasi script notifikasi sebelum bisa '
  + 'tekan tombol \'Kirim Sekarang\' atau \'Simpan Notifikasi\'.',
  edit_notifications: 'Perbaharui Notifikasi',
  event_name: 'Event Name',
  inserted_at_notification: 'Tanggal Dibuat',
  new_notifications: 'Notifikasi Baru',
  new_notification_tooltip_message: {
    channel: 'how to send notification, currently accepting: mobile-push, web-push, email, sms',
    channel_config:
    'JSON map of possible channel config for this notification, relevant for mobile-push to specify action code '
    + 'and relevant data. This config is dynamically templated, based on mustache syntax. Action code determine what '
    + 'action happen when user click on notification, and action_data supply necessary information for the action',
    event_name:
    'event name for notification, relevant for analytics to track notification engagement, '
    + 'should be low-cased and snake-cased event name format (ex: "experiment_transporter_engagement_loading")',
    sql_query:
    'SQL Query used to determine who & when to send notification, this query will be executed every 30 minute '
    + 'to real-time DB v2. For getting current time in SQL query, please use NOW() function.',
  },
  notifications: 'Notifikasi',
  notification_demo_table_titles: {
    channel: 'Channel',
    channel_config: 'Channel Config',
    event_type: 'Event Type',
    judul: 'Judul',
    no_hp: 'No. HP',
    penerima: 'Penerima',
    pesan: 'Pesan',
  },
  notification_deactivate_title: 'Matikan Notifikasi?',
  notification_deactivate_message:
    'Notifikasi akan dimatikan dari daftar dan tidak akan bisa diaktifkan lagi. Lanjutkan?',
  notification_result: 'Hasil notifikasi',
  notification_successfully_create: 'Notifikasi berhasil dibuat',
  notification_successfully_edit: 'Notifikasi berhasil di edit',
  notification_successfully_send: 'Notifikasi berhasil di kirim',
  save_notification: 'Simpan Notifikasi',
  search_notification: 'Cari notifikasi',
  send_now: 'Kirim Sekarang',
  select_channel: 'Pilih Channel',
  sql_query: 'Sql Query',
  text_input: 'Text Input',
};
