/* eslint-disable max-len */
import { ACTIVE_STATUS } from '~/Pages/Announcement/constants';

export default {
  action: 'Action',
  announcement: 'Pengumuman',
  announcement_succefully_created: 'Pengumuman Berhasil Dibuat',
  announcement_succefully_deactivated: 'Pengumuman Berhasil Dinonaktifkan',
  announcement_status: {
    [ACTIVE_STATUS.ACTIVE]: 'Active',
    [ACTIVE_STATUS.INACTIVE]: 'Inactive',
  },
  cancel_confirmation_announcement_cancel_button: 'Kembali',
  cancel_confirmation_announcement_description: 'Semua perubahan yang Anda buat akan dibatalkan. Lanjutkan?',
  cancel_confirmation_announcement_ok_button: 'Lanjut',
  cancel_confirmation_announcement_title: 'Batalkan Perubahan?',
  cancel_delete_announcement: 'Tidak, Batalkan',
  confirm_delete_announcement: 'Ya, Matikan',
  create_announcement: 'Buat Pengumuman',
  deactivate_announcement: 'Matikan Pengumuman',
  description_delete_announcement_dialog: 'Pengumuman akan dimatikan dari daftar dan tidak akan bisa diaktifkan lagi. Lanjutkan?',
  detail_banner: 'Banner Detail',
  detail_banner_file_upload_note: 'PNG (ukuran maksimum 1MB), rasio 4:3 (horizontal / landscape)',
  file_uploader: 'File Uploader',
  image: 'Gambar',
  image_successfully_uploaded: 'Unggah gambar berhasil',
  link_target_article: 'Link Menuju Artikel',
  link_target_article_note: 'Masukkan link artikel untuk landing page',
  main_banner: 'Banner Utama',
  main_banner_file_upload_note: 'PNG (ukuran maksimum 1MB), rasio 3:4 (vertikal / portrait)',
  new_announcement: 'Pengumuman Baru',
  placeholder_announcement_link: 'https://',
  placeholder_short_description: 'Body Paragraph 2. A more elaborate and main message of the page. Make it clear and concise.',
  placeholder_title: 'Text Input',
  short_description: 'Deskripsi Singkat',
  short_description_note: 'Akan muncul sebagai deskripsi di notifikasi dan landing page',
  submit_confirmation_announcement_cancel_button: 'Kembali',
  submit_confirmation_announcement_description: 'Pengumuman akan dibuat dan akan muncul di aplikasi Kargo Vendor dan dapat diakses oleh Transporter',
  submit_confirmation_announcement_ok_button: 'Buat Post',
  submit_confirmation_announcement_title: 'Buat Post?',
  title_delete_announcement_dialog: 'Matikan Pengumuman?',
  title_note: 'Akan muncul sebagai judul di notifikasi dan landing page',
  upload_new_announcement: 'Upload Pegumuman Baru',
  view_image: 'Lihat Gambar',
  view_link: 'Lihat Link',
};
