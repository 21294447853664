import {
  PRICING_RATE_TYPE,
  PRICING_RATE_TYPES,
} from '~/Configurations/constants';

export const ROUTE_POINT = {
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
};

export const PRICE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
};

export const PRICE_STATUS_COLOR = {
  [PRICE_STATUS.ACTIVE]: 'success',
  [PRICE_STATUS.INACTIVE]: 'danger',
};

export const BTMS_PRICING_RATE_TYPE = PRICING_RATE_TYPE.filter(({ value }) => value !== PRICING_RATE_TYPES.PER_LANE);

export default {};
