import { get } from 'lodash-es';

export const getUserProfile = data => {
  const employeeProfile = get(data, 'myEmployeeProfile');
  const companyDetails = get(data, 'myEmployeeProfile.company');
  const companyDocuments = get(data, 'companySettings.companyDocuments');
  const companyBankAccounts = get(data, 'companyBankAccounts');
  return {
    ksuid: get(employeeProfile, 'ksuid'),
    name: get(employeeProfile, 'name'),
    email: get(employeeProfile, 'user.email'),
    phoneNumber: get(employeeProfile, 'user.phoneNumber'),
    defaultLocale: get(employeeProfile, 'user.defaultLocale'),
    company: {
      accessType: get(employeeProfile, 'accessType'),
      accessTypes: get(employeeProfile, 'accessTypes', []),
      signatureName: get(companyDetails, 'signatureName'),
      signatureTitle: get(companyDetails, 'signatureTitle'),
      hasConsentedCustomer: Boolean(get(companyDetails, 'hasConsentedCustomer')),
      isPartneredWithEnterpriseShipper: get(companyDetails, 'isPartneredWithEnterpriseShipper'),
      metadata: get(companyDetails, 'metadata'),
      address: get(data, 'companySettings.companyDetails.address'),
      email: get(data, 'companySettings.companyDetails.email'),
      handle: get(data, 'companySettings.companyDetails.handle'),
      isDofEligible: get(data, 'companySettings.companyDetails.isDofEligible'),
      isPodfEligible: get(data, 'companySettings.companyDetails.isPodfEligible'),
      ksuid: get(data, 'companySettings.companyDetails.ksuid'),
      logo: {
        url: get(data, 'companySettings.companyDetails.logo.url.full'),
        fileName: get(data, 'companySettings.companyDetails.logo.fileName')
      },
      name: get(data, 'companySettings.companyDetails.name'),
      phoneNumber: get(data, 'companySettings.companyDetails.phoneNumber'),
      status: get(data, 'companySettings.companyDetails.status'),
      type: get(data, 'companySettings.companyDetails.type'),
      documents: {
        ktp: {
          url: get(companyDocuments, 'ktpFile.url.full'),
          fileName: get(companyDocuments, 'ktpFile.fileName'),
          uploadedAt: get(companyDocuments, 'ktpFile.uploadedAt.datetime')
        },
        npwp: {
          url: get(companyDocuments, 'npwpFile.url.full'),
          fileName: get(companyDocuments, 'npwpFile.fileName'),
          uploadedAt: get(companyDocuments, 'npwpFile.uploadedAt.datetime')
        },
        sppkp: {
          url: get(companyDocuments, 'sppkpFile.url.full'),
          fileName: get(companyDocuments, 'sppkpFile.fileName'),
          uploadedAt: get(companyDocuments, 'sppkpFile.uploadedAt.datetime')
        }
      }
    },
    bankAccounts: Array.isArray(companyBankAccounts)
      ? companyBankAccounts.map(account => ({
        ksuid: get(account, 'ksuid'),
        accountHolder: get(account, 'accountHolder'),
        accountNumber: get(account, 'accountNumber'),
        isDefault: get(account, 'isDefault'),
        bank: {
          ksuid: get(account, 'bank.ksuid'),
          name: get(account, 'bank.name')
        }
      }))
      : []
  };
};

export default getUserProfile;
