import React, { Suspense, lazy, useContext } from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageSuspenseFallback } from '@kargotech/tms-ui/components';
import {
  LOCAL_SHIPMENT_STATUSES,
  USER_ACCESS_TYPE
} from '~/Configurations/constants';
import { PrivateRoute, AuthRoute } from '~/Components/Route';
import { ProfileContext } from '~/Contexts/ProfileProvider';
import { ACCESS_PRIVILEGE } from './accessPrevillegeMap';

/* eslint-disable max-len */
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ '~/Layouts/MainLayout'));
const MinimalLayout = lazy(() => import(/* webpackChunkName: "MinimalLayout" */ '~/Layouts/MinimalLayout'));

const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ '~/Pages/Auth/Login'));
const RegisterPage = lazy(() => import(/* webpackChunkName: "RegisterPage" */ '~/Pages/Auth/Register'));
const RegisterDetailsPage = lazy(() => import(/* webpackChunkName: "RegisterDetailsPage" */ '~/Pages/Auth/Register/RegisterDetails'));
const OtpVerification = lazy(() => import(/* webpackChunkName: "OtpVerification" */ '~/Pages/Auth/OtpVerification'));
const ChooseCompanyPage = lazy(() => import(/* webpackChunkName: "ChooseCompanyPage" */ '~/Pages/Auth/ChooseCompanyPage'));

const CompanyDetails = lazy(() => import(/* webpackChunkName: "CompanyDetails" */ '~/Pages/Company/CompanyDetails'));
const VehicleDetails = lazy(() => import(/* webpackChunkName: "VehicleDetails" */ '~/Pages/Company/Vehicles/VehicleDetails'));

const Employees = lazy(() => import(/* webpackChunkName: "Employees" */ '~/Pages/Company/Employees'));
const Vehicles = lazy(() => import(/* webpackChunkName: "Vehicles" */ '~/Pages/Company/Vehicles'));
const Drivers = lazy(() => import(/* webpackChunkName: "Drivers" */ '~/Pages/Company/Drivers'));
const DriverDetail = lazy(() => import(/* webpackChunkName: "DriverDetail" */ '~/Pages/Company/Drivers/DriverDetail/DriverDetail'));
const PersonalAccount = lazy(() => import(/* webpackChunkName: "PersonalAccount" */ '~/Pages/PersonalAccount'));
const TransporterListPage = lazy(() => import(/* webpackChunkName: "TransporterListPage" */ '~/Pages/Company/Transporter/TransporterListPage'));
const TransporterDetailsPage = lazy(() => import(/* webpackChunkName: "TransporterDetailsPage" */ '~/Pages/Company/Transporter/TransporterDetailsPage'));
const ContractPage = lazy(() => import(/* webpackChunkName: "ContractPage" */ '~/Pages/Company/Transporter/ContractPage'));
const SwiftPaymentProjectsList = lazy(() => import(/* webpackChunkName: "SwiftPaymentProjectsList" */ '~/Pages/Payment/SwiftPaymentProjectsList/SwiftPaymentProjectsList'));
const SwiftPaymentProjectDetailsPage = lazy(() => import(/* webpackChunkName: "SwiftPaymentProjectDetailsPage" */ '~/Pages/Payment/SwiftPaymentProjectDetailsPage/SwiftPaymentProjectDetailsPage'));

// eslint-disable-next-line
const DisbursementSchemeListPage = lazy(() => import(/* webpackChunkName: "DisbursementSchemeListPage" */ '~/Pages/Payment/DisbursementScheme/DisbursementSchemeListPage'));
// eslint-disable-next-line
const DisbursementSchemeDetailPage = lazy(() => import(/* webpackChunkName: "DisbursementSchemeDetailPage" */ '~/Pages/Payment/DisbursementScheme/DisbursementSchemeDetailPage'));
const AgingListPage = lazy(() => import(/* webpackChunkName: "AgingListPage" */ '../Pages/Payment/AgingListPage'));
const PaymentShipment = lazy(() => import(/* webpackChunkName: "PaymentShipment" */ '~/Pages/Payment/ShipmentListPage/ShipmentListPage'));
const PaymentInvoiceList = lazy(() => import(/* webpackChunkName: "PaymentInvoiceList" */ '~/Pages/Payment/InvoiceListPage/InvoiceListPage'));
const SwiftPaymentListPage = lazy(() => import(/* webpackChunkName: "SwiftPaymentListPage" */ '~/Pages/Payment/SwiftPaymentListPage'));
const DofRequestItemPage = lazy(() => import(/* webpackChunkName: "DofRequestItemPage" */ '~/Pages/Payment/DofRequestItemPage'));
const DofRequestDetailsPage = lazy(() => import(/* webpackChunkName: "DofRequestDetailsPage" */ '~/Pages/Payment/DofRequestItemPage/DofRequestDetailsPage'));
const DofSummaryDetailsPage = lazy(() => import(/* webpackChunkName: "DofSummaryDetailsPage" */ '~/Pages/Payment/DofRequestItemPage/DofSummaryDetailsPage'));
const Dof2RequestItemPage = lazy(() => import(/* webpackChunkName: "Dof2RequestItemPage" */ '~/Pages/Payment/Dof2RequestItemPage'));
const Dof2RequestDetailsPage = lazy(() => import(/* webpackChunkName: "Dof2RequestDetailsPage" */ '~/Pages/Payment/Dof2RequestItemPage/Dof2RequestDetailsPage'));
const Dof2SummaryDetailsPage = lazy(() => import(/* webpackChunkName: "Dof2SummaryDetailsPage" */ '~/Pages/Payment/Dof2RequestItemPage/Dof2SummaryDetailsPage'));
const PodfSummaryDetailsPage = lazy(() => import(/* webpackChunkName: "PodfSummaryDetailsPage" */ '~/Pages/Payment/PodfRequestItemPage/PodfSummaryDetailsPage'));
const PodfRequestItemPage = lazy(() => import(/* webpackChunkName: "PodfRequestItemPage" */ '~/Pages/Payment/PodfRequestItemPage'));
const PodfRequestDetailsPage = lazy(() => import(/* webpackChunkName: "PodfRequestDetailsPage" */ '~/Pages/Payment/PodfRequestItemPage/PodfRequestDetailsPage'));

const InvoiceSummary = lazy(() => import(/* webpackChunkName: "InvoiceSummary" */ '~/Pages/Payment/InvoiceSummary'));
const InvoiceHistory = lazy(() => import(/* webpackChunkName: "InvoiceHistory" */ '~/Pages/Payment/InvoiceHistory'));

const NFSFeatureConfigs = lazy(() => import(/* webpackChunkName: "NFSFeatureConfigs" */ '~/Pages/Company/CompanyFeatureConfigs/NFSFeatureConfigs'));
const NFTFeatureConfigs = lazy(() => import(/* webpackChunkName: "NFTFeatureConfigs" */ '~/Pages/Company/CompanyFeatureConfigs/NFTFeatureConfigs'));
const CustomerListPage = lazy(() => import(/* webpackChunkName: "CustomerListPage" */ '~/Pages/Customers/CustomerListPage'));
const CustomerDetailPage = lazy(() => import(/* webpackChunkName: "CustomerDetailPage" */ '~/Pages/Customers/CustomerDetailPage'));
const CustomerSMEDetailPage = lazy(() => import(/* webpackChunkName: "CustomerSMEDetailPage" */ '~/Pages/Customers/CustomerDetailPage/CustomerSMEDetailPage'));
const CommittedDeliveryOrderPage = lazy(() => import(/* webpackChunkName: "CommittedDeliveryOrderPage" */ '~/Pages/Customers/CommittedDeliveryOrderPage/CommittedDeliveryOrderPage'));
const WarehouseListPage = lazy(() => import(/* webpackChunkName: "WarehouseListPage" */ '~/Pages/Customers/WarehouseListPage'));
const WarehouseDetailPage = lazy(() => import(/* webpackChunkName: "WarehouseDetailPage" */ '~/Pages/Customers/WarehouseDetailPage'));

const ShipmentListPage = lazy(() => import(/* webpackChunkName: "ShipmentListPage" */ '~/Pages/Shipment/ShipmentListPage'));
const ShipmentDetailPage = lazy(() => import(/* webpackChunkName: "ShipmentDetailPage" */ '~/Pages/Shipment/ShipmentDetailPage'));

const ContainerDetailPage = lazy(() => import(/* webpackChunkName: "ContainerDetailPage" */ '~/Pages/Container/ContainerDetailPage'));

const VehicleTrackingPage = lazy(() => import(/* webpackChunkName: "VehicleTrackingPage" */ '~/Pages/Vehicle/VehicleTrackingPage'));
const WelcomePage = lazy(() => import(/* webpackChunkName: "WelcomePage" */ '~/Pages/WelcomePage'));

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '~/Pages/App/NotFound'));
const AnnouncementPage = lazy(() => import(/* webpackChunkName: "AnnouncementPage" */ '~/Pages/Announcement'));
const AddNewAnnouncementPage = lazy(() => import(/* webpackChunkName: "AddNewAnnouncementPage" */ '~/Pages/Announcement/AddNewAnnouncement'));

const NotificationsPage = lazy(() => import(/* webpackChunkName: "NotificationPage" */ '~/Pages/Notifications'));
const AddNewNotificationsPage = lazy(() => import(/* webpackChunkName: "AddNewNotificationPage" */ '~/Pages/Notifications/AddNewNotifications'));

/* eslint-enable max-len */

export default function Routes() {
  const { t } = useTranslation();

  const {
    profile,
    getSelectedCompany,
    isAuthorizedToAccess
  } = useContext(ProfileContext);

  const companyPageTabNavigation = [
    isAuthorizedToAccess(ACCESS_PRIVILEGE.COMPANY_DETAILS_READ) && {
      path: '/company/details',
      title: t('common:company_details')
    },
    isAuthorizedToAccess(ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ) && {
      path: '/company/transporter-list',
      title: t('common:transporter')
    },
    {
      path: '/company/employees',
      title: t('common:employees')
    },
    {
      path: '/company/vehicles',
      title: t('common:vehicles')
    },
    {
      path: '/company/drivers',
      title: t('common:drivers')
    },
    {
      path: '/company/financing-project/list',
      title: t('document_collection:fast_payment_project')
    },
  ].filter(Boolean);

  const customerPageTabNavigation = [
    {
      path: '/customer/customer-list',
      title: isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
        ? t('common:shipper')
        : t('common:customer')
    },
    {
      path: '/customer/warehouse-list',
      title: t('common:warehouse')
    }
  ];

  const customerSMEDetailsTabNavigation = [
    {
      path: '?tab=cdetails',
      title: 'Company Details'
    },
    {
      path: '?tab=cpodReturnsClaims',
      title: 'POD Returns & Claims'
    },
    {
      path: '?tab=clocationpic',
      title: 'Location PICs'
    },
    {
      path: '?tab=cprices',
      title: 'Prices'
    }
  ];

  const customerSMEDetailsInvoicedTabNavigation = [
    {
      path: '?tab=cdetails',
      title: 'Company Details'
    },
    {
      path: '?tab=cpayments',
      title: 'Payments'
    },
    {
      path: '?tab=cpodReturnsClaims',
      title: 'POD Returns & Claims'
    },
    {
      path: '?tab=clocationpic',
      title: 'Location PICs'
    },
    {
      path: '?tab=cprices',
      title: 'Prices'
    }
  ];

  const paymentTabNavigation = [
    {
      path: '/payment/shipment-list',
      title: t('common:shipment')
    },
    {
      path: '/payment/invoice-list',
      title: t('common:invoice')
    },
    (isAuthorizedToAccess(ACCESS_PRIVILEGE.SWIFT_PAYMENT_BYPASS)
      || profile.company.isDofEligible
      || profile.company.isPodfEligible)
    && {
      path: '/payment/swift-payment-list',
      title: t('common:swift_payment')
    },
    {
      path: '/payment/aging',
      title: t('common:aging')
    },
  ].filter(Boolean);

  const shipmentPageTabNavigation = Object.keys(LOCAL_SHIPMENT_STATUSES)
    .filter(statusKey => {
      if (LOCAL_SHIPMENT_STATUSES[statusKey] === LOCAL_SHIPMENT_STATUSES.UPCOMING) {
        return false;
      }

      return !([
        LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE,
        LOCAL_SHIPMENT_STATUSES.AT_LOCATION,
        LOCAL_SHIPMENT_STATUSES.IN_TRANSIT,
      ].includes(LOCAL_SHIPMENT_STATUSES[statusKey]));
    })
    .map(
      statusKey => ({
        path: `/shipment/list/${LOCAL_SHIPMENT_STATUSES[statusKey]}`,
        title: t(
          `common:local_shipment_statuses.${LOCAL_SHIPMENT_STATUSES[statusKey]}`
        )
      })
    );

  const featureConfigTab = [
    {
      path: '/company/feature-configs/nfs',
      title: t('common:feature_configs_tab.nfs')
    },
    {
      path: '/company/feature-configs/nft',
      title: t('common:feature_configs_tab.nft')
    },
  ];

  const renderDefaultRootPath = () => {
    if (getSelectedCompany().accessType === USER_ACCESS_TYPE.FINANCE) {
      return <Redirect exact={true} from="/" to="/company/details" />;
    }

    return <Redirect exact={true} from="/" to="/shipment" />;
  };

  return (
    <Suspense fallback={<PageSuspenseFallback />}>
      <Switch>
        <Route path="/auth">
          <MinimalLayout>
            <Suspense fallback={<PageSuspenseFallback />}>
              <Switch>
                <AuthRoute
                  exact={true}
                  path="/auth/login/:accessType?"
                  showLogo={true}
                  title={t('common:login')}
                >
                  <LoginPage />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/register"
                  showLogo={true}
                  title={t('common:register')}
                >
                  <RegisterPage />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/register/details/:phoneNumber"
                  showLogo={false}
                  title={t('message:fill_account_details')}
                >
                  <RegisterDetailsPage />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/verification/:phoneNumber"
                >
                  <OtpVerification options="REGISTRATION" />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/forgot-password/:phoneNumber"
                >
                  <OtpVerification options="RESET_PASSWORD" />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/choose-company"
                  showLogo={false}
                  title={t('common:choose_company')}
                >
                  <ChooseCompanyPage />
                </AuthRoute>
                <Redirect to="/auth/login" />
              </Switch>
            </Suspense>
          </MinimalLayout>
        </Route>

        <PrivateRoute path="/welcome" title="">
          <MinimalLayout>
            <WelcomePage />
          </MinimalLayout>
        </PrivateRoute>

        <PrivateRoute path="/">
          <MainLayout>
            <Suspense fallback={<PageSuspenseFallback />}>
              <Switch>
                <PrivateRoute
                  exact={true}
                  path="/personal-account"
                  title={t('common:personal_account')}
                >
                  <PersonalAccount />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/company/details"
                  tabNavigation={companyPageTabNavigation}
                  title={t('common:company_settings')}
                >
                  <CompanyDetails />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/transporter-list"
                  tabNavigation={companyPageTabNavigation}
                  title={t('common:transporter_list')}
                >
                  <TransporterListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/employees"
                  tabNavigation={companyPageTabNavigation}
                  title={
                    isAuthorizedToAccess(
                      ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ
                    )
                      ? t('common:transporter_list')
                      : t('common:company_settings')
                  }
                >
                  <Employees />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/vehicles"
                  tabNavigation={companyPageTabNavigation}
                  title={
                    isAuthorizedToAccess(
                      ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ
                    )
                      ? t('common:transporter_list')
                      : t('common:company_settings')
                  }
                >
                  <Vehicles />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/vehicle-details/:vehicleKsuid"
                >
                  <VehicleDetails />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/drivers"
                  tabNavigation={companyPageTabNavigation}
                  title={
                    isAuthorizedToAccess(
                      ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ
                    )
                      ? t('common:transporter_list')
                      : t('common:company_settings')
                  }
                >
                  <Drivers />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/financing-project/list"
                  tabNavigation={companyPageTabNavigation}
                  title={
                    isAuthorizedToAccess(
                      ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ
                    )
                      ? t('common:transporter_list')
                      : t('common:company_settings')
                  }
                >
                  <SwiftPaymentProjectsList />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/financing-project/:projectKsuid"
                  title={
                    isAuthorizedToAccess(
                      ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ
                    )
                      ? t('common:transporter_list')
                      : t('common:company_settings')
                  }
                >
                  <SwiftPaymentProjectDetailsPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/transporter/contracts/:companyKsuid"
                  skipTabNavigationSetup={true}
                >
                  <ContractPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/transporter/details/:companyKsuid"
                  skipTabNavigationSetup={true}
                >
                  <TransporterDetailsPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/transporter/employees/:companyKsuid"
                  skipTabNavigationSetup={true}
                >
                  <Employees />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/transporter/vehicles/:companyKsuid"
                  skipTabNavigationSetup={true}
                >
                  <Vehicles />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/transporter/drivers/:companyKsuid"
                  skipTabNavigationSetup={true}
                >
                  <Drivers />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/company"
                  to={
                    isAuthorizedToAccess(
                      ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ
                    )
                      ? '/company/transporter-list'
                      : '/company/details'
                  }
                />
                <PrivateRoute
                  exact={true}
                  path="/company/driver-details/:driverKsuid"
                >
                  <DriverDetail />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/customer/customer-list"
                  tabNavigation={customerPageTabNavigation}
                  title={
                    isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
                      ? t('common:shipper_list')
                      : t('common:customer_list')
                  }
                >
                  <CustomerListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/feature-configs/nfs"
                  title={t('common:feature_configs')}
                  tabNavigation={featureConfigTab}
                >
                  <NFSFeatureConfigs />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/company/feature-configs/nft"
                  title={t('common:feature_configs')}
                  tabNavigation={featureConfigTab}
                >
                  <NFTFeatureConfigs />
                </PrivateRoute>
                <Redirect
                  exact={true}
                  from="/company/feature-configs"
                  to="/company/feature-configs/nfs"
                />
                <PrivateRoute
                  exact={true}
                  path="/customer/customer-details/:shipperKsuid/:transporterKsuid"
                  skipTabNavigationSetup={true}
                >
                  <CustomerDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/committed-delivery-order/:shipperKsuid/:transporterKsuid"
                  skipTabNavigationSetup={true}
                >
                  <CommittedDeliveryOrderPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/sme-details/:customerKsuid/:companyKsuid?"
                  tabNavigation={customerSMEDetailsTabNavigation}
                >
                  <CustomerSMEDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/sme-details/invoice/:customerKsuid/:companyKsuid?"
                  tabNavigation={customerSMEDetailsInvoicedTabNavigation}
                >
                  <CustomerSMEDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/warehouse-list"
                  tabNavigation={customerPageTabNavigation}
                  title={
                    isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
                      ? t('common:shipper_list')
                      : t('common:customer_list')
                  }
                >
                  <WarehouseListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/warehouse-details/:warehouseKsuid"
                >
                  <WarehouseDetailPage />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/customer"
                  to="/customer/customer-list"
                />

                <PrivateRoute
                  exact={true}
                  path="/shipment/list/:shipmentStatus"
                  tabNavigation={shipmentPageTabNavigation}
                  title={t('common:shipment')}
                >
                  <ShipmentListPage />
                </PrivateRoute>
                <Redirect
                  exact={true}
                  from="/shipment"
                  to="/shipment/list/planned"
                />
                <Redirect
                  exact={true}
                  from="/shipment/list"
                  to="/shipment/list/planned"
                />

                <PrivateRoute
                  exact={true}
                  path="/shipment/details/:shipmentID"
                >
                  <ShipmentDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/container/details/:containerID"
                >
                  <ContainerDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/vehicle/tracking"
                  title={t('common:vehicle')}
                >
                  <VehicleTrackingPage />
                </PrivateRoute>
                <Redirect
                  exact={true}
                  from="/vehicle"
                  to="/vehicle/tracking"
                />

                <PrivateRoute
                  exact={true}
                  path="/payment/shipment-list"
                  tabNavigation={paymentTabNavigation}
                  title={t('common:payment')}
                >
                  <PaymentShipment />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/invoice-list"
                  tabNavigation={paymentTabNavigation}
                  title={t('common:payment')}
                >
                  <PaymentInvoiceList />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/aging"
                  tabNavigation={paymentTabNavigation}
                  title={t('common:payment')}
                >
                  <AgingListPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/swift-payment-list"
                  tabNavigation={paymentTabNavigation}
                  title={t('common:payment')}
                >
                  <SwiftPaymentListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/disbursement-scheme"
                  skipTabNavigationSetup={true}
                  title={t('common:disbursement_scheme')}
                >
                  <DisbursementSchemeListPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/disbursement-scheme/:disbursementSchemeKsuid"
                >
                  <DisbursementSchemeDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/dof-summary/:dofRequestKsuid"
                  skipTabNavigationSetup={true}
                >
                  <DofRequestItemPage>
                    <DofSummaryDetailsPage />
                  </DofRequestItemPage>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/dof-details/:dofRequestKsuid"
                  skipTabNavigationSetup={true}
                >
                  <DofRequestItemPage>
                    <DofRequestDetailsPage />
                  </DofRequestItemPage>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/podf-details/:podfRequestKsuid"
                  skipTabNavigationSetup={true}
                >
                  <PodfRequestItemPage>
                    <PodfRequestDetailsPage />
                  </PodfRequestItemPage>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/podf-summary/:podfRequestKsuid"
                  skipTabNavigationSetup={true}
                >
                  <PodfRequestItemPage>
                    <PodfSummaryDetailsPage />
                  </PodfRequestItemPage>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/dof2-details/:dof2RequestKsuid"
                  skipTabNavigationSetup={true}
                >
                  <Dof2RequestItemPage>
                    <Dof2RequestDetailsPage />
                  </Dof2RequestItemPage>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/dof2-summary/:dof2RequestKsuid"
                  skipTabNavigationSetup={true}
                >
                  <Dof2RequestItemPage>
                    <Dof2SummaryDetailsPage />
                  </Dof2RequestItemPage>
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/payment"
                  to="/payment/shipment-list"
                />

                <PrivateRoute
                  exact={true}
                  path="/payment/invoice-details/:invoiceKsuid"
                >
                  <InvoiceSummary />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/invoice-history/:invoiceKsuid"
                >
                  <InvoiceHistory />
                </PrivateRoute>
                <Redirect
                  exact={true}
                  from="/invoice/details/:invoiceNumber"
                  to="/invoice/details/summary"
                />
                <PrivateRoute exact={true} path="/announcement" title={t('common:announcement')}>
                  <AnnouncementPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/announcement/add-new" title={t('announcement:new_announcement')}>
                  <AddNewAnnouncementPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/notifications" title={t('notification:notifications')}>
                  <NotificationsPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/notifications/add-new" title={t('notification:new_notifications')}>
                  <AddNewNotificationsPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/notifications/edit/:notificationKsuid"
                  title={t('notification:edit_notifications')}
                >
                  <AddNewNotificationsPage />
                </PrivateRoute>

                {renderDefaultRootPath()}
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </MainLayout>
        </PrivateRoute>

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
