import React, { Fragment, useContext, useEffect, useCallback } from 'react';
import { Route as ReactRoute, Redirect, useLocation } from 'react-router-dom';
import { get } from 'lodash-es';
import { PageSuspenseFallback } from '@kargotech/tms-ui/components';
import { AUTH_STATUS, AuthContext } from '@kargotech/tms-core/auth';
import { NavigationContext } from '../../Contexts/NavigationProvider';

/**
 * Private Route: can only be accessed by non-authenticated user
 * @param {Object} props
 * @param {import('react').ReactElement} props.children
 * @param {import('react').ReactNode} props.Layout
 * @param {String} props.title
 * @param {String} props.subtitle
 * @param {String} props.tabNavigation.path
 * @param {String} props.tabNavigation.title
 * @param {Boolean} props.showLogo
 */
function AuthRoute({
  children,
  Layout = Fragment,
  title = '',
  subtitle = '',
  showLogo = false,
  ...routeProps
}) {
  const {
    authStatus,
    enrolledCompanies,
    fetchAndSaveEnrolledCompanies,
    selectedCompanyKsuid,
    updateSessionStatus,
  } = useContext(AuthContext);
  const { setTitle, setSubtitle, setTabNavigation, setShowLogo } = useContext(NavigationContext);

  const { pathname, state } = useLocation();

  const updateSessionAndEnrolledCompanies = useCallback(async () => {
    const isAuthenticated = await updateSessionStatus();
    if (isAuthenticated) {
      await fetchAndSaveEnrolledCompanies();
    }
  }, [fetchAndSaveEnrolledCompanies, updateSessionStatus]);

  useEffect(() => {
    updateSessionAndEnrolledCompanies();
  }, [updateSessionAndEnrolledCompanies]);

  useEffect(() => {
    setTitle(title);
    setSubtitle(subtitle);
    setTabNavigation([]);
    setShowLogo(showLogo);
  }, [title, setTitle, subtitle, setSubtitle, setTabNavigation, showLogo, setShowLogo]);

  const wrapRender = useCallback(renderedEl => (
    <ReactRoute {...routeProps}>
      {renderedEl}
    </ReactRoute>
  ), [routeProps]);

  if (authStatus === AUTH_STATUS.LOADING || (authStatus === AUTH_STATUS.AUTHENTICATED && !enrolledCompanies.length)) {
    return wrapRender(
      <PageSuspenseFallback />
    );
  }

  if (authStatus === AUTH_STATUS.AUTHENTICATED && selectedCompanyKsuid) {
    return wrapRender(
      <Redirect
        to={{
          pathname: get(state, 'from') || '/',
          search: get(state, 'fromSearch'),
          state: { from: pathname }
        }}
      />
    );
  }

  // User who already logged in but has not choose any company will be
  // redirected to choose company page
  if (
    authStatus === AUTH_STATUS.AUTHENTICATED
    && !selectedCompanyKsuid
    && enrolledCompanies.length > 1
    && pathname !== '/auth/choose-company'
  ) {
    return wrapRender(
      <Redirect to="/auth/choose-company" />
    );
  }

  return wrapRender(
    <Layout>
      {children}
    </Layout>
  );
}

export default AuthRoute;
