export const AR_AGING_LABELS = {
  AGING_DUE: 'AGING_DUE',
  AGING_DUE_TODAY: 'AGING_DUE_TODAY',
  AGING_OVERDUE: 'AGING_OVERDUE',
};

export const AR_AGING_STATUSES = {
  DISBURSED: 'DISBURSED',
  SECOND_DISBURSEMENT_APPROVED: 'SECOND_DISBURSEMENT_APPROVED',
  SECOND_DISBURSEMENT_IN_PROCESS: 'SECOND_DISBURSEMENT_IN_PROCESS',
  SECOND_DISBURSEMENT_REQUESTED: 'SECOND_DISBURSEMENT_REQUESTED',
  SECOND_DISBURSEMENT_DISBURSED: 'SECOND_DISBURSEMENT_DISBURSED',
  SECOND_DISBURSEMENT_ON_HOLD: 'SECOND_DISBURSEMENT_ON_HOLD',
  SECOND_DISBURSEMENT_REJECTED: 'SECOND_DISBURSEMENT_REJECTED',
  THIRD_DISBURSEMENT_DISBURSED: 'THIRD_DISBURSEMENT_DISBURSED',
  THIRD_DISBURSEMENT_IN_PROCESS: 'THIRD_DISBURSEMENT_IN_PROCESS',
};

export const AR_AGING_STATUS_COLORS = {
  DISBURSED: 'success',
  SECOND_DISBURSEMENT_APPROVED: 'primary',
  SECOND_DISBURSEMENT_IN_PROCESS: 'warning',
  SECOND_DISBURSEMENT_REQUESTED: 'warning',
  SECOND_DISBURSEMENT_DISBURSED: 'success',
  SECOND_DISBURSEMENT_ON_HOLD: 'warning',
  SECOND_DISBURSEMENT_REJECTED: 'warning',
  THIRD_DISBURSEMENT_IN_PROCESS: 'warning',
  THIRD_DISBURSEMENT_DISBURSED: 'success',
};

export const APP_ENV_MAPPING = {
  DEV: 'dev',
  INTEGRATION: 'integration',
  STG: 'stg',
};
export const COMPANY_TYPES = {
  PT: 'PT',
  UD: 'UD',
  CV: 'CV',
  FA: 'FA'
};

export const DO_REJECTION_REASONS = {
  VEHICLE_NOT_READY: 'VEHICLE_NOT_READY',
  VEHICLE_MAINTENANCE_NEEDED: 'VEHICLE_MAINTENANCE_NEEDED',
  DRIVER_NOT_READY: 'DRIVER_NOT_READY',
  OTHER_REASON: 'OTHER_REASON'
};

export const DO_CANCELLATION_REASONS = {
  TRUCK_NO_SHOW: 'TRUCK_NO_SHOW',
  COMPLIANCE_ISSUE: 'COMPLIANCE_ISSUE',
  FULL_STOCK: 'FULL_STOCK',
  NO_EMPTY_JUGS: 'NO_EMPTY_JUGS',
  NO_PALLET_JUGRACK: 'NO_PALLET_JUGRACK',
  NO_STOCK: 'NO_STOCK',
  LOADING_DELAY: 'LOADING_DELAY',
  TKBM_ISSUE: 'TKBM_ISSUE',
  FORCE_MAJEURE: 'FORCE_MAJEURE',
  ALLOCATION_REVISION: 'ALLOCATION_REVISION',
  OTHER_REASON: 'OTHER_REASON'
};

export const COMPANY_ADDRESS_TYPES = {
  BILLING: 'BILLING',
  COMPANY: 'COMPANY'
};

export const SHIPPER_COMPANY_DOCUMENT_TYPES = {
  TDP: 'TDP',
  KTP: 'KTP',
  'SIUP/SIUJPT': 'SIUP/SIUJPT',
  NPWP: 'NPWP',
  PKP: 'PKP'
};

export const COMPANY_PARTNER_TYPE = {
  PARTNER: 'PARTNER',
  COMPANY: 'COMPANY'
};

export const SHOW_CLAIMS_IN = {
  POD: 'POD',
  BERITA_ACARA: 'BERITA_ACARA',
  LOAD_ITEMS: 'LOAD_ITEMS'
};

export const IS_RETURN_GOODS = {
  NO_RETURN: 'NO_RETURN',
  RETURN_GOODS: 'RETURN_GOODS'
};

export const GOODS_REJECTION_CATEGORY = {
  CLAIM: 'CLAIM',
  NO_CLAIM: 'NO_CLAIM',
  REPACKING: 'REPACKING'
};

export const IS_RETURN_FEE_COVERED = {
  NEGOTIABLE: 'NEGOTIABLE',
  NOT_COVERED: 'NOT_COVERED'
};

export const OTP_OPTIONS = {
  REGISTRATION: 'REGISTRATION',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FIRST_TIME_LOGIN: 'FIRST_TIME_LOGIN'
};

export const PLATE_COLORS = {
  BLACK_PLATE: 'BLACK_PLATE',
  YELLOW_PLATE: 'YELLOW_PLATE'
};

export const LOCATION_TYPE = {
  NORMAL: 'NORMAL',
  HOLDING: 'HOLDING',
  PORT: 'PORT',
  RETURN_DEPO: 'RETURN_DEPO',
  STORE: 'STORE'
};

export const ROUTE_LOCATION_GROUP = {
  PORT_STORE_RETURN_DEPO: [
    LOCATION_TYPE.PORT,
    LOCATION_TYPE.STORE,
    LOCATION_TYPE.RETURN_DEPO
  ],
  PORT_HOLDING: [LOCATION_TYPE.PORT, LOCATION_TYPE.HOLDING],
  HOLDING_STORE_RETURN_DEPO: [
    LOCATION_TYPE.HOLDING,
    LOCATION_TYPE.STORE,
    LOCATION_TYPE.RETURN_DEPO
  ]
};

export const PAYMENT_TYPES = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  PPN: 'PPN',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  TICKET_FEE: 'TICKET_FEE',
  OTHER_FEE: ''
};

export const ADDITIONAL_PAYMENT_TYPES = [
  PAYMENT_TYPES.LOADING_FEE,
  PAYMENT_TYPES.UNLOADING_FEE,
  PAYMENT_TYPES.OVERNIGHT_FEE,
  PAYMENT_TYPES.TICKET_FEE,
  PAYMENT_TYPES.OTHER_FEE
];

export const SERVICES_URL = {
  PANTHERA: window.PANTHERA_SERVICE_URL,
  LOAD: window.LOAD_SERVICE_URL,
  EVENT: window.EVENT_SERVICE_URL,
  PROFILE: window.PROFILE_SERVICE_URL,
  MASTER_SERVICE: window.MASTER_SERVICE_URL,
  AUTH: window.AUTH_SERVICE_URL,
  PROFILE_PUBLIC: window.PROFILE_SERVICE_URL_PUBLIC,
  PAYMENT: window.PAYMENT_SERVICE_URL,
  NEW_APOLLO_BACKEND: window.APOLLO_BACKEND_API_URL,
};

export const EVENT_SERVICE_DEVICE_TYPE = 'transporter_central';

export const VEHICLE_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED'
};

export const VEHICLE_STATUS_COLORS = {
  [VEHICLE_STATUSES.DRAFT]: 'darkGrey',
  [VEHICLE_STATUSES.PENDING]: 'warning',
  [VEHICLE_STATUSES.ACTIVE]: 'success',
  [VEHICLE_STATUSES.DEACTIVATED]: 'danger',
  [VEHICLE_STATUSES.REGISTERED]: 'success',
  [VEHICLE_STATUSES.EXPIRED]: 'danger',
  [VEHICLE_STATUSES.REJECTED]: 'danger'
};

export const DRIVER_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
  SUSPENDED: 'SUSPENDED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CREATED: 'CREATED',
  DOCUMENT_EXPIRED: 'DOCUMENT_EXPIRED'
};

export const SHIPPER_EMPLOYEE_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
  SUSPENDED: 'SUSPENDED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CREATED: 'CREATED',
  DOCUMENT_EXPIRED: 'DOCUMENT_EXPIRED'
};

export const DRIVER_STATUS_COLORS = {
  [DRIVER_STATUSES.SUSPENDED]: 'danger',
  [DRIVER_STATUSES.DEACTIVATED]: 'danger',
  [DRIVER_STATUSES.ACTIVE]: 'success',
  [DRIVER_STATUSES.REGISTERED]: 'success',
  [DRIVER_STATUSES.INACTIVE]: 'danger',
  [DRIVER_STATUSES.CREATED]: 'darkGrey',
  [DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'danger'
};

export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  REGISTERED: 'REGISTERED',
  INACTIVE: 'INACTIVE'
};

export const USER_STATUS_COLORS = {
  [USER_STATUSES.ACTIVE]: 'success',
  [USER_STATUSES.REGISTERED]: 'warning',
  [USER_STATUSES.INACTIVE]: 'danger'
};

export const NON_EDITABLE_USER_STATUSES = [USER_STATUSES.BLOCKED];

export const DOCUMENT_TYPES = {
  POD: 'POD',
  POL: 'POL',
  CLAIMS_AND_RETURNS: 'CAR',
  CLAIM_AND_RETURN_DOCUMENT: 'CLAIM_AND_RETURN_DOCUMENT',
  CLAIM_AND_RETURN_GOODS: 'CLAIM_AND_RETURN_GOODS',
  DRIVER_REJECTED_GOODS: 'REJECTED_GOODS',
  DRIVER_REJECTION_LETTER: 'REJECTION_LETTER',
};

export const SHIPMENT_DOCUMENT_TYPES = {
  INTERNAL_POD: 'internal-pod',
  INTERNAL_POL: 'internal-pol',
  CLAIMS_AND_RETURNS: 'claims-returns'
};

export const DOCUMENT_VERIFICATION_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  NEGLECTED: 'NEGLECTED',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  REJECTED: 'REJECTED',
};

export const LOCALE_TYPE = {
  id: 'id',
  en: 'en'
};

export const CUSTOMER_DIVISION = {
  OPERATIONS: 'OPERATIONS',
  WAREHOUSE: 'WAREHOUSE',
  SALES: 'SALES',
  FINANCE: 'FINANCE',
  PROCUREMENT: 'PROCUREMENT',
  LOGISTICS: 'LOGISTICS',
  TREASURY: 'TREASURY',
  CLAIMS: 'CLAIMS'
};

export const PANTHERA_PIC_DIVISION = {
  SALES: 'SALES',
  SALES_SUPPORT: 'SALES_SUPPORT',
  ACCOUNT_RECEIVABLE: 'ACCOUNT_RECEIVABLE',
  CANVASSER: 'CANVASSER'
};

export const LOCAL_SHIPMENT_STATUSES = {
  UPCOMING: 'upcoming',
  PLANNED: 'planned',
  AT_WAREHOUSE: 'at-warehouse',
  IN_TRANSIT: 'in-transit',
  AT_LOCATION: 'at-location',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
};

export const WEIGHT_UNITS = {
  TON: 'TON',
  CBM: 'CBM'
};

export const PPH23_DEDUCTOR = {
  PARTNER: 'PARTNER',
  SELF: 'SELF'
};

export const LAST_DISBURSEMENT_PPH23_DEDUCTOR = {
  SHIPPER: 'SHIPPER',
  TRANSPORTER: 'TRANSPORTER'
};

export const ROUTE_TYPES = {
  FACTORY_TO_DEPO: 'FACTORY_TO_DEPO',
  MULTIDROP_MULTIPICKUP: 'MULTIDROP_MULTIPICKUP',
  SAME_DAY: 'SAME_DAY',
};

export const SHIPMENT_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  ALLOCATE: 'ALLOCATE',
  REALLOCATE: 'REALLOCATE',
  UPDATE_DO_NUMBER: 'UPDATE_DO_NUMBER',
  CHOOSE_WINNER: 'CHOOSE_WINNER',
  ASSIGN_SHIPMENT: 'ASSIGN_SHIPMENT',
  CREATE_REMINDER: 'CREATE_REMINDER',
  REOPEN_JOB: 'REOPEN_JOB',
  CANCEL_SHIPMENT: 'CANCEL_SHIPMENT',
  UNFULFILLMENT_SHIPMENT: 'UNFULFILLMENT_SHIPMENT',
  CANCEL_MARKETPLACE_JOB: 'CANCEL_MARKETPLACE_JOB',
  APPROVE_REASSIGNMENT: 'APPROVE_REASSIGNMENT',
  SET_BID_BASE_PRICE: 'SET_BID_BASE_PRICE',
  SET_BID_WINNER: 'SET_BID_WINNER',
};

export const SHIPPER_FORM_TYPES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
};

export const SHIPMENT_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  ALLOCATION_FORM: 'ALLOCATION_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY'
};

export const SHIPMENT_POINT_TYPES = {
  DROPOFF: 'DROPOFF',
  PICKUP: 'PICKUP',
  PICKUP_DROPOFF: 'PICKUP_DROPOFF',
};

export const SHIPMENT_STATUSES = {
  UPCOMING: 'UPCOMING',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ON_SHIPMENT: 'ON_SHIPMENT',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED: 'CANCELLED',
  UNFULFILLED: 'UNFULFILLED',
  REASSIGNED_BY_SHIPPER: 'REASSIGNED_BY_SHIPPER',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
};

export const SHIPMENT_ACTIVITY_TYPES = {
  ASSIGNED_ONGOING_TO_PICKUP: 'ASSIGNED_ONGOING_TO_PICKUP',
  ONGOING_TO_PICKUP_ARRIVE_AT_PICKUP: 'ONGOING_TO_PICKUP_ARRIVE_AT_PICKUP',
  ARRIVE_AT_PICKUP_START_LOADING: 'ARRIVE_AT_PICKUP_START_LOADING',
  START_LOADING_FINISH_LOADING: 'START_LOADING_FINISH_LOADING',
  FINISH_LOADING_ON_SHIPMENT: 'FINISH_LOADING_ON_SHIPMENT',
  ON_SHIPMENT_ARRIVE_AT_DESTINATION: 'ON_SHIPMENT_ARRIVE_AT_DESTINATION',
  ARRIVE_AT_DESTINATION_START_UNLOADING: 'ARRIVE_AT_DESTINATION_START_UNLOADING'
};

export const DERIVED_SHIPMENT_STATUSES = {
  MAIN_SHIPMENT: 'MAIN_SHIPMENT',
  BEHIND_THE_SCHEDULE: 'BEHIND_THE_SCHEDULE',
  ON_TIME: 'ON_TIME',
  QUEUEING_AT_ORIGIN: 'QUEUEING_AT_ORIGIN',
  QUEUEING_AT_DESTINATION: 'QUEUEING_AT_DESTINATION',
  QUEUEING_OVERTIME: 'QUEUEING_OVERTIME',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
};

export const JOB_STATUSES = {
  OPEN: 'OPEN',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  LATE_TO_ORIGIN: 'LATE_TO_ORIGIN',
  LATE_TO_DESTINATION: 'LATE_TO_DESTINATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  TRANSPORTER_CONFIRMED: 'TRANSPORTER_CONFIRMED',
  BIDDING: 'BIDDING',
  CANCELLED: 'CANCELLED',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED'
};

export const SHIPMENT_STATUS_SEQUENCE = [
  SHIPMENT_STATUSES.UPCOMING,
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE
];

export const SHIPPER_STATUSES = {
  CHURN: 'CHURN',
  PENDING_ONBOARDING: 'PENDING_ONBOARDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'SHIPPER_STATUSES',
};

export const PRODUCT_CATEGORY = {
  ALCOHOLIC_BEVARAGES: 'ALCOHOLIC_BEVARAGES',
  DRINKING_WATER: 'DRINKING_WATER',
  COFFEE: 'COFFEE',
  FRUIT_FLAVORED_DRINKS: 'FRUIT_FLAVORED_DRINKS',
  JUICE: 'JUICE',
  MILK: 'MILK',
  NON_DAIRY: 'NON_DAIRY',
  POWDERED_BEVERAGES_MIXES: 'POWDERED_BEVERAGES_MIXES',
  SODA: 'SODA',
  SPORTS_ENERGY_DRINKS: 'SPORTS_ENERGY_DRINKS',
  TEA_INFUSIONS: 'TEA_INFUSIONS',
  WATER: 'WATER',
  BAKERY: 'BAKERY',
  SNAKES: 'SNAKES',
  CONDIMENTS_SOUCES: 'CONDIMENTS_SOUCES',
  COOKING_BAKING_INGREDIENTS: 'COOKING_BAKING_INGREDIENTS',
  DAIRY: 'DAIRY',
  DIPS: 'DIPS',
  FOOD: 'FOOD',
  FROZEN: 'FROZEN',
  FRUITS: 'FRUITS',
  GRAINS: 'GRAINS',
  MEAT: 'MEAT',
  NUTS: 'NUTS',
  PASTA: 'PASTA',
  PREPARED: 'PREPARED',
  SPICES: 'SPICES',
  SNACKS: 'SNACKS',
  SOUPS: 'SOUPS',
  TOUFU: 'TOUFU',
  FITNESS_AND_NUTRITION: 'FITNESS_AND_NUTRITION',
  MEDICINE_AND_DRUGS: 'MEDICINE_AND_DRUGS',
  FMCG: 'FMCG',
  PLASTIC: 'PLASTIC',
  PACKAGING: 'PACKAGING'
};

export const SHIPPER_TYPES = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3'
};

export const INDUSTRY = {
  FMCG: 'FMCG',
  RAW_MATERIAL_OR_ORM: 'RAW_MATERIAL_OR_ORM',
  LOGISTIC_COMPANY: 'LOGISTIC_COMPANY',
  BROKER_TRANSPORTER: 'BROKER_TRANSPORTER',
  E_COMMERCE: 'E_COMMERCE',
  COMMODITY_AND_TRADING: 'COMMODITY_AND_TRADING'
};

export const SHIPPER_STATUS_COLORS = {
  [SHIPPER_STATUSES.CHURN]: 'danger',
  [SHIPPER_STATUSES.INACTIVE]: 'warning',
  [SHIPPER_STATUSES.ACTIVE]: 'success',
  [SHIPPER_STATUSES.PENDING_ONBOARDING]: 'grey',
  [SHIPPER_STATUSES.SUSPENDED]: 'danger',
  [SHIPPER_STATUSES.REJECTED]: 'danger'
};

export const SHIPPER_LOCATION_OPERATING_DAY = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
  WEEKDAY: 'WEEKDAY',
  WEEKEND: 'WEEKEND',
  HOLIDAY: 'HOLIDAY'
};

export const SHIPMENT_STATUS_COLORS = {
  [SHIPMENT_STATUSES.UPCOMING]: 'danger',
  [SHIPMENT_STATUSES.PLANNED]: 'danger',
  [SHIPMENT_STATUSES.ASSIGNED]: 'darkGrey',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.START_LOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_LOADING]: 'success',
  [SHIPMENT_STATUSES.ON_SHIPMENT]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'primary',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'success',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'grey',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED]: 'danger',
  [SHIPMENT_STATUSES.UNFULFILLED]: 'danger',
  [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'primary',
};

export const MORE_FILTER_KEYS = {
  ORIGIN_LOCATION_FIELD: 'originWarehouseKsuid',
  DESTINATION_LOCATION_FIELD: 'destinationWarehouseKsuid',
  LOADING_DATE_FIELD: 'loadingDate',
  UNLOADING_DATE_FIELD: 'unloadingDate',
  ARRIVE_AT_ORIGIN_DATE_FIELD: 'arriveAtOriginDate',
  ARRIVE_AT_DESTINATION_DATE_FIELD: 'arriveAtDestinationDate',
  TAGS_FIELD: 'tags',
  SUPPORT_FIELD: 'createdByKsuid',
  EXTERNAL_ID: 'externalId',
  GROUP_LOAD_NUMBER: 'groupLoadNumber',
  TRANSPORTER_COMPANY: 'transporterCompanyKsuids',
  DRIVER: 'driverKsuids',
};

export const SHIPMENT_STATUS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.UPCOMING]: [SHIPMENT_STATUSES.UPCOMING],
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [SHIPMENT_STATUSES.PLANNED, SHIPMENT_STATUSES.ASSIGNED],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.HISTORY]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
    SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ON_SHIPMENT,
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ON_SHIPMENT,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  ],
};

export const SHIPMENT_COUNT_QUERY_NAME = {
  [SHIPMENT_STATUSES.UPCOMING]: 'upcomingCount',
  [SHIPMENT_STATUSES.PLANNED]: 'plannedCount',
  [SHIPMENT_STATUSES.ASSIGNED]: 'assignedCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'arriveAtPickupCount',
  [SHIPMENT_STATUSES.START_LOADING]: 'startLoadingCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'arriveAtDestinationCount',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'startUnloadingCount',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'finishUnloadingCount',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'shipmentCompleteCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'cancelledByTransporterCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'cancelledByShipperCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'cancelledByInternalCount',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'queueingOvertimeCount',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'behindTheScheduleCount',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'onTimeCount',
};

export const SHIPMENT_STATUS_COUNTS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.PLANNED,
    SHIPMENT_STATUSES.ASSIGNED,
  ].filter(Boolean),
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME,
    SHIPMENT_STATUSES.START_LOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE,
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE,
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [
    DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE,
    DERIVED_SHIPMENT_STATUSES.ON_TIME,
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.LATE_TO_ORIGIN,
    SHIPMENT_STATUSES.LATE_TO_DESTINATION,
  ].filter(Boolean),
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  ].filter(Boolean),
};

export const READINESS_SHIPMENT_STATUS = {
  READY: 'READY',
  ABOUT_TO_BE_READY: 'ABOUT_TO_BE_READY'
};

export const READINESS_SHIPMENT_STATUS_COLORS = {
  [READINESS_SHIPMENT_STATUS.READY]: 'success',
  [READINESS_SHIPMENT_STATUS.ABOUT_TO_BE_READY]: 'orangeyYellow'
};

export const ARMADA_SHIPMENT_STATUSES = [
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE
];

export const GENERAL_ENTITY_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED'
};

export const COMPANY_STATUSES = {
  ACTIVE: 'ACTIVE',
  CHURN: 'CHURN',
  DEACTIVATED: 'DEACTIVATED',
  FREE_TRIAL: 'FREE_TRIAL',
  INACTIVE: 'INACTIVE',
  REGISTERED: 'REGISTERED',
};

export const COMPANY_STATUS_COLORS = {
  [COMPANY_STATUSES.ACTIVE]: 'success',
  [COMPANY_STATUSES.CHURN]: 'danger',
  [COMPANY_STATUSES.DEACTIVATED]: 'danger',
  [COMPANY_STATUSES.FREE_TRIAL]: 'warning',
  [COMPANY_STATUSES.INACTIVE]: 'danger',
  [COMPANY_STATUSES.REGISTERED]: 'orangeyYellow',
};

export const GENERAL_ENTITY_STATUS_COLORS = {
  REGISTERED: 'success',
  DEACTIVATED: 'danger'
};

export const BROKERAGE_EXTRA_USER_ACCESS_TYPE = {
  ACCOUNT_PAYABLE: 'ACCOUNT_PAYABLE',
  AR: 'AR',
  DOCUMENT_COLLECTION: 'DOCUMENT_COLLECTION',
  FINANCE: 'FINANCE',
  FINANCE_ADMIN: 'FINANCE_ADMIN',
  SALES: 'SALES',
  SALES_SUPPORT: 'SALES_SUPPORT',
  TRAFFIC_CONTROLLER: 'TRAFFIC_CONTROLLER',
  TRANSPORT_PLANNER: 'TRANSPORT_PLANNER',
  VENDOR_MANAGEMENT: 'VENDOR_MANAGEMENT',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES',
};

export const PLATFORM_ACCESS_TYPE = {
  LOGISTIC_PIC: 'LOGISTIC_PIC',
  MONITORING_PIC: 'MONITORING_PIC',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES',
  SHIPPER_PLATFORM: 'SHIPPER_PLATFORM'
};

/**
 * ADMIN access for the whole TMS
 * FULL_ACCESS & RESTRICTED_ACCESS are registered for TTMS
 * SALES, SALES_SUPPORT, AR, FINANCE, VENDOR_MANAGEMENT, ACCOUNT_PAYABLE are for BTMS
 */

export const USER_ACCESS_TYPE = {
  ADMIN: 'ADMIN',
  FULL_ACCESS: 'FULL_ACCESS',
  RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
};

export const FILE_UPLOAD_SOURCE = {
  DA: 'DA',
  TMS: 'TMS',
  NFT: 'NFT',
};

export const FILE_PREVIEW_TYPE = {
  IMAGE: 'IMAGE',
  GDRIVE_FOLDER: 'GDRIVE_FOLDER',
  GDRIVE_FILE: 'GDRIVE_FILE',
  PDF: 'PDF'
};

export const MIME_TO_FILE_PREVIEW_TYPE_MAP = {
  'image/jpeg': FILE_PREVIEW_TYPE.IMAGE,
  'image/png': FILE_PREVIEW_TYPE.IMAGE,
  'application/pdf': FILE_PREVIEW_TYPE.PDF
};

export const INVOICE_STATUS = {
  NOT_INVOICED_YET: null,
  PARTIALLY_INVOICED: 'PARTIALLY_INVOICED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAID: 'PAID',
  INVOICE_GENERATED: 'INVOICE_GENERATED'
};

export const BROKERAGE_INVOICE_STATUS = {
  NOT_INVOICED: 'NOT_INVOICED',
  INVOICED: 'INVOICED'
};

export const INVOICE_STATUS_SEQUENCE = [
  INVOICE_STATUS.NOT_INVOICED_YET,
  INVOICE_STATUS.PAYMENT_PENDING,
  INVOICE_STATUS.PAID
];

export const INVOICE_STATUS_COLOR = {
  [INVOICE_STATUS.NOT_INVOICED_YET]: 'darkGrey',
  [INVOICE_STATUS.PAYMENT_PENDING]: 'orangeyYellow',
  [INVOICE_STATUS.PAID]: 'success'
};

export const DISPLAY_MODE = {
  READ: 'READ',
  WRITE: 'WRITE',
  PRINT: 'PRINT'
};

export const DOF_SHIPMENT_STATUSES = {
  POL_PENDING: 'POL_PENDING',
  POL_ACCEPTED: 'POL_ACCEPTED',
  POD_ACCEPTED: 'POD_ACCEPTED',
  POD_REJECTED: 'POD_REJECTED',
  POL_REJECTED: 'POL_REJECTED'
};

export const DOF_SHIPMENT_STATUS_COLORS = {
  [DOF_SHIPMENT_STATUSES.POL_PENDING]: 'darkGrey',
  [DOF_SHIPMENT_STATUSES.POL_ACCEPTED]: 'primary',
  [DOF_SHIPMENT_STATUSES.POD_ACCEPTED]: 'primary',
  [DOF_SHIPMENT_STATUSES.POD_REJECTED]: 'warning',
  [DOF_SHIPMENT_STATUSES.POL_REJECTED]: 'warning'
};

export const SWIFT_PAYMENT_REQUEST_STATUSES = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DISBURSED: 'DISBURSED',
  ON_HOLD: 'ON_HOLD',
  DOF2_PROCESSED_PARTIALLY: 'DOF2_PROCESSED_PARTIALLY',
  SECOND_DISBURSEMENT_IN_PROCESS: 'SECOND_DISBURSEMENT_IN_PROCESS',
  SECOND_DISBURSEMENT_DISBURSED: 'SECOND_DISBURSEMENT_DISBURSED',
  THIRD_DISBURSEMENT_IN_PROCESS: 'THIRD_DISBURSEMENT_IN_PROCESS',
  THIRD_DISBURSEMENT_DISBURSED: 'THIRD_DISBURSEMENT_DISBURSED'
};

export const SWIFT_PAYMENT_REQUEST_STATUS_COLORS = {
  [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'danger',
  [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'success',
  [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'success',
  [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'success'
};

export const SWIFT_PAYMENT_DOCUMENT_STATUSES = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

export const DOF2_REQUEST_ON_HOLD_REASON = {
  ALL_DOCUMENTS_REJECTED: 'ALL_DOCUMENTS_REJECTED',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  LATE_PAYMENT_ISSUE: 'LATE_PAYMENT_ISSUE',
  OTHER: 'OTHER'
};

export const SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE = {
  CLAIM: 'CLAIM',
  TRANSFER_FEE: 'TRANSFER_FEE',
  ADDITION_IN_DO_PRICE: 'SHIPMENT_FEE_ADDITION',
  REDUCE_IN_DO_PRICE: 'SHIPMENT_FEE_DEDUCTION',
  OTHERS: 'OTHERS'
};

export const DOF2_REQUEST_ON_HOLD_REASON_OPTIONS = [
  DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED,
  DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE,
  DOF2_REQUEST_ON_HOLD_REASON.OTHER
];

export const DOF_REQUEST_REJECTION_REASON = {
  POL_BLUR: 'POL_BLUR',
  POD_BLUR: 'POD_BLUR',
  POL_SHIPMENT_NUMBER_MISMATCH: 'POL_SHIPMENT_NUMBER_MISMATCH',
  POD_SHIPMENT_NUMBER_MISMATCH: 'POD_SHIPMENT_NUMBER_MISMATCH',
  OTHER: 'OTHER'
};

export const DOF_REQUEST_REJECTION_REASON_GROUP = {
  POL: [
    DOF_REQUEST_REJECTION_REASON.POL_BLUR,
    DOF_REQUEST_REJECTION_REASON.POL_SHIPMENT_NUMBER_MISMATCH,
    DOF_REQUEST_REJECTION_REASON.OTHER
  ],
  POD: [
    DOF_REQUEST_REJECTION_REASON.POD_BLUR,
    DOF_REQUEST_REJECTION_REASON.POD_SHIPMENT_NUMBER_MISMATCH,
    DOF_REQUEST_REJECTION_REASON.OTHER
  ]
};

export const PODF_INVOICE_REJECTION_REASON = {
  INVOICE_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER'
};

export const PODF_INVOICE_RECEIPT_REJECTION_REASON = {
  INVOICE_RECEIPT_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER'
};

export const DOF2_INVOICE_REJECTION_REASON = {
  INVOICE_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER'
};

export const DOF2_INVOICE_RECEIPT_REJECTION_REASON = {
  INVOICE_RECEIPT_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER'
};

export const SWIFT_PAYMENT_TYPES = {
  DOF: 'DOF',
  PODF: 'PODF',
  DOF2: 'DOF-2'
};

export const PODF_FIRST_DISBURSEMENT_PERCENTAGE = 80;
export const DOF_THIRD_DISBURSEMENT_PERCENTAGE = 20;
export const DOF2_ADDITIONAL_FEE = 80;

export const DOF2_ELIGIBILITY_STATUS = {
  ELIGIBLE: 0,
  SHIPMENT_NOT_COMPLETED: 1,
  DO_IS_ALREADY_IN_ANOTHER_DOF2_REQUEST: 2,
  DO_IS_ALREADY_IN_ANOTHER_INVOICE: 3,
  DOF_NOT_DISBURSED_YET: 4,
  UNDEFINED_BACKEND_CODE: 5,
  DOF_REQUEST_NOT_FOUND: 6
};

export const DOF_FIRST_DISBURSEMENT_PERCENTAGE = 50;
export const DOF_SECOND_DISBURSEMENT_PERCENTAGE = 30;
export const DOF_ADDITIONAL_FEES_SECOND_DISBURSEMENT_PERCENTAGE = 80;
export const DOF_THIRD_INVOICE_PPH_CLAIM_TRANSFER_FEE_ADJUSTMENT_FEE_PERCENTAGE = 20;

// [Start] Fist Mile Project Specific
export const FIRST_MILE_USER_ACCESS_TYPE = {
  FREIGHT_FORWARDER: 'FREIGHT_FORWARDER',
  CONSIGNEE: 'CONSIGNEE'
};

export const CONTAINER_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  BATCH_UPDATE: 'BATCH_UPDATE'
};

export const CONTAINER_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
  BATCH_UPDATE_FORM: 'BATCH_UPDATE_FORM'
};

export const CONTAINER_STATUSES = {
  ARRIVED_AT_PORT: 'ARRIVED_AT_PORT',
  PIB: 'PIB',
  NO_NOA: 'NO_NOA',
  NOA: 'NOA',
  SPPB: 'SPPB',
  WAITING_FOR_SPPB: 'WAITING_FOR_SPPB',
  WAITING_FOR_ORIGINAL_DOC: 'WAITING_FOR_ORIGINAL_DOC',
  QUARANTINED: 'QUARANTINED',
  ON_SHIPMENT: 'ON_SHIPMENT',
  MOVED_TO_HOLDING: 'MOVED_TO_HOLDING',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  INVOICED: 'INVOICED',
  PAID: 'PAID'
};

export const CONTAINER_STATUS_COLOR = {
  [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'primary',
  [CONTAINER_STATUSES.ON_SHIPMENT]: 'primary',
  [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [CONTAINER_STATUSES.PAYMENT_PENDING]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'warning',
  [CONTAINER_STATUSES.PAID]: 'success',
  [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'primary',
  [CONTAINER_STATUSES.PIB]: 'primary',
  [CONTAINER_STATUSES.NO_NOA]: 'warning',
  [CONTAINER_STATUSES.NOA]: 'primary',
  [CONTAINER_STATUSES.SPPB]: 'primary',
  [CONTAINER_STATUSES.QUARANTINED]: 'primary',
  [CONTAINER_STATUSES.INVOICED]: 'success',
};

export const FIRST_MILE_DOCUMENT_TYPES = {
  HOUSE_BILL_OF_LADING: 'HOUSE_BILL_OF_LADING',
  BILL_OF_LADING: 'BILL_OF_LADING',
  NOTICE_OF_ARRIVAL: 'NOTICE_OF_ARRIVAL',
  PIB: 'PIB',
  QUARANTINE: 'QUARANTINE',
  EBILLING: 'EBILLING',
  SHIPPING_LINE_DELIVERY_ORDER: 'SHIPPING_LINE_DELIVERY_ORDER',
  STORAGE_CHARGES: 'STORAGE_CHARGES',
  TILA: 'TILA',
  SPPB: 'SPPB',
  CONTAINER_RENTAL: 'CONTAINER_RENTAL',
  PACKING_LIST: 'PACKING_LIST',
  MATERIAL_SAFETY_AND_DATA_SHEET: 'MATERIAL_SAFETY_AND_DATA_SHEET',
  CERTIFICATE_OF_ORIGIN: 'CERTIFICATE_OF_ORIGIN',
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  LOLO: 'LOLO',
  TAX_INVOICE: 'TAX_INVOICE',
  BILL_OF_LADING_INVOICE: 'BILL_OF_LADING_INVOICE'
};

export const FIRST_MILE_CONTAINER_SIZES = {
  '20_FEET': '20_FEET',
  '20_FR': '20_FR',
  '20_GP': '20_GP',
  '20_OT': '20_OT',
  '20_OW': '20_OW',
  '40_FEET': '40_FEET',
  '40_FR': '40_FR',
  '40_GP': '40_GP',
  '40_HC': '40_HC',
  '40_OW': '40_OW',
  '40_OT': '40_OT',
  '45_FEET': '45_FEET'
};

export const FIRST_MILE_COMPANY_CONSTANT_TYPES = {
  PORT: 'PORT',
  SUPPLIER: 'SUPPLIER',
  SHIPPING_LINE: 'SHIPPING_LINE',
  CONSIGNEE: 'CONSIGNEE',
  NOTIFY_PARTY: 'NOTIFY_PARTY',
  TERMINAL: 'TERMINAL'
};

export const DISBURSEMENT_SCHEME_TYPE = {
  BROKERAGE: 'BROKERAGE',
  MARKETPLACE: 'MARKETPLACE',
  FINANCING: 'FINANCING'
};

export const DISBURSEMENT_SCHEME_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const DISBURSEMENT_SCHEME_STATUS_COLOR = {
  [DISBURSEMENT_SCHEME_STATUS.ACTIVE]: 'success',
  [DISBURSEMENT_SCHEME_STATUS.INACTIVE]: 'danger'
};

// [End] Fist Mile Project Specific

export const EMPLOYEE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY'
};

export const APP_CONTEXT_STORAGE_KEY = 'appContext';

// LOCATION FEE Constants
export const LOCATION_FEE_TYPE = {
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
  OTHER: 'OTHER'
};

export const LOCATION_FEE_TYPE_ORDERING = {
  LOADING: 1,
  UNLOADING: 0,
  OTHER: 2
};

export const SHIPMENT_TYPE = {
  MT: 'MT',
  GT: 'GT',
  W2W: 'W2W',
  F2DEPO: 'F2DEPO',
  F2DIST: 'F2DIST',
  F2MT: 'F2MT',
  F2W: 'F2W',
  W2DEPO: 'W2DEPO',
  W2DIST: 'W2DIST',
  W2MT: 'W2MT'
};

export const PRICING_RATE_TYPES = {
  PER_CBM: 'PER_CBM',
  PER_KG: 'PER_KG',
  PER_LANE: 'PER_LANE',
  PER_ROUTE: 'PER_ROUTE',
  PER_UNIT: 'PER_UNIT'
};

export const PRICING_RATE_TYPES_MAPPER = {
  [PRICING_RATE_TYPES.PER_CBM]: 'Per CBM',
  [PRICING_RATE_TYPES.PER_KG]: 'Per Kg',
  [PRICING_RATE_TYPES.PER_LANE]: 'Per Lane',
  [PRICING_RATE_TYPES.PER_ROUTE]: 'Per Route',
  [PRICING_RATE_TYPES.PER_UNIT]: 'Per Unit'
};

// need to update the text and value below from the constant above
export const PRICING_RATE_TYPE = [
  { label: 'Per Route', value: 'PER_ROUTE' },
  { label: 'Per Lane', value: 'PER_LANE' },
  { label: 'Per Unit', value: 'PER_UNIT' },
  { label: 'Per Kg', value: 'PER_KG' },
  { label: 'Per CBM', value: 'PER_CBM' }
];

export const MULTIPLE_SHIPMENT_TYPE = {
  MULTIDROP: 'MULTIDROP',
  MULTIPICKUP: 'MULTIPICKUP',
  MULTIDROP_MULTIPICKUP: 'MULTIDROP_MULTIPICKUP'
};

export const REIMBURSEMENT_REQUIREMENT_TYPE = {
  REIMBURSABLE_WITH_RECEIPT_AND_STAMP: 'REIMBURSABLE_WITH_RECEIPT_AND_STAMP',
  REIMBURSABLE_WITH_RECEIPT: 'REIMBURSABLE_WITH_RECEIPT',
  REIMBURSABLE: 'REIMBURSABLE',
  NOT_REIMBURSABLE: 'NOT_REIMBURSABLE',
  OTHER: 'OTHER'
};

export const REIMBURSEMENT_TYPE = {
  ROUTE: 'ROUTE',
  UNIT: 'UNIT',
  KG: 'KG',
  OTHER: 'OTHER'
};

const CAPACITY_UNIT = {
  KG: 'KG',
  CBM: 'CBM',
  UNIT: 'UNIT',
  TON: 'TON'
};

const CAPACITY_UNIT_MAPPER = {
  [CAPACITY_UNIT.KG]: 'Kg',
  [CAPACITY_UNIT.CBM]: 'CBM',
  [CAPACITY_UNIT.UNIT]: 'Unit'
};

export const CAPACITY_UNIT_OPTIONS = [
  { label: CAPACITY_UNIT_MAPPER[CAPACITY_UNIT.KG], value: CAPACITY_UNIT.KG },
  { label: CAPACITY_UNIT_MAPPER[CAPACITY_UNIT.CBM], value: CAPACITY_UNIT.CBM },
  {
    label: CAPACITY_UNIT_MAPPER[CAPACITY_UNIT.UNIT],
    value: CAPACITY_UNIT.UNIT
  }
];

export const CUSTOM_CITY_REGION_MAPPING_METADATA = 'custom_city_region_mapping';
export const CUSTOM_COMPANY_ROLES_METADATA = 'company_roles';

export const DANONE_USER_ACCESS_TYPE = {
  DANONE_LOGISTIC: 'DANONE_LOGISTIC',
  DANONE_PROCUREMENT: 'DANONE_PROCUREMENT',
  WAREHOUSE: 'WAREHOUSE',
};

export const TON_TO_KG_MULTIPLIER = 1000;

export const SHIPMENT_POINT_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE'
};

export const POD_STATUS = {
  ARRIVING_AT_PITSTOP: 'ARRIVING_AT_PITSTOP',
  RECEIVED: 'RECEIVED',
  DELIVERING_TO_AR: 'DELIVERING_TO_AR',
  DELIVERING_TO_SHIPPER: 'DELIVERING_TO_SHIPPER',
  DELIVERED_TO_AR: 'DELIVERED_TO_AR',
  DELIVERED_TO_SHIPPER: 'DELIVERED_TO_SHIPPER'
};

export const paymentSchemeLabel = {
  '100% di Muka': 'Full_Payment, 100% Upfront (Manual)',
  'DP Rp 200,000 (pelunasan di muat)': 'Split_Payment (200k upfront via Manual)',
  '100% di Bongkar': 'Destination_Payment, 100% After Unloading (Manual)',
  '100% di Muat': 'Origin_Payment, 100% After Loading (Manual)',
  'DP Rp 200,000 (pelunasan di bongkar)': 'Split_Destination (200k upfront via Manual)',
  '50% di Muat - 50% di Bongkar': '50:50_Payment (Origin - Destination)',
  invoice: 'Pembayaran Invoice'
};

export const USER_SALES_ROLE = {
  SME_TELESALES: 'SME_TELESALES',
  SME_SALES_EXECUTIVE: 'SME_SALES_EXECUTIVE',
  SME_TEAM_LEADER: 'SME_TEAM_LEADER'
};

const USER_ACCESS_ROLE = {
  FULL_ACCESS: 'FULL_ACCESS',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES'
};

export const USER_ACCESS_ROLE_OPTIONS = [
  { label: 'All roles', value: [USER_ACCESS_ROLE.FULL_ACCESS, USER_ACCESS_ROLE.KARGO_SHIPPER_SALES] },
  { label: 'Admin Access', value: USER_ACCESS_ROLE.FULL_ACCESS },
  { label: 'Kargo Team', value: USER_ACCESS_ROLE.KARGO_SHIPPER_SALES }
];

export const USER_STATUS_OPTIONS = [
  { label: 'Active', value: USER_STATUSES.ACTIVE },
  { label: 'Inactive', value: USER_STATUSES.INACTIVE },
  { label: 'Created', value: USER_STATUSES.REGISTERED },
];

// CUSTOMIZABLE INVOICE MODULE (TTMS)
export const CUSTOMIZABLE_INVOICE_STATUSES = {
  NOT_INVOICED: 'NOT_INVOICED',
  PARTIALLY_INVOICED: 'PARTIALLY_INVOICED'
};

export const INVOICE_PAYMENT_STATUS = {
  APPROVED: 'APPROVED',
  PAID: 'PAID',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  REJECTED: 'REJECTED',
};

export const CUSTOM_INVOICE_FEE_TYPES = {
  TRUCKING_FEE: 'TRUCKING_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  TICKET_FEE: 'TICKET_FEE',
};

export const CUSTOM_INVOICE_FEE_STATUSES = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAID: 'PAID',
  NOT_INVOICED: '',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const CUSTOM_INVOICE_FEE_UNITS = {
  PER_CBM: 'PER_CBM',
  PER_ROUTE: 'PER_ROUTE',
  PER_KG: 'PER_KG',
};

// M/M Shipments
export const MULTI_PICKUP_POINT_STATUS = {
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
};

export const MULTI_DROPOFF_POINT_STATUS = {
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
};

export const MULTI_SHIPMENT_POINT_OTHER_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
};

export const NFT_MODULES = {
  ANALYTICS: 'ANALYTICS',
  'ANALYTICS_DO-ACCEPTANCE': 'ANALYTICS_DO-ACCEPTANCE',
  'ANALYTICS_SHIPMENT-TIME-ACCURACY': 'ANALYTICS_SHIPMENT-TIME-ACCURACY',
  'ANALYTICS_TRAVELLED-DISTANCE': 'ANALYTICS_TRAVELLED-DISTANCE',
  ANALYTICS_UTILIZATION: 'ANALYTICS_UTILIZATION',
  'ANALYTICS_WAREHOUSE-PERFORMANCE': 'ANALYTICS_WAREHOUSE-PERFORMANCE',
  'COMPANY-SETTING_CUSTOMER-WAREHOUSE-CREATION': 'COMPANY-SETTING_CUSTOMER-WAREHOUSE-CREATION',
  'FINANCE_EARNINGS-REPORT': 'FINANCE_EARNINGS-REPORT',
  FINANCE_INVOICING: 'FINANCE_INVOICING',
  'FINANCE_SHIPMENT-INCOME': 'FINANCE_SHIPMENT-INCOME',
  FLEET_AVAILABILITY: 'FLEET_AVAILABILITY',
  FLEET_MONITORING: 'FLEET_MONITORING',
  'SHIPMENT_BODY-CODE': 'SHIPMENT_BODY-CODE',
  'SHIPMENT_BTMS-INTEGRATION': 'SHIPMENT_BTMS-INTEGRATION',
  'SHIPMENT_CONTAINER-INFO': 'SHIPMENT_CONTAINER-INFO',
  'SHIPMENT_DO-CREATION': 'SHIPMENT_DO-CREATION',
  'SHIPMENT_LIVE-TRACKER': 'SHIPMENT_LIVE-TRACKER',
  'SHIPMENT_NFS-FULFILLMENT': 'SHIPMENT_NFS-FULFILLMENT',
  'SHIPMENT_TRUCK-VARIANT': 'SHIPMENT_TRUCK-VARIANT',
  'SHIPMENT_VEHICLE-ALERT': 'SHIPMENT_VEHICLE-ALERT',
  'SHIPMENT_VEHICLE-AVAILABILITY': 'SHIPMENT_VEHICLE-AVAILABILITY',
  'COMPANY-SETTING_VEHICLE-QR-CODE': 'COMPANY-SETTING_VEHICLE-QR-CODE',
  'COMPANY-SETTING_NFT-FREE-TRIAL': 'COMPANY-SETTING_NFT-FREE-TRIAL'
};

export const FEATURE_CONFIG_GROUPS = {
  ANALYTICS: 'analytics',
  COMPANY_SETTING: 'company-setting',
  FINANCE: 'finance',
  FLEET: 'fleet',
  SHIPMENT: 'shipment',
};

export const NFT_CONTRACT_LOGS_OPERATION = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
};

export const NFT_CONTRACT_EVENT_OPERATION = {
  CREATE_CONTRACT: 'create_nft_subscription',
  CREATE_NON_ENTERPRISE_CONTRACT: 'create_regular_nft_subscription',
  INACTIVE_CONTRACT: 'inactivate_nft_subscription',
  INCREMENT_SUBSCRIPTION: 'increment_subscription_quota',
  UPDATE_CONTRACT: 'update_nft_subscription',
  UPDATE_CYCLE: 'update_nft_subscription_cycle',
  UPDATE_SUBSCRIPTION: 'upgrade_subscription_quota',
};

export const NFT_MODULARITY_OPERATION = {
  INSERT: 'INSERT',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
};

/* DOCUMENT COLLECTION START */
export const PAYMENT_PROJECT_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
  DRAFT: 'DRAFT',
  UNDER_VERIFICATION: 'UNDER_VERIFICATION',
  NEED_REVISION: 'NEED_REVISION',
  VERIFIED: 'VERIFIED',
};

export const FINANCING_SUBMITTED_DOC_REFERENCE = {
  ADDITIONAL_DOCUMENTS: 'FINANCING_PROJECT_ADDITIONAL_DOCUMENTS',
  REQUESTED_DOCUMENTS: 'FINANCING_PROJECT_REQUESTED_DOCUMENTS',
};

export const PROJECT_DOCUMENT_STATUS_COLORS = {
  APPROVED: {
    color: '#2BBF8E0D',
    textColor: '#249D75',
  },
  DRAFT: {
    color: '#66686A0D',
    textColor: '#66686A',
  },
  NOT_UPLOADED: {
    color: '#028EFF0D',
    textColor: '#028EFF',
  },
  PENDING: {
    color: '#66686A0D',
    textColor: '#66686A',
  },
  REJECTED: {
    color: '#D130420D',
    textColor: '#992331',
  },
  UNDER_VERIFICATION: {
    color: '#F7B1251A',
    textColor: '#C07807',
  },
};

export const PROJECT_STATUS_COLORS = {
  ACTIVE: 'success',
  INACTIVE: 'warning',
  REJECTED: 'danger',
  SUBMITTED: 'warning',
};

export const PROJECT_LIST_STATUS_COLORS = {
  ACTIVE: 'success',
  INACTIVE: 'darkGrey',
  REJECTED: 'danger',
  SUBMITTED: 'warning',
};

export const PAYMENT_PROJECT_STATUS_COLORS = {
  [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'success',
  [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'danger',
  [PAYMENT_PROJECT_STATUSES.IN_REVIEW]: 'orangeyYellow',
  [PAYMENT_PROJECT_STATUSES.REJECTED]: 'danger',
};

export const QUICK_PAY_FINANCING_DOCUMENT_TYPE = {
  // MAIN DOCUMENTS
  AKTA_PENDIRIAN: 'DEED_OF_INCORPORATION',
  AKTA_PERUBAHAN_TERAKHIR: 'LATEST_DEED_OF_AMENDMENT',
  KTP_NPWP_DIRECTOR: 'KTP_NPWP_DIRECTOR',
  NPWP_COMPANY: 'NPWP_COMPANY',

  // LEGAL DOCUMENTS
  AKTA_PERUBAHAN_PERUSAHAAN: 'DEED_OF_AMENDMENT',
  NIB_OR_TDP: 'NIB',
  SIUP: 'SIUP_OR_TDP',
  SKDU: 'SKDU',

  // FINANCIAL DOCUMENTS
  BANK_STATEMENT: 'BANK_STATEMENT',
  BUKTI_PELUNASAN_HUTANG: 'CONFIRMATION_OF_LOAN_SETTLEMENT',
  DEBT_REPORT: 'DEBT_REPORT',
  FINANCIAL_REPORT: 'FINANCIAL_REPORT',

  // CUSTOMER DOCUMENTS
  INVOICE_EXAMPLE: 'INVOICE_EXAMPLE',

  // OTHER DOCUMENTS
  AGREEMENT_COOPERATION: 'AGREEMENT_COOPERATION',
  AGREEMENT_CREDIT_CHECK: 'AGREEMENT_CREDIT_CHECK',
  BALANCE_SHEET: 'BALANCE_SHEET',
  CASH_FLOW_STATEMENT: 'CASH_FLOW_STATEMENT',
  CREDIT_REPORT: 'CREDIT_REPORT',
  INCOME_STATEMENT: 'INCOME_STATEMENT',
  KTP_NPWP_COMMISSIONER: 'KTP_NPWP_COMMISSIONER',
  NPWP_CUSTOMER: 'NPWP_CUSTOMER',
  SALES_REPORT: 'SALES_REPORT',
  SK_KEMENKUMHAM: 'SK_KEMENKUMHAM',
  SK_KEMENKUMHAM_AKTA_PERUBAHAN: 'SK_KEMENKUMHAM_AKTA_PERUBAHAN',
};

export const FINANCE_PROJECT_DOCUMENT_LIST_TYPE = {
  FINANCIAL: 'FINANCIAL',
  LEGAL: 'LEGAL',
  MAIN: 'MAIN',
  CUSTOMER: 'CUSTOMER',
};

export const FINANCE_PROJECT_DOCUMENT_TYPE_NUMBER = {
  MAIN: '1',
  FINANCIAL: '2',
  LEGAL: '3',
  CUSTOMER: '4',
};

export const PROJECT_DOCUMENT_STATUSES = {
  ACTIVE: 'ACTIVE',
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE',
  NOT_UPLOADED: 'NOT_UPLOADED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REUPLOADED: 'REUPLOADED',
  REQUESTED: 'REQUESTED',
  SUBMITTED: 'SUBMITTED',
  UNDER_VERIFICATION: 'UNDER_VERIFICATION',
  NEED_REVISION: 'NEED_REVISION',
  VERIFIED: 'VERIFIED',
};

export const PAYMENT_PROJECT_SCHEME = {
  QUICK_PAY: 'QUICK_PAY',
  ENTERPRISE_REFERRAL: 'ENTERPRISE_REFERRAL',
};

export const FINANCING_SUBMITTED_DOCUMENT_ACTIONS = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

export const FINANCING_PROJECT_ASSESSMENT_ACTIONS = {
  REJECT: 'REJECT',
  APPROVE: 'APPROVE',
};

export const SHIPMENT_INVOICE_SCOPE = {
  NFT: 'NFT',
  FINANCING: 'FINANCING',
  NFT_ENTERPRISE: 'NFT_ENTERPRISE',
};

export const ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE = {
  // REQUIRED DOCUMENTS
  NPWP_COMPANY: 'NPWP_COMPANY',
  NPWP_CUSTOMER: 'NPWP_CUSTOMER',
  AKTA_PENDIRIAN: 'AKTA_PENDIRIAN',
  SK_KEMENKUMHAM: 'SK_KEMENKUMHAM',
  AKTA_PERUBAHAN_PERUSAHAAN: 'AKTA_PERUBAHAN_PERUSAHAAN',
  SK_KEMENKUMHAM_AKTA_PERUBAHAN: 'SK_KEMENKUMHAM_AKTA_PERUBAHAN',
  KTP_CUSTOMER: 'KTP_CUSTOMER',

  // OPTIONAL DOCUMENTS
  BANK_STATEMENT: 'BANK_STATEMENT',
  INVOICE_EXAMPLE: 'INVOICE_EXAMPLE',
  FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
  AGREEMENT_CUSTOMER_COOPERATION: 'AGREEMENT_CUSTOMER_COOPERATION',
  NIB_OR_TDP: 'NIB_OR_TDP',
  SIUP: 'SIUP',
  SKDU: 'SKDU',
  KTP_NPWP_DIRECTOR: 'KTP_NPWP_DIRECTOR',
  KTP_NPWP_COMMISSIONER: 'KTP_NPWP_COMMISSIONER',
  AGREEMENT_COOPERATION: 'AGREEMENT_COOPERATION',
};

export const KARGO_PLATFORM = {
  TMS_ADMIN: 'nexus-admin'
};

export const KARGO_ROLE = {
  INTERNAL: 'internal'
};

export const COMMITMENT_DO_STATUSES = {
  ACTIVE: 'ACTIVE',
  GRACE_PERIOD: 'GRACE_PERIOD',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
};

export const INDONESIA_TIMEZONE = {
  7: 'WIB',
  8: 'WITA',
  9: 'WIT',
};

export const INDONESIA_TIMEZONE_CONSTANT = {
  WIB: 'WIB',
  WITA: 'WITA',
  WIT: 'WIT',
};

export const PRICING_SCHEME = {
  PRICING_TYPE: {
    ENTERPRISE: 'ENTERPRISE',
    NON_ENTERPRISE: 'NON_ENTERPRISE',
  },
};

export const ADDITIONAL_DOCUMENT_FILE_FORMAT = {
  PDF: 'PDF',
  IMAGE: 'IMAGE',
  EXCEL: 'EXCEL',
};

export const CHIP_PROJECT_STATUS_COLORS = {
  [PAYMENT_PROJECT_STATUSES.ACTIVE]: '#05B012',
  [PAYMENT_PROJECT_STATUSES.DRAFT]: '#5952FF',
  [PAYMENT_PROJECT_STATUSES.INACTIVE]: '#05B012',
  [PAYMENT_PROJECT_STATUSES.REJECTED]: '#D13042',
  [PROJECT_DOCUMENT_STATUSES.REUPLOADED]: '#249D75',
  [PROJECT_DOCUMENT_STATUSES.REQUESTED]: '#249D75',
  [PROJECT_DOCUMENT_STATUSES.SUBMITTED]: '#058EFF',
  [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: '#0268BA',
  [PROJECT_DOCUMENT_STATUSES.NEED_REVISION]: '#FF9D05',
  [PROJECT_DOCUMENT_STATUSES.VERIFIED]: '#04610F',
};

export const DOCUMENT_REJECTION_CATEGORY = {
  DOCUMENT_IS_BLURRY: 'DOCUMENT_IS_BLURRY',
  DOCUMENT_IS_INCOMPLETE: 'DOCUMENT_IS_INCOMPLETE',
  DOCUMENT_IS_EXPIRED: 'DOCUMENT_IS_EXPIRED',
  WRONG_DOCUMENT: 'WRONG_DOCUMENT',
  OTHER: 'OTHER',
};

export const SHAREHOLDER_CHECKBOX_GROUP = {
  MANAGEMENT: 'MANAGEMENT',
  SHAREHOLDER: 'SHAREHOLDER',
};

export const DOCUMENT_DETAIL_TYPE_ENUM = {
  DEED_OF_INCORPORATION: 'DEED_OF_INCORPORATION',
  LATEST_DEED_OF_AMENDMENT: 'LATEST_DEED_OF_AMENDMENT',
  KEMENKUMHAM: 'KEMENKUMHAM',
  KTP: 'KTP',
  NIB: 'NIB',
  NPWP: 'NPWP',
};

export const DOCUMENT_CHECKING_ENUM = {
  COMPANY_NPWP_NUMBER_ALREADY_EXIST: 'COMPANY_NPWP_NUMBER_ALREADY_EXIST',
  COMPANY_NPWP_NUMBER_NOT_EXIST: 'COMPANY_NPWP_NUMBER_NOT_EXIST',
  DEED_OF_INCORPORATION_NUMBER_ALREADY_EXIST: 'DEED_OF_INCORPORATION_NUMBER_ALREADY_EXIST',
  DEED_OF_INCORPORATION_NUMBER_NOT_EXIST: 'DEED_OF_INCORPORATION_NUMBER_NOT_EXIST',
  KEMENKUMHAM_NUMBER_ALREADY_EXIST: 'KEMENKUMHAM_NUMBER_ALREADY_EXIST',
  KEMENKUMHAM_NUMBER_NOT_EXIST: 'KEMENKUMHAM_NUMBER_NOT_EXIST',
  LATEST_DEED_OF_AMENDMENT_NUMBER_ALREADY_EXIST: 'LATEST_DEED_OF_AMENDMENT_NUMBER_ALREADY_EXIST',
  LATEST_DEED_OF_AMENDMENT_NUMBER_NOT_EXIST: 'LATEST_DEED_OF_AMENDMENT_NUMBER_NOT_EXIST',
  NIB_NUMBER_ALREADY_EXIST: 'NIB_NUMBER_ALREADY_EXIST',
  NIB_NUMBER_NOT_EXIST: 'NIB_NUMBER_NOT_EXIST',
  NIK_ALREADY_EXIST: 'NIK_ALREADY_EXIST',
  NIK_MATCH_ON_DEED_INCORPORATION_AND_AMENDMENT: 'NIK_MATCH_ON_DEED_INCORPORATION_AND_AMENDMENT',
  NIK_NOT_EXIST: 'NIK_NOT_EXIST',
  NIK_NOT_MATCH_ON_DEED_INCORPORATION_OR_AMENDMENT: 'NIK_NOT_MATCH_ON_DEED_INCORPORATION_OR_AMENDMENT',
  NPWP_NUMBER_ALREADY_EXIST: 'NPWP_NUMBER_ALREADY_EXIST',
  NPWP_NUMBER_NOT_EXIST: 'NPWP_NUMBER_NOT_EXIST',
};

export const NEGATIVE_CHECK_OUTCOME = [
  DOCUMENT_CHECKING_ENUM.COMPANY_NPWP_NUMBER_ALREADY_EXIST,
  DOCUMENT_CHECKING_ENUM.DEED_OF_INCORPORATION_NUMBER_ALREADY_EXIST,
  DOCUMENT_CHECKING_ENUM.KEMENKUMHAM_NUMBER_ALREADY_EXIST,
  DOCUMENT_CHECKING_ENUM.LATEST_DEED_OF_AMENDMENT_NUMBER_ALREADY_EXIST,
  DOCUMENT_CHECKING_ENUM.NIB_NUMBER_ALREADY_EXIST,
  DOCUMENT_CHECKING_ENUM.NIK_ALREADY_EXIST,
  DOCUMENT_CHECKING_ENUM.NIK_NOT_MATCH_ON_DEED_INCORPORATION_OR_AMENDMENT,
  DOCUMENT_CHECKING_ENUM.NPWP_NUMBER_ALREADY_EXIST,
];

/* DOCUMENT COLLECTION END */
export default {};
