/* eslint-disable max-len */
import {
  AR_AGING_STATUSES,
  BROKERAGE_EXTRA_USER_ACCESS_TYPE,
  COMPANY_STATUSES,
  CUSTOMER_DIVISION,
  CUSTOMIZABLE_INVOICE_STATUSES,
  CUSTOM_INVOICE_FEE_TYPES,
  CUSTOM_INVOICE_FEE_UNITS,
  DERIVED_SHIPMENT_STATUSES,
  DISBURSEMENT_SCHEME_STATUS,
  DISBURSEMENT_SCHEME_TYPE,
  DOCUMENT_TYPES,
  DOF2_INVOICE_RECEIPT_REJECTION_REASON,
  DOF2_INVOICE_REJECTION_REASON,
  DOF2_REQUEST_ON_HOLD_REASON,
  DOF_REQUEST_REJECTION_REASON,
  DOF_SHIPMENT_STATUSES,
  DO_CANCELLATION_REASONS,
  DO_REJECTION_REASONS,
  DRIVER_STATUSES,
  FILE_UPLOAD_SOURCE,
  FIRST_MILE_USER_ACCESS_TYPE,
  GENERAL_ENTITY_STATUSES,
  GOODS_REJECTION_CATEGORY,
  INDUSTRY,
  INVOICE_PAYMENT_STATUS,
  INVOICE_STATUS,
  IS_RETURN_FEE_COVERED,
  IS_RETURN_GOODS,
  LAST_DISBURSEMENT_PPH23_DEDUCTOR,
  LOCALE_TYPE,
  LOCAL_SHIPMENT_STATUSES,
  LOCATION_FEE_TYPE,
  LOCATION_TYPE,
  NFT_MODULES,
  PANTHERA_PIC_DIVISION,
  PAYMENT_TYPES,
  PLATE_COLORS,
  PLATFORM_ACCESS_TYPE,
  PODF_INVOICE_RECEIPT_REJECTION_REASON,
  PODF_INVOICE_REJECTION_REASON,
  PPH23_DEDUCTOR,
  PRODUCT_CATEGORY,
  READINESS_SHIPMENT_STATUS,
  REIMBURSEMENT_REQUIREMENT_TYPE,
  REIMBURSEMENT_TYPE,
  SHIPMENT_DOCUMENT_TYPES,
  SHIPMENT_STATUSES,
  SHIPPER_LOCATION_OPERATING_DAY,
  SHIPPER_STATUSES,
  SHIPPER_TYPES,
  SHOW_CLAIMS_IN,
  SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE,
  SWIFT_PAYMENT_REQUEST_STATUSES,
  SWIFT_PAYMENT_TYPES,
  USER_ACCESS_TYPE,
  USER_SALES_ROLE,
  USER_STATUSES,
  VEHICLE_STATUSES,
} from '~/Configurations/constants';
import { PRICE_STATUS } from '~/Pages/Customers/CustomerDetailPage/Components/Prices/constants';

import { OVERNIGHT_PROOF_TYPE, SPECIAL_DAY_INCLUSION_TYPE } from '~/Pages/Shipper/ShipperForm/constant';
import { OWNER_TYPE } from '~/Pages/Shipper/SMEForm/constant';

/**
 * Common: for simple word translation
 */
export default {
  acceptance_date: 'Tanggal Penerimaan',
  account: 'Akun',
  account_details: 'Detail Akun',
  account_holder_name: 'Nama Pemegang Rekening',
  account_name: 'Nama Akun',
  account_number: 'Nomor Rekening',
  account_receivable: 'Akun Diterima',
  account_receiveble: 'Piutang dagang',
  action: 'Tindakan',
  activate: 'Aktifkan',
  activate_driver: 'Aktifkan Supir',
  activate_transporter: 'Aktifkan Transporter',
  activate_truck: 'Aktifkan Unit',
  active_features: 'Fitur Aktif',
  active_kir: 'KIR Aktif',
  active_lembar_pajak: 'Lembar Pajak Aktif',
  active_sim: 'SIM Aktif',
  active_stnk: 'STNK Aktif',
  activity_log_type: {
    comment: {
      UPDATE: '{{name}} meninggalkan komentar',
    },
    create_company_vehicles: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    save_brokerage_vendor: {
      INSERT: 'Transporter created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}',
    },
    save_customer: {
      INSERT: '{{name}} membuat shipper',
      UPDATE: '{{name}} menyimpan shipper',
    },
    save_warehouse: {
      INSERT: '{{name}} membuat lokasi',
      UPDATE: '{{name}} menyimpan lokasi',
    },
    save_warehouse_fee: {
      INSERT: '{{name}} membuat biaya lokasi',
      UPDATE: '{{name}} menyimpan biaya lokasi',
    },
    update_partner_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    upsert_partner_company_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    verify_company_driver: {
      UPDATE: 'Driver verified by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    verify_vendor: {
      UPDATE: 'Transporter verified by {{name}}',
    },
  },
  add_account: 'Tambah Rekening',
  add_adjustment_details: 'Tambah Detail Adjustment',
  add_bank_account: 'Tambah Rekening Bank',
  add_claims: '+ Tambahkan Klaim',
  add_column: 'Tambah Kolom',
  add_company_document: 'Tambahkan Dokumen Perusahaan',
  add_contact: '+ Tambah kontak',
  add_contract_document: 'Tambahkan Dokumen Kontrak',
  add_costs: 'Tambah biaya',
  add_customer: 'Tambah Customer',
  add_days: 'Tambah Hari',
  add_document: 'Tambah Dokumen',
  add_document_resturn_sla: 'Tambahkan SLA Pengembalian Dokumen',
  add_employee: 'Tambah Karyawan',
  add_fee: 'Masukan Biaya',
  add_fee_for_truck_type: 'Masukkan Biaya Tambahan {{name}}',
  add_invoice: 'Tambah Invoice',
  add_kargo_fee_adjustment: 'Tambah Detail Kargo Fee Adjustment',
  add_location: 'Menambahkan lokasi',
  add_logo: 'Tambah Logo',
  add_pic_contact: 'Tambah Kontak PIC',
  add_pod: 'Tambah POD',
  add_pph23: 'Tambah PPh23',
  add_shipper_employee: 'Add Shipper\'s Team',
  add_transporter: 'Tambah Transporter',
  add_unit: 'Tambah Unit',
  add_warehouse: 'Tambah Gudang',
  added_at: 'Ditambahkan pada',
  added_the_driver: 'Tambah Supir',
  additional_account: 'Rekening Tambahan',
  additional_charge: 'Biaya Tambahan',
  additional_fee: 'Biaya Tambahan',
  additional_fee_type: {
    cancellation: {
      TRUCK_ARRIVAL: 'Pembatalan setelah truk tiba di lokasi muat',
      TRUCK_DETAILS_CONFIRMED: 'Pembatalan setelah detil truk terkonfirmasi',
    },
    multishipment: 'Biaya pengiriman lebih dari satu',
    overnight: {
      LOADING: 'Biaya inap saat muat',
      UNLOADING: 'Biaya inap saat bongkar',
    },
  },
  additional_notes: 'Catatan Tambahan',
  address: 'Alamat',
  address_details: 'Detail Alamat',
  adjustment: 'Adjustment',
  adjustment_type: 'Tipe Adjustment',
  after: 'Sesudah',
  after_en: 'After',
  aging: 'Aging',
  aging_date_of_submission: 'Tgl. Pengajuan',
  aging_disbursement_date: 'Tgl. Cair',
  aging_shipper_payment_date: 'Tgl. Pembayaran Shipper',
  aging_total: 'Jumlah',
  agreement_status: 'Status Perjanjian',
  alternative_doc: 'Dokumen Alternatif',
  amount_in_words: 'Terbilang',
  announcement: 'Pengumuman',
  applied_dof2_invoice_details: 'Rincian Invoice Diajukan',
  applied_dof_shipment_list_details: 'Rincian Pengiriman yang Diajukan',
  applied_podf_invoice_details: 'Rincian Invoice Diajukan',
  apply: 'Terapkan',
  apply_swift_payment: 'Ajukan Pembayaran Cepat',
  applying_for_dof_2nd_disbursement: 'Mengajukan DOF Pencairan ke-2',
  applying_for_podf: 'Mengajukan PODF',
  applying_for_swift_payment: 'Mengajukan Pembayaran Cepat',
  approved_dof2_invoice_details: 'Rincian Invoice Disetujui',
  approved_dof_shipment_list_details: 'Rincian Pengiriman yang Disetujui',
  approved_podf_invoice_details: 'Rincian Invoice Disetujui',
  ar_aging_status_map: {
    [AR_AGING_STATUSES.DISBURSED]: 'pencairan ke 1 ditransfer',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_REQUESTED]: 'pencairan ke 2 diajukan',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_APPROVED]: 'pencairan ke 2 diproses',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'pencairan ke 2 diproses',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'pencairan ke 2 ditransfer',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_REJECTED]: 'pencairan ke 2 ditolak',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_ON_HOLD]: 'pencairan ke 2 ditunda',
    [AR_AGING_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'pencairan ke 3 diproses',
    [AR_AGING_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'pencairan ke 3 ditransfer',
  },
  back: 'Kembali',
  bank: 'Bank',
  bank_account: 'Rekening Bank',
  bank_account_number: 'Nomor Rekening',
  bank_account_owner: 'Pemilik Rekening',
  bank_account_type: 'Jenis Rekening',
  bank_information_is: 'Informasi Bank',
  bank_name: 'Nama Bank',
  before: 'Sebelum',
  before_en: 'Before',
  bidding: 'Penawaran',
  billing_address: 'Alamat tagihan',
  brand_name: 'Nama merk',
  broadcast_date: 'Tanggal Broadcast',
  business_type: 'Tipe Bisnis',
  calendar_days: 'Hari-hari kalender',
  cancel: 'Batal',
  cancel_shipment: 'Batalkan Pengiriman',
  central_address: 'Alamat Pusat',
  certain_products: 'Deskripsi',
  change: 'Ubah',
  change_adjustment_details: 'Ubah Detail Adjustment',
  change_customer: 'Ubah Customer',
  change_details: 'Ubah Detail',
  change_invoice: 'Ubah Invoice',
  change_invoice_status: 'Ubah Status Invoice',
  change_password: 'Ubah Password',
  change_shipment: 'Ubah Pengiriman',
  change_status: 'Ubah Status',
  change_truck_and_driver: 'Ubah Truk & Supir',
  change_value: 'Ubah Nilai',
  change_warehouse: 'Ubah Gudang',
  changes: 'Perubahan',
  changesInCustomerPricesClaimTransferFee: 'Perubahan Harga Customer - Claim - Transfer Fee',
  check_account: 'Cek Rekening',
  choose_bank: 'Pilih bank',
  choose_company: 'Pilih Perusahaan',
  choose_driver: 'Pilih Supir',
  choose_file: 'Pilih File',
  choose_language: 'Pilih bahasa',
  choose_pod: 'Pilih SJ Bongkar',
  choose_shipment: 'Pilih Pengiriman',
  choose_shipment_from_dof: 'Pilih Pengiriman dari DOF',
  choose_truck: 'Pilih Truk',
  city: 'Kota',
  claim: 'Claim',
  claim_disbursement_deadline: 'Claim Disburse. Deadline',
  claim_submission_deadline: 'Claim Submission Deadline',
  claim_value: 'Nilai Klaim',
  claim_value_collection_sla: 'Klaim Pengumpulan Nilai SLA',
  claims: 'Klaim',
  clear: 'Hapus',
  close: 'Menutup',
  commitment: 'Komitmen',
  commitment_do: 'Komitmen DO',
  commitment_period: 'Periode Komitmen',
  commitment_status: 'Status Komitmen',
  company_address: 'Alamat Perusahaan',
  company_data: 'Data Perusahaan',
  company_details: 'Detail Perusahaan',
  company_document: 'Dokumen Perusahaan',
  company_document_type: 'Tipe Dokument Perusahaan',
  company_documents: 'Dokumen Perusahaan',
  company_name: 'Nama Perusahaan',
  company_number_already_exists: 'Nama Perusahaan sudah ada',
  company_settings: 'Pengaturan Perusahaan',
  company_status_map: {
    [COMPANY_STATUSES.ACTIVE]: 'Aktif',
    [COMPANY_STATUSES.CHURN]: 'Churn',
    [COMPANY_STATUSES.DEACTIVATED]: 'Dinonaktifkan',
    [COMPANY_STATUSES.FREE_TRIAL]: 'Free Trial',
    [COMPANY_STATUSES.INACTIVE]: 'Nonaktif',
    [COMPANY_STATUSES.REGISTERED]: 'Terdaftar',
  },
  confirm: 'Konfirmasi',
  confirm_proforma_invoice: 'Konfirmasi Proforma Invoice',
  confirm_your_availability: 'Konfirmasi ketersediaan Anda',
  contact: 'Kontak',
  contact_name: 'Nama Kontak',
  contacts: 'Kontak',
  container: 'Container',
  continue: 'Lanjut',
  continue_edit: 'Lanjutkan',
  contract: 'Kontrak',
  contract_document_type: 'Tipe Dokumen Kontrak',
  contract_documents: 'Dokumen Kontrak',
  contract_period: 'Periode Kontrak',
  contract_status: 'Status Kontrak',
  contract_subscription_status: 'Status Kontrak Berlangganan',
  created: 'Dibuat',
  create: 'Buat',
  create_contract: 'Buat Kontrak',
  create_commitment: 'Buat Komitmen',
  create_invoice: 'Buat Invoice',
  create_invoice_revision: 'Buat Revisi Invoice',
  create_new_customer: 'Buat Customer Baru',
  create_new_payment_scheme: 'Ya, Buat baru',
  create_new_warehouse: 'Buat Gudang Baru',
  create_post: 'Buat Post',
  create_proforma_invoice: 'Buat Proforma Invoice',
  create_subscription: 'Buat Subscription',
  created_at: 'Dibuat pada',
  created_by: 'Dibuat oleh',
  created_do: 'DO Dibuat',
  custom_invoice_fee_form_map: {
    amount: {
      label: 'Nominal Biaya',
      placeholder: 'Ketik nominal biaya',
    },
    fee_type: {
      label: 'Tipe Biaya',
      placeholder: '',
    },
    notes: {
      label: 'Keterangan',
      placeholder: 'Ketik tambahan informasi terkait biaya',
    },
    PER_CBM: {
      label: 'Volume CBM (Tidak wajib)',
      placeholder: 'Ketik volume dalam meter kubik',
    },
    PER_KG: {
      label: 'Kilograms (Tidak wajib)',
      placeholder: 'Ketik bobot muatan dalam kg\'s',
    },
    total_amount: {
      label: 'Total Biaya',
      placeholder: '',
    },
  },
  custom_invoice_fee_types: {
    [CUSTOM_INVOICE_FEE_TYPES.LOADING_FEE]: 'Biaya Muat',
    [CUSTOM_INVOICE_FEE_TYPES.OTHER_FEE]: 'Biaya Lain',
    [CUSTOM_INVOICE_FEE_TYPES.OVERNIGHT_FEE]: 'Biaya Inap',
    [CUSTOM_INVOICE_FEE_TYPES.TICKET_FEE]: 'Biaya Tilang',
    [CUSTOM_INVOICE_FEE_TYPES.TRUCKING_FEE]: 'Biaya Pengiriman',
    [CUSTOM_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Biaya Bongkar',
  },
  custom_invoice_fee_units: {
    [CUSTOM_INVOICE_FEE_UNITS.PER_CBM]: 'Per Cbm',
    [CUSTOM_INVOICE_FEE_UNITS.PER_KG]: 'Per Kg',
    [CUSTOM_INVOICE_FEE_UNITS.PER_ROUTE]: 'Per Pengiriman',
  },
  custom_invoice_toast: {
    fee_create: 'Berhasil menambahkan biaya',
    fee_edit: 'Berhasil mengubah biaya',
  },
  customer: 'Customer',
  customer_details: 'Detail Customer',
  customer_division: {
    [CUSTOMER_DIVISION.FINANCE]: 'Finance',
    [CUSTOMER_DIVISION.OPERATIONS]: 'Operations',
    [CUSTOMER_DIVISION.SALES]: 'Sales',
    [CUSTOMER_DIVISION.WAREHOUSE]: 'Warehouse',
  },
  customer_information: 'Informasi Customer',
  customer_list: 'Daftar Customer',
  customer_name: 'Nama Customer',
  customer_name_slash_company: 'Nama Customer / Perusahaan',
  cv: 'CV',
  cycle_date: 'Tanggal Siklus',
  damage: 'Kerusakan',
  damage_type: 'Tipe Kerusakan',
  date: 'Tanggal',
  date_changes: 'Tanggal Perubahan',
  date_of_submission: 'Tanggal Pengajuan',
  day: 'Hari',
  days: 'Hari',
  deactivate: 'Nonaktifkan',
  deactivate_customer: 'Nonaktifkan Customer',
  deactivate_driver: 'Nonaktifkan Supir',
  deactivate_employee: 'Nonaktifkan Karyawan',
  deactivate_payment_scheme: 'Nonaktifkan Skema Pembayaran',
  deactivate_transporter: 'Nonaktifkan Transporter',
  deactivate_truck: 'Nonaktifkan Unit',
  deactivate_warehouse: 'Nonaktifkan Gudang',
  default_account: 'Rekening Utama',
  delay_submission: 'Tunda Pengiriman',
  delete: 'Hapus',
  delete_account: 'Hapus Rekening',
  delete_fee: 'Hapus Biaya',
  delete_invoice: 'Hapus Invoice',
  delivery_order: 'Delivery Order',
  description: 'Deskripsi',
  destination: 'Tujuan',
  destination_warehouse_name: 'Tujuan',
  detail: 'Rincian',
  detail_of_rejection: 'Penolakan Dokumen',
  detail_title: 'Detail',
  disable_nexus_access_for_shipper: 'Non-aktifkan Akses Nexus for Shipper?',
  disbursement_date: 'Tanggal Pencairan',
  disbursement_scheme: 'Skema Pembayaran',
  disbursement_scheme_name: 'Nama Skema',
  disbursement_scheme_stage_map: {
    do_stage: 'DO',
    emoney_stage: 'E-Money',
    invoice_paid_stage: 'Invoice Paid',
    invoice_sent_stage: 'Invoice Sent',
    pod_digital_stage: 'POD Digital',
    pod_physical_stage: 'POD Physical',
    pol_stage: 'POL',
  },
  disbursement_scheme_status_map: {
    [DISBURSEMENT_SCHEME_STATUS.ACTIVE]: 'Aktif',
    [DISBURSEMENT_SCHEME_STATUS.INACTIVE]: 'Inaktif',
  },
  disbursement_scheme_type_map: {
    [DISBURSEMENT_SCHEME_TYPE.BROKERAGE]: 'Brokerage',
    [DISBURSEMENT_SCHEME_TYPE.FINANCING]: 'Financing',
    [DISBURSEMENT_SCHEME_TYPE.MARKETPLACE]: 'Marketplace',
  },
  disbursement_stages: 'Tahap Pembayaran',
  district: 'Kecamatan',
  division: 'Divisi',
  do: 'DO',
  do_cancellation_reason_map: {
    [DO_CANCELLATION_REASONS.ALLOCATION_REVISION]: 'Perubahan alokasi, perubahan shipment',
    [DO_CANCELLATION_REASONS.COMPLIANCE_ISSUE]: 'Issue compliance',
    [DO_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Bencana alam',
    [DO_CANCELLATION_REASONS.FULL_STOCK]: 'Full stock at customer',
    [DO_CANCELLATION_REASONS.LOADING_DELAY]: 'Masalah proses loading di pabrik',
    [DO_CANCELLATION_REASONS.NO_EMPTY_JUGS]: 'Tidak ada galon kosong (khusus kiriman galon)',
    [DO_CANCELLATION_REASONS.NO_PALLET_JUGRACK]: 'Tidak ada pallet / jugrack tersedia',
    [DO_CANCELLATION_REASONS.NO_STOCK]: 'Tidak ada stock di pabrik / production issue',
    [DO_CANCELLATION_REASONS.OTHER_REASON]: 'Alasan lain yang tidak disebutkan diatas',
    [DO_CANCELLATION_REASONS.TKBM_ISSUE]: 'Issue TKBM',
    [DO_CANCELLATION_REASONS.TRUCK_NO_SHOW]: 'Truck tidak datang, auto expired',
  },
  do_commitment: 'Komitmen DO',
  do_contract: 'Kontrak DO',
  do_estimated_price: 'Estimated Posted Price',
  do_max_bid_limit: 'Max Bid Limit',
  do_number: 'Nomor DO',
  do_rejection_reason_map: {
    [DO_REJECTION_REASONS.DRIVER_NOT_READY]: 'Supir belum ready',
    [DO_REJECTION_REASONS.OTHER_REASON]: 'Alasan Lainnya',
    [DO_REJECTION_REASONS.VEHICLE_MAINTENANCE_NEEDED]: 'Unit truk butuh perbaikan',
    [DO_REJECTION_REASONS.VEHICLE_NOT_READY]: 'Unit truk belum ready',
  },
  do_type: 'Tipe DO',
  documents: 'Dokumen',
  doc_requirements: 'Persyaratan Dokumen',
  document: 'Dokumen',
  document_expiry_date: '{{name}} Valid Hingga',
  document_number: 'Nomor {{name}}',
  document_returns: 'Pengembalian Dokumen',
  document_type: 'Jenis Dokumen',
  document_type_map: {
    [DOCUMENT_TYPES.CAR]: 'Klaim dan Pengembalian',
    [DOCUMENT_TYPES.POD]: 'SJ Bongkar',
    [DOCUMENT_TYPES.POL]: 'SJ Muat',
    [DOCUMENT_TYPES.REJECTED_GOODS]: 'Kondisi Barang',
    [DOCUMENT_TYPES.REJECTION_LETTER]: 'Berita Acara Tolakan',
  },
  dof: 'DOF',
  dof1_details: {
    view_pod: 'Lihat SJ Bongkar',
    view_pol: 'Lihat SJ Muat',
  },
  dof2_invoice_detail: 'Rincian Invoice',
  dof2_invoice_receipt_rejection_reason_map: {
    [DOF2_INVOICE_RECEIPT_REJECTION_REASON.INVOICE_RECEIPT_BLUR]: 'Tanda Terima Invoice Buram',
    [DOF2_INVOICE_RECEIPT_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Nomor Invoice tidak sesuai dengan Tanda Terima',
    [DOF2_INVOICE_RECEIPT_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  dof2_invoice_rejection_reason_map: {
    [DOF2_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Invoice Buram',
    [DOF2_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'No. Invoice tidak ada di tanda terima Invoice',
    [DOF2_INVOICE_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  dof2_podf_details: {
    upload_pod: 'Unggah SJ Bongkar',
    view_pod: 'Lihat Surat Jalan',
  },
  dof2_podf_error_toast: {
    trucking_fee: 'Salah satu DO tidak memiliki Biaya Trucking',
    upload_pod: 'Anda perlu unggah SJ Bongkar untuk melanjutkan',
  },
  dof2_request_on_hold_reason: {
    [DOF2_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]:
      'Revisi pengajuan berdasarkan alasan yang diberikan untuk memproses pencairan',
    [DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Pengajuan Melebihi batas peminjaman uang',
    [DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Masalah Pembayaran terlambat',
    [DOF2_REQUEST_ON_HOLD_REASON.OTHER]: 'Lainnya',
  },
  dof2_request_status_map: {
    [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'Pencairan ke-2 Diproses',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'Pencairan ke-2 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'Pencairan ke-2 diajukan sebagian',
    [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'Pengajuan Ditunda',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'Ditolak',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'Pencairan ke-2 Diajukan',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'Pencairan ke-3 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'Pencairan ke-3 Diproses',
  },
  dof_2nd_disbursement: 'DOF Pencairan ke-2',
  dof_2nd_disbursement_summary: 'DOF pada Pencairan Kedua',
  dof_actual_top: 'Tanggal Pencairan DF1',
  dof_at_first_disbursement: 'DOF pada Pencairan Pertama',
  dof_request_number: 'DOF1 Request Number',
  dof_request_on_hold_reason: {
    [DOF2_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]:
      'Revisi pengajuan berdasarkan alasan yang diberikan untuk memproses pencairan',
    [DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Pengajuan Melebihi batas peminjaman uang',
    [DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Masalah Pembayaran terlambat',
    [DOF2_REQUEST_ON_HOLD_REASON.OTHER]: 'Lainnya',
  },
  dof_request_rejection_reason: {
    [DOF_REQUEST_REJECTION_REASON.OTHER]: 'Lainnya',
    [DOF_REQUEST_REJECTION_REASON.POD_BLUR]: 'SJ Bongkar buram',
    [DOF_REQUEST_REJECTION_REASON.POD_SHIPMENT_NUMBER_MISMATCH]:
      'No. Pengiriman di SJ Bongkar tidak sesuai dengan No. Pengiriman di Vendor Central',
    [DOF_REQUEST_REJECTION_REASON.POL_BLUR]: 'SJ Muat buram',
    [DOF_REQUEST_REJECTION_REASON.POL_SHIPMENT_NUMBER_MISMATCH]:
      'No. Pengiriman di SJ Muat tidak sesuai dengan No. Pengiriman di Vendor Central',
  },
  dof_shipment_statuses: {
    [DOF_SHIPMENT_STATUSES.POD_ACCEPTED]: 'SJ Bongkar Diterima',
    [DOF_SHIPMENT_STATUSES.POD_REJECTED]: 'SJ Bongkar Pernah Ditolak',
    [DOF_SHIPMENT_STATUSES.POL_ACCEPTED]: 'SJ Muat Diterima',
    [DOF_SHIPMENT_STATUSES.POL_PENDING]: 'Menunggu SJ Muat',
    [DOF_SHIPMENT_STATUSES.POL_REJECTED]: 'SJ Muat Pernah Ditolak',
  },
  done: 'Selesai',
  download: 'Download',
  download_csv: 'Unduh CSV',
  download_documents: 'Unduh Dokumen',
  download_documents_from_external: 'Unduh Dari Web',
  download_tax_invoice_file: 'Unduh Faktur Pajak',
  driver: 'Supir',
  driver_information: 'Informasi Supir',
  driver_name: 'Nama Supir',
  driver_not_found: 'Supir tidak ditemukan',
  driver_phone_number: 'Telepon Supir',
  driver_safety_req: 'Ketentuan Keamanan Pengemudi',
  driver_status_map: {
    [DRIVER_STATUSES.ACTIVE]: 'Aktif',
    [DRIVER_STATUSES.CREATED]: 'Dokumen Belum Lengkap',
    [DRIVER_STATUSES.DEACTIVATED]: 'Dinonaktifkan',
    [DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'SIM Kadaluarsa',
    [DRIVER_STATUSES.INACTIVE]: 'Nonaktif',
    [DRIVER_STATUSES.REGISTERED]: 'Terdaftar',
    [DRIVER_STATUSES.SUSPENDED]: 'Terblokir',
  },
  drivers: 'Supir',
  due_date: 'Tanggal Jatuh Tempo',
  e_money: 'E-Money',
  edit: 'Edit',
  edit_commitment_do: 'Ubah Komitmen DO',
  edit_commitment_period: 'Ubah Periode Komitmen',
  edit_contract_period: 'Ubah Periode Kontrak',
  edit_customer: 'Ubah Customer',
  edit_driver: 'Ubah Supir',
  edit_employee: 'Ubah Karyawan',
  edit_fee: 'Ubah Biaya',
  edit_increment: 'Ubah Increment',
  edit_invoice_receipt: 'Ubah Tanda Terima Invoice',
  edit_location: 'Edit Lokasi',
  edit_pic: 'Edit PIC',
  edit_scheme: 'Ubah Skema',
  edit_shipper_employee: 'Edit Shipper\'s Team',
  edit_transporter: 'Ubah Transporter',
  edit_unit: 'Ubah Unit',
  edit_warehouse: 'Ubah Gudang',
  email: 'Email',
  email_proforma_invoice: 'Email Proforma Invoice',
  employee_details: 'Detail Karyawan',
  employees: 'Karyawan',
  end: 'Akhir',
  end_date: 'Tanggal Berakhir',
  end_period: 'Akhir Periode',
  end_time: 'Waktu Berakhir',
  enterprise: 'Enterprise',
  error_nft_free_trial_date: 'Masukkan Tanggal',
  estimated: 'Estimated',
  eta: 'Perkiraan Tiba',
  external_id_label: 'Eksternal ID',
  failed_to_save_nft_modules: 'Gagal menyimpan karena terjadi pembaruan settingan awal. Harap periksa kembali.',
  fast_funding: 'Pendanaan Cepat',
  fast_payment: 'Pembayaran Cepat',
  feature: 'Fitur',
  feature_configs: 'Pengaturan Fitur',
  feature_configs_tab: {
    analytics: 'Laporan Analitik',
    back: 'Kembali ke Daftar Vendor',
    back_nfs: 'Back to Shipper List',
    'company-setting': 'Pengaturan Perusahaan',
    edit: 'Ubah Fitur',
    finance: 'Pembayaran & Keuangan',
    fleet: 'Armada',
    nfs: 'Fitur NFS',
    nft: 'Fitur NFT',
    reset: 'Reset',
    reset_success: 'Berhasil me-reset pengaturan',
    save: 'Save',
    search: 'Cari Vendor',
    shipment: 'Pengiriman',
    show_changes: 'Lihat perubahan',
    show_changes_en: 'See changes',
    vendor_name: 'Nama Vendor',
    'qr-code': 'Generate QR Code',
  },
  fee: 'Biaya',
  fee_calculation: 'Kalkulasi Biaya',
  fee_description: 'Ketik deskripsi biaya di sini',
  fee_name: 'Nama Fee',
  fee_per_point_different_city: 'Biaya per Titik Antar Kota',
  fee_per_point_within_city: 'Biaya per Titik Dalam Kota',
  fee_type: 'Jenis Biaya',
  fee_type_shipper: 'Jenis Biaya Tambahan',
  fees: 'Biaya',
  fees_required_on_truck_requirement:
    'Masukan minimal satu jenis biaya overnight, satu jenis biaya cancellation, dan semua biaya multiple shipment',
  file_not_selected_yet: 'File belum dipilih',
  file_upload_source_map: {
    [FILE_UPLOAD_SOURCE.DA]: 'Aplikasi Mitra',
    [FILE_UPLOAD_SOURCE.TMS]: 'Vendor Central',
  },
  fill_disbursement_details: 'Isi Detail Pencairan',
  fill_gmv_commitment_value: 'Isi Nilai Komitmen GMV',
  fill_truck_details: 'Isi Detail Truk',
  final_invoice: 'Invoice Final',
  find_another_payload: 'Cari muatan lain',
  first: 'Pencairan',
  first_disbursement: 'Pencairan Pertama',
  first_disbursement_date: 'Tanggal Pencairan Pertama',
  fixed: 'Tetap',
  fixed_amount: 'Jumlah Tetap',
  flat_pricing_type: 'Flat',
  for_receiver_at_destination: 'Untuk Penerima di Tujuan',
  from: 'Dari',
  from_kargo_fee: 'dari Kargo Fee',
  full_address: 'Alamat lengkap',
  full_name: 'Nama Lengkap',
  general_entity_status_map: {
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Nonaktif',
    [GENERAL_ENTITY_STATUSES.REGISTERED]: 'Aktif',
  },
  gmv_amount_monthly: 'Nilai GMV Bulanan',
  give_shipper_access_to_nfs: 'Berikan Shipper Akses ke NFS',
  gmv_commitment_value: 'Nilai Komitmen GMV',
  goods_capacity: 'Kapasitas Barang',
  goods_near_expiry_date: 'Barang Hampir Kadaluarsa',
  goods_rejection_category_map: {
    [GOODS_REJECTION_CATEGORY.CLAIM]: 'Klaim',
    [GOODS_REJECTION_CATEGORY.NO_CLAIM]: 'Tanpa Klaim',
    [GOODS_REJECTION_CATEGORY.REPACKING]: 'Kemasan Ulang',
  },
  goods_too_few: 'Barang Kurang',
  goods_too_many: 'Barang Terlalu Banyak',
  goods_type: 'Jenis Barang',
  group_load_id: 'Group Load ID',
  group_load_number: 'Group Load Number',
  help: 'Bantuan',
  hide: 'Sembunyikan',
  hold_reason: 'Alasan Penundaan',
  hold_request: 'Tunda Pengajuan',
  hours: 'Jam',
  id_card: 'KTP',
  immediately_confirm: 'Segera konfirmasi',
  include_in_calculation: 'Masukkan dalam perhitungan',
  increment: 'Increment',
  inactive: 'Nonaktifkan',
  industry: 'Industri',
  industry_map: {
    [INDUSTRY.BROKER_TRANSPORTER]: 'Broker / Transporter',
    [INDUSTRY.COMMODITY_AND_TRADING]: 'Komoditi dan Perdagangan',
    [INDUSTRY.E_COMMERCE]: 'E-Commerce',
    [INDUSTRY.FMCG]: 'FMCG',
    [INDUSTRY.LOGISTIC_COMPANY]: 'Perusahaan Logistik',
    [INDUSTRY.RAW_MATERIAL_OR_ORM]: 'Bahan Baku / OEM',
  },
  input_company_name_placeholder: 'Masukkan nama perusahaan',
  input_email_placeholder: 'Masukkan email',
  input_owner_name_placeholder: 'Masukkan nama pemilik',
  input_payment_info: 'Masukkan Info Pembayaran',
  input_phone_number_placeholder: 'Masukkan nomor handphone',
  invoice: 'Invoice',
  invoice_addressed_to: 'Invoice ditujukan kepada',
  invoice_amount_accepted: 'Jumlah Invoice Disetujui',
  invoice_amount_submitted: 'Jumlah Invoice Diajukan',
  invoice_code: 'Kode Faktur',
  invoice_date: 'Tanggal Invoice',
  invoice_detail: 'Rincian Tagihan',
  invoice_details: 'Detail Invoice',
  invoice_no: 'No. Invoice',
  invoice_number: 'Nomor Invoice',
  invoice_paid: 'Invoice Paid',
  invoice_payment_status_filter_map: {
    [INVOICE_PAYMENT_STATUS.PAYMENT_PENDING]: 'Belum Lunas',
    [INVOICE_PAYMENT_STATUS.PAID]: 'Invoice Lunas',
  },
  invoice_payment_status_map: {
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Di-approve',
    [INVOICE_PAYMENT_STATUS.PAID]: 'Lunas',
    [INVOICE_PAYMENT_STATUS.PAYMENT_PENDING]: 'Sudah di-Invoice',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Ditolak',
  },
  invoice_receipt: 'Tanda Terima Invoice',
  invoice_receipt_deadline: 'Batas Waktu Penerimaan Faktur',
  invoice_receipt_schedule: 'Jadwal Penerimaan Faktur',
  invoice_sent: 'Invoice Sent',
  invoice_sent_date: 'Tanggal Invoice',
  invoice_status_map: {
    [CUSTOMIZABLE_INVOICE_STATUSES.NOT_INVOICED]: 'Belum Ditagih',
    [CUSTOMIZABLE_INVOICE_STATUSES.PARTIALLY_INVOICED]: 'Ditagih Sebagian',
    [INVOICE_STATUS.NOT_INVOICED_YET]: 'Belum di-Invoice',
    [INVOICE_STATUS.PAID]: 'Lunas',
    [INVOICE_STATUS.PAYMENT_PENDING]: 'Sedang di-Invoice',
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Di-approve',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Ditolak',
    FULLY_INVOICED: 'Ditagih Sepenuhnya',
  },
  invoice_value: 'Nilai Invoice',
  invoices_details: 'Detail Faktur',
  invoicing_attention: 'Perhatian Faktur',
  is_return_fee_covered_map: {
    [IS_RETURN_FEE_COVERED.NEGOTIABLE]: 'Negotiable',
    [IS_RETURN_FEE_COVERED.NOT_COVERED]: 'Not Covered',
  },
  is_return_goods_map: {
    [IS_RETURN_GOODS.NO_RETURN]: 'Tidak Ada Pengembalian',
    [IS_RETURN_GOODS.RETURN_GOODS]: 'Kembalikan Barang',
  },
  item: 'Item',
  kargo_fee: 'Kargo Fee',
  kargo_fee_adjustment: 'Kargo Fee Adjustment',
  kir: 'KIR',
  ktp: 'KTP',
  price_sensitivity: 'Price Sensitivity',
  language: 'Bahasa',
  language_map: {
    [LOCALE_TYPE.en]: 'English',
    [LOCALE_TYPE.id]: 'Bahasa Indonesia',
  },
  last_disbursement_pph23_deductors: {
    [LAST_DISBURSEMENT_PPH23_DEDUCTOR.SHIPPER]: 'Shipper',
    [LAST_DISBURSEMENT_PPH23_DEDUCTOR.TRANSPORTER]: 'Transporter',
  },
  last_modified_by: 'Terakhir diubah oleh',
  late_fee: 'Late Fee',
  latest_pickup: 'Muat Terakhir',
  latest_number_recipients: 'Total Penerima Terbaru',
  latest_notification_sent_time: 'Waktu Notifikasi Terkirim Terbaru',
  latitude: 'Latitude',
  lead_time: 'Lead Time',
  leave_page: 'Tinggalkan Halaman',
  lembar_pajak: 'Lembar Pajak',
  license_plate: 'No. Polisi',
  limited_hours: 'Jam Terbatas',
  line: 'Baris',
  load: 'Muat',
  load_and_unload: 'Muat & Bongkar',
  load_date_and_time: 'Tanggal & Waktu Muat',
  load_location: 'Lokasi Muat',
  load_schedule: 'Jadwal Muat',
  loading_date: 'Tanggal Muat',
  local_shipment_statuses: {
    [LOCAL_SHIPMENT_STATUSES.PLANNED]: 'Dijadwalkan',
    [LOCAL_SHIPMENT_STATUSES.ONGOING]: 'Berlangsung',
    [LOCAL_SHIPMENT_STATUSES.COMPLETED]: 'Riwayat',
  },
  location: 'Lokasi',
  location_fee: 'Tambah Biaya Lokasi',
  location_fee_type_map: {
    [LOCATION_FEE_TYPE.LOADING]: 'Muat',
    [LOCATION_FEE_TYPE.OTHER]: 'Lainnya',
    [LOCATION_FEE_TYPE.UNLOADING]: 'Bongkar',
  },
  location_name: 'Nama lokasi',
  location_pic: 'Location PIC',
  location_type: {
    [LOCATION_TYPE.HOLDING]: 'Lokasi Penitipan',
    [LOCATION_TYPE.NORMAL]: 'Normal',
    [LOCATION_TYPE.PORT]: 'Pelabuhan',
    [LOCATION_TYPE.RETURN_DEPO]: 'Return Depo',
    [LOCATION_TYPE.STORE]: 'Store',
  },
  log_changes: 'Log Perubahan',
  log_changes_detail: 'Detail Log Perubahan',
  log_name: 'Nama Log',
  login: 'Masuk',
  login_detail: 'Detail Login',
  logo: 'Logo',
  logout: 'Keluar',
  longitude: 'Longitude',
  mark_as_disbursed: 'Tandai Pencairan Ditransfer',
  mark_as_payment_received: 'Tandai pembayaran diterima',
  marketplace_budget: 'Marketplace Budget',
  max_bid_limit: 'Max Bid Limit',
  max_columns_message: 'Anda tidak boleh memiliki lebih dari 10 kolom',
  max_transporter_bid_limit: 'Max Transporter Bid Limit',
  maximum_amount: 'Jumlah maksimal',
  maximum_nfs_users: 'Maks. Pengguna NFS',
  measurement_unit: 'Satuan',
  message: 'Pesan',
  metadata_label: {
    AKTA: {
      director_name: 'Director Name',
    },
    KIR: {
      expiry_date: 'Expiry Date',
      height_in_mm: 'Height',
      length_in_mm: 'Length',
      number: 'KIR Number',
      width_in_mm: 'Width',
    },
    KTP: {
      birth_date: 'Tanggal Lahir',
      NIK: 'KTP Number',
    },
    NPWP: {
      number: 'NPWP Number',
    },
    'SIUP/SIUJPT': {
      expiry_date: 'Expiry Date',
      number: 'SIUP Number',
    },
    SKB: {
      expiry_date: 'Expiry Date',
      number: 'SKB Number',
    },
    STNK: {
      expiry_date: 'Expiry Date',
      number: 'STNK Number',
      tax_expiry_date: 'Tax Expiry Date',
    },
  },
  min_quantity_to_be_paid: 'Min Quantity to be Paid',
  min_shipper_price: 'Minimum Shipper Price',
  minimum: 'Minimum',
  minimum_hours: 'Waktu Minimal',
  minimum_quantity: 'Kuantitas Minimum',
  missing_goods: 'Barang Hilang',
  mobile_no: 'No Handphone',
  month: 'Bulan',
  n_a: 'N/A',
  name: 'Nama',
  negotiation_price: 'Harga Negosiasi',
  new: 'Baru',
  new_customer: 'Customer Baru',
  new_disbursement_scheme: 'Tambah Skema',
  new_driver: 'Supir Baru',
  new_location: 'Lokasi Baru',
  new_password: 'Password Baru',
  new_scheme: 'Skema Baru',
  new_unit: 'Unit Baru',
  new_warehouse: 'Gudang Baru',
  next: 'Lanjut',
  // Group NFS translations under `nfs` namespace below because NFS use english.
  // TODO: find a better way to combine english and Indonesian translations
  nfs: {
    action: 'Action',
    additional_do: 'Additional DO',
    changes: 'Changes',
    change_log: 'Change Log',
    committed_do_details: 'Committed DO Details',
    committed_do: 'Committed DO',
    created_date: 'Created Date',
    created_do: 'Created DO',
    cycle: 'Cycle',
    edit_details: 'Edit Details',
    effective_date: 'Effective Date',
    free_do: 'Free DO',
    last_updated: 'Last Updated',
    months: 'Month(s)',
    period: 'Period',
    plan: 'Plan',
    plan_created: 'Plan created',
    remove: 'Remove',
    save: 'Save',
    start_date: 'Start Date',
  },
  nfs_activation: 'Aktivasi NFS',
  // Translation for NFS Pengaturan Fitur page.
  // Might have duplication with `nfs` namespace above because this namespace was created earlier.
  // TODO: move widely-used translation out from `nfs_module_tab` to `nfs` namespace.
  nfs_modules_tab: {
    can_only_be_selected_with: 'Hanya dapat dipilih setelah mengaktifkan: ',
    confirmation_dialog: {
      body: 'Bila Anda kembali ke Daftar Shipper, maka semua perubahaan Anda akan hilang',
      title: 'Perubahan Belum Tersimpan',
    },

    // intentionally not sorted props, group by each modules
    access_type: 'Access Types',
    fullaccess: 'Full Access',
    restricted: 'Restricted',
    logistic: 'Logistic',
    procurement: 'Procurement',
    warehouse: 'Warehouse',
    admin_3pl: '3PL Admin',
    finance: 'Finance',

    shipper_type: 'Shipper Types',
    is_enterprise_shipper: 'Enterprise Shipper',
    is_first_mile: 'First Mile',

    modules: 'Modules',
    is_analytics_enable: 'Analytics',
    is_company_constants_enable: 'Company Constants',
    custom_city_region_mapping: 'Custom City and Region',
    is_invoices_enable: 'Invoice',
    is_door_to_door_enable: 'Door to Door Order Management',

    additional_order_form_field: 'Additional Order Form Fields',
    has_do_container_number: 'Container-related Information',
    has_do_serial_number: 'Serial Number',
    has_do_po_numbers: 'PO Numbers',
    has_do_product_batch: 'Product Batch',

    contract_pricing: 'Contract Pricing',
    is_pricing_sensitive_to_sku: 'Contract Sensitive to SKU',
    is_pricing_sensitive_to_capacity: 'Contract Sensitive to Capacity',

    customize_for_transporter: 'Customization for Transporter TMS',
    start_date: 'Start Date',
    end_date: 'End Date',
    transporter_tms_from_email: 'Email Sender to Transporter TMS',
    transporter_tms_platform_name: 'Platform Name for Transporter TMS',
    transporter_tms_from_sms: 'SMS Sender for Transporter TMS',
    transporter_tms_url: 'Input URL for Transporter TMS',
    form_error_transporter_tms_from_email: 'Input Email Sender to Transporter TMS',
    form_error_format_transporter_tms_from_email: 'Invalid format. Please input according to this format x@domain.z',
    form_error_transporter_tms_platform_name: 'Input Platform Name to Transporter TMS',
    form_error_transporter_tms_from_sms: 'Input SMS Sender for Transporter TMS',
    form_error_transporter_tms_url: 'Input Url for Transporter TMS',
    form_error_do_number_custom_format: 'Input Order No. Custom Format',
    form_error_nfs_upselling_free_trial: 'Input Start Date',

    others: 'Others',
    is_dropdown_evaluate: 'Predetermined Load Rating Note',
    do_number_custom_format: 'Order No. Custom Format',
    unique_order_number: 'Unique Order No.',
    flexible_truck_type_default: 'Default On for Truck Selection by Vendor',
    nfs_upselling_free_trial: 'Free Trial Mode',
    shipment_completion_by_shipper: 'Shipment Completion by Shipper',
    is_pbi_live_soa_enable: 'PBI Live SoA',

    do_not_change_is_enterprise_shipper: 'Do not change if the Enterprise Shipper has been selected',
    do_not_change_is_first_mile: 'Do not change if the First Mile has been selected',

    enabling_fullaccess: 'Enable Full Access user access types',
    enabling_logistic: 'Enable Logistic user access types',
    enabling_procurement: 'Enable Procurement user access types',
    enabling_restricted: 'Enable Restricted user access types',
    enabling_warehouse: 'Enable Warehouse user access types',
    enabling_admin_3pl: 'Enable 3PL Admin user access types',

    enabling_is_first_mile: 'Identifies shipper as First Mile and have different dashboard',
    enabling_is_enterprise_shipper: 'Identifies shipper as Enterprise Shipper and will be used for NFT',

    enabling_has_do_container_number: 'Adding Container No, Seal No, Shipping Seal No fields',
    enabling_has_do_serial_number: 'Adding Serial Number field and allowing search by Serial Number',
    enabling_has_do_po_numbers:
      'Adding Inbound PO Number and Outbond PO Number fields and allowing search by PO Numbers',
    enabling_has_do_product_batch: 'Adding Batch field and allowing filter by Batch',

    enabling_is_pricing_sensitive_to_sku: 'Making Contract and DO pricing sensitive to SKU',
    enabling_is_pricing_sensitive_to_capacity: 'Making Contract and DO pricing sensitive to Capacity',

    enabling_transporter_tms_from_email: 'Changing the email sender, i.e. noreply@dantms.com for Danone ',
    enabling_transporter_tms_from_sms: 'Changing the SMS sender, i.e. DANTMS Admin for Danone',
    enabling_transporter_tms_platform_name: 'Changing the platform name for Transporters, e.g. DanTMS for Danone',
    enabling_transporter_tms_url: 'Changing the NFT URL, e.g. dantms.com',

    enabling_is_analytics_enable: 'Can disable/hide the Analytics tab',
    enabling_is_company_constants_enable: 'Can disable/hide the Company Settings, Location Settings, Vendor Settings',
    enabling_custom_city_region_mapping: 'Can disable/hide City and Region tab',
    enabling_is_invoices_enable: 'Can disable/hide the Invoice tab',

    enabling_is_dropdown_evaluate: 'Add preset dropdown reasons for load rating notes',
    enabling_do_number_custom_format: 'Adding an auto-generate for DO number. e.g. SFG Format',
    enabling_unique_order_number: 'Adding unique format validation for Order No.',
    enabling_flexible_truck_type_default: 'Making the default value = on for Truck Selection by Vendor',
    enabling_nfs_upselling_free_trial:
      'Providing a free trial to use services and features of Kargo Nexus for Shippers',
    enabling_shipment_completion_by_shipper:
      'When activated, Transporter & Driver could only update status until Finish Unloading of the last drop off point. Shipper should submit Evaluation to complete the Shipment.',

    enabling_this_will_effect: 'This module will affect: ',

    nfs_modules: {
      all_desktop_mobile_pages: 'All Pages on Desktop and Mobile Web',

      analytics: 'Analytics Tab',
      location_settings: 'Location Settings',
      vendor_settings: 'Vendor Settings',
      custom_city_and_region: 'Location Settings: City and Region Tab',
      invoice: 'Invoice Tab',

      predetermined_load_rating_note: 'Load Rating Form',
      order_no_custom_format: 'Order No.',
      default_on_for_truck_selection: 'Create Do Form : Truck selection by vendor toggle',

      company_constant_settings: 'Company Settings',
      company_settings: 'Company Settings : Employees Page',
      contract_creation_capacity: 'Contract creation required fields(Capacity)',
      contract_creation_sku: 'Contract creation required fields(SKU)',
      do_assignment: 'Do assignment (Contract Matching)',
      do_creation_capacity: 'Do creation required fields(Capacity)',
      do_creation_sku: 'Do creation required fields(SKU)',
      email_sender_to_transporter_tms: 'Email to Transporters on assignment and related notifications',
      order_details: 'Order Details',
      order_form: 'Order Form',
      platform_name_for_transporter_tms: ' Changing the platform name for Transporters, e.g. DanTMS for Danone',
      search_field_order_table: 'Search Field in Order Table',
      sms_sender_for_transporter_tms: 'SMS to Transporters on assignment and related notifications',
      url_for_transporter_tms: 'NFT Platform URL',
      more_filter: 'More Filter',
      admin_3pl: {
        1: 'Restriction to view Reports, Invoice and Register Employee, Vendors & Company Settings',
        2: 'Restriction to Shipment Evaluation',
        3: 'Access to Create, Allocate and Manage Orders',
        4: 'Access to Live Monitoring',
      },
    },
  },
  nfs_user_creation_limit: 'Batas pembuatan pengguna NFS',
  nft: {
    active: 'Aktif',
    non_active: 'Tidak Aktif',
    grace_period: 'Masa Tenggang',
    suspended: 'Ditangguhkan',
    committed: 'Komitmen',
    non_enterprise: 'Non Enterprise',
    enterprise: 'Enterprise',
    deactivate_submit: 'Ya, Nonaktifkan',
  },
  nft_modules_tab: {
    enabling_this_will_effect: 'Mengaktifkan ini akan memunculkan: ',
    can_only_be_selected_with: 'Hanya dapat dipilih setelah mengaktifkan: ',
    must_be_selected_with: 'Wajib dipilih sebelum mengaktifkan: ',
    confirmation_dialog: {
      title: 'Perubahan Belum Tersimpan',
      body: 'Bila Anda kembali ke daftar vendor, maka semua perubahan Anda akan hilang.',
    },
    nft_modules: {
      [NFT_MODULES.ANALYTICS]: 'Laporan Analitik',
      [NFT_MODULES.ANALYTICS_UTILIZATION]: 'Utilisasi',
      [NFT_MODULES['ANALYTICS_DO-ACCEPTANCE']]: 'Penerimaan DO',
      [NFT_MODULES['ANALYTICS_SHIPMENT-TIME-ACCURACY']]: 'Ketepatan Waktu',
      [NFT_MODULES['ANALYTICS_TRAVELLED-DISTANCE']]: 'Jarak Tempuh',
      [NFT_MODULES['ANALYTICS_WAREHOUSE-PERFORMANCE']]: 'Performa Lokasi',
      [NFT_MODULES['COMPANY-SETTING_CUSTOMER-WAREHOUSE-CREATION']]: 'Pembuatan Customer & Gudang',
      [NFT_MODULES.FINANCE_INVOICING]: 'Invoicing',
      [NFT_MODULES['FINANCE_EARNINGS-REPORT']]: 'Laporan Laba Rugi',
      [NFT_MODULES['FINANCE_SHIPMENT-INCOME']]: 'Pendapatan dari Pengiriman',
      [NFT_MODULES.FLEET_AVAILABILITY]: 'Ketersediaan Armada',
      [NFT_MODULES.FLEET_MONITORING]: 'Memantau Armada',
      [NFT_MODULES['SHIPMENT_BODY-CODE']]: 'Kode Lambung',
      [NFT_MODULES['SHIPMENT_BTMS-INTEGRATION']]: 'Pengiriman BTMS',
      [NFT_MODULES['SHIPMENT_CONTAINER-INFO']]: 'Data Terkait Container',
      [NFT_MODULES['SHIPMENT_DO-CREATION']]: 'Pembuatan Pengiriman',
      [NFT_MODULES['SHIPMENT_LIVE-TRACKER']]: 'Live Tracker',
      [NFT_MODULES['SHIPMENT_NFS-FULFILLMENT']]: 'Penerimaan Pengiriman & Bidding',
      [NFT_MODULES['SHIPMENT_TRUCK-VARIANT']]: 'Varian',
      [NFT_MODULES['SHIPMENT_VEHICLE-ALERT']]: 'Shipment Alert',
      [NFT_MODULES['SHIPMENT_VEHICLE-AVAILABILITY']]: 'Ketersediaan Armada',
      [NFT_MODULES['COMPANY-SETTING_VEHICLE-QR-CODE']]: 'Kode QR Truck',
      [NFT_MODULES['COMPANY-SETTING_NFT-FREE-TRIAL']]: 'Uji Coba Gratis',
    },
    nft_modules_effects: {
      [NFT_MODULES.ANALYTICS]: 'Tab Utilisasi di menu Laporan\nTab Utilisasi di halaman Pengaturan Perusahaan',
      [NFT_MODULES.ANALYTICS_UTILIZATION]:
        'Tab Utilisasi di menu Laporan\nTab Utilisasi di halaman Pengaturan Perusahaan',
      [NFT_MODULES['ANALYTICS_DO-ACCEPTANCE']]: 'Tab Penerimaan DO di menu Laporan',
      [NFT_MODULES['ANALYTICS_SHIPMENT-TIME-ACCURACY']]: 'Tab Ketepatan Waktu di menu Laporan',
      [NFT_MODULES['ANALYTICS_TRAVELLED-DISTANCE']]: 'Tab Jarak Tempuh di menu Laporan',
      [NFT_MODULES['ANALYTICS_WAREHOUSE-PERFORMANCE']]: 'Tab Performa Lokasi di menu Laporan',
      [NFT_MODULES['COMPANY-SETTING_CUSTOMER-WAREHOUSE-CREATION']]:
        'Pembuatan Customer Baru di halaman Daftar Customer\nPembuatan Gudang Baru di halaman Daftar Customer',
      [NFT_MODULES.FINANCE_INVOICING]: 'Menu Pembayaran',
      [NFT_MODULES['FINANCE_EARNINGS-REPORT']]: 'Tab Laba Rugi di menu Keuangan\nTab Pengeluaran di halaman Detail DO',
      [NFT_MODULES['FINANCE_SHIPMENT-INCOME']]: 'Tab Pendapatan di halaman Detail DO',
      [NFT_MODULES.FLEET_AVAILABILITY]: 'Tab Ketersediaan Armada untuk mengecek unit yang siap ditugaskan',
      [NFT_MODULES.FLEET_MONITORING]: 'Tab Memantau Armada untuk melacak truk, supir, dan pengiriman secara langsung',
      [NFT_MODULES['SHIPMENT_BODY-CODE']]:
        'Informasi Kode Lambung di tabel Pengiriman dan halaman Detail Armada\nKolom Kode Lambung pada file unduhan dari tabel pengiriman',
      [NFT_MODULES['SHIPMENT_BTMS-INTEGRATION']]: 'DO dari BTMS\nTab Insentif di menu Keuangan',
      [NFT_MODULES['SHIPMENT_CONTAINER-INFO']]:
        'Pilihan Ubah Informasi Kontainer\nInformasi Kontainer di halaman Detail DO\nInformasi Kontainer pada dropdown di halaman Pembuatan Invoice\nKolom No. Kontainer, kolom No. Segel Pelayaran, dan No. Segel Customer pada file unduhan dari tabel pengiriman',
      [NFT_MODULES['SHIPMENT_DO-CREATION']]: 'Pembuatan Pengiriman Baru di menu Pengiriman',
      [NFT_MODULES['SHIPMENT_LIVE-TRACKER']]: 'Tab Memantau Armada di menu Armada',
      [NFT_MODULES['SHIPMENT_NFS-FULFILLMENT']]: 'Tab Butuh Konfirmasi di menu Pengiriman\nMenu Penawaran',
      [NFT_MODULES['SHIPMENT_TRUCK-VARIANT']]:
        'Filter Varian pada hampir semua tab di menu Pengiriman\nInformasi Varian di pop-up Konfirmasi DO\nInformasi Varian di pop-up Isi Detail Truk\nInformasi Varian di halaman Detail DO\nKolom Varian pada tabel di menu Penawaran dan Pengiriman\n Kolom Varian pada file unduhan dari tabel pengiriman',
      [NFT_MODULES['SHIPMENT_VEHICLE-ALERT']]:
        'Informasi Ketersediaan di halaman Detail Armada\nToggle & Popup Armada Sedang Bermasalah\nPerubahan Status Log di halaman Detail DO',
      [NFT_MODULES['SHIPMENT_VEHICLE-AVAILABILITY']]:
        'Tab Ketersediaan Armada di menu Armada\nPool field di halaman Detail Armada\nPool field di Popup Edit Detail Armada',
      [NFT_MODULES['COMPANY-SETTING_VEHICLE-QR-CODE']]: 'Tombol "Lihat Kode QR Truk" di halaman Detail Truk',
      [NFT_MODULES['COMPANY-SETTING_NFT-FREE-TRIAL']]:
        'Memberikan akses ke layanan dan fitur Kargo Nexus for Transporter untuk pengguna dalam waktu yang ditentukan',
    },
  },
  no: 'Tidak',
  no_of_submission: 'No. Pengajuan',
  no_po: 'No. Po',
  notes: 'Catatan',
  notes_for_driver: 'Catatan untuk Supir',
  notification_history: 'Riwayat Notifikasi',
  notification_sent_time: 'Waktu Notifikasi Dikirim',
  number_of_recipient: 'Total Penerima',
  npwp_number: 'Nomor NPWP',
  number_of_customer_transfer: 'Jumlah Transfer Customer',
  number_of_nfs_user_accesses: 'Jumlah Akses Pengguna NFS',
  number_of_shipment: 'Jumlah Pengiriman',
  office_address: 'Alamat Kantor',
  ok: 'Ok',
  onboarding_date: 'Tanggal Orientasi',
  ongoing_shipment: 'Pengiriman Berlangsung',
  open: 'Buka',
  open_24_hrs: 'Buka 24 jam',
  operating_hours: 'Jam beroperasi',
  operational_hours: 'Jam Operasional',
  optional: 'Opsional',
  order: 'Pesanan',
  origin: 'Asal',
  origin_warehouse_name: 'Asal',
  other: 'Lainnya',
  other_document: 'Dokumen Lainnya',
  other_fee: 'Biaya Lain',
  other_notes: 'Other Notes',
  overnight_proof_description: {
    [OVERNIGHT_PROOF_TYPE.DRIVER_APP_GPS_TIMESTAMPS]: '',
    [OVERNIGHT_PROOF_TYPE.FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY]: 'Tidak dicantumkan dalam POD',
    [OVERNIGHT_PROOF_TYPE.OTHER]: '',
    [OVERNIGHT_PROOF_TYPE.SHIPPER_APPROVAL]: '',
    [OVERNIGHT_PROOF_TYPE.WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS]: 'Ditulis dan dicap basah di POD',
  },
  overnight_proof_type: {
    [OVERNIGHT_PROOF_TYPE.DRIVER_APP_GPS_TIMESTAMPS]: 'Waktu di GPS Aplikasi Pengemudi',
    [OVERNIGHT_PROOF_TYPE.FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY]:
      'Formulir, Berita Acara, atau Bukti Menginap',
    [OVERNIGHT_PROOF_TYPE.OTHER]: 'Lainnya',
    [OVERNIGHT_PROOF_TYPE.SHIPPER_APPROVAL]: 'Persetujuan dari Shipper',
    [OVERNIGHT_PROOF_TYPE.WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS]: 'Tanggal Masuk dan Keluar Gudang',
  },
  owner_name: 'Nama Pemilik',
  owner_type: {
    [OWNER_TYPE.COMPANY]: 'Perusahaan',
    [OWNER_TYPE.PERSONAL]: 'Perseorangan',
  },
  packaging_damage: 'Kemasan Rusak',
  panthera_do_number: 'DO Number panthera',
  panthera_poc: 'Panthera POC',
  parent_company_access_settings: 'Pengaturan Akses Induk Perusahaan',
  parent_company_monthly_gmv: 'GMV Bulanan Induk Perusahaan',
  pasport_number: 'Nomor Surat Jalan',
  password: 'Password',
  payment: 'Pembayaran',
  payments: 'Payments',
  payment_and_tax_details: 'Detail Pembayaran & Pajak',
  payment_details: 'Rincian Pembayaran',
  payment_method: 'Cara Pembayaran',
  payment_scheme: 'Skema Pembayaran',
  payment_stage: 'Tahap Pembayaran',
  payment_type: {
    [PAYMENT_TYPES.LOADING_FEE]: 'Biaya Muat',
    [PAYMENT_TYPES.OTHER_FEE]: 'Biaya Lain',
    [PAYMENT_TYPES.OVERNIGHT_FEE]: 'Biaya Inap',
    [PAYMENT_TYPES.SHIPMENT_FEE]: 'Biaya Trucking',
    [PAYMENT_TYPES.TICKET_FEE]: 'Biaya Tilang',
    [PAYMENT_TYPES.UNLOADING_FEE]: 'Biaya Bongkar',
  },
  period: 'Periode',
  per_30_days: 'Per 30 Hari',
  percentage: 'Presentase',
  percentage_of_shipper_price: '% dari Harga Pengirim',
  personal_account: 'Akun Pribadi',
  phone: 'Telepon',
  phone_number: 'Nomor Handphone',
  pic: 'PIC',
  pic_name: 'Nama PIC',
  pic_phone: 'Telepon PIC',
  pkp_number: 'Nomor PKP',
  plate_color: 'Warna Plat',
  plate_color_array: {
    [PLATE_COLORS.BLACK_PLATE]: 'Hitam',
    [PLATE_COLORS.YELLOW_PLATE]: 'Kuning',
  },
  po_number: 'No. PO',
  pod_details: 'Detail POD',
  pod_digital: 'POD Digital',
  pod_number: 'Nomor POD',
  pod_physical: 'POD Physical',
  pod_returns: 'POD Returns',
  podf: 'PODF',
  podf_actual_top: 'Tanggal Pencairan PF1',
  podf_first_disbursement_formula: '80% Invoice + PPN - PPH - Kargo Fee - VAT',
  podf_invoice_receipt_rejection_reason_map: {
    [PODF_INVOICE_RECEIPT_REJECTION_REASON.INVOICE_RECEIPT_BLUR]: 'Tanda Terima Invoice Buram',
    [PODF_INVOICE_RECEIPT_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Nomor Invoice tidak sesuai dengan Tanda Terima',
    [PODF_INVOICE_RECEIPT_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  podf_invoice_rejection_reason_map: {
    [PODF_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Invoice Buram',
    [PODF_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'No. Invoice tidak ada di tanda terima Invoice',
    [PODF_INVOICE_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  podf_request_number: 'PF1 Request Number',
  podf_request_on_hold_reason: {
    [DOF2_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]:
      'Revisi pengajuan berdasarkan alasan yang diberikan untuk memproses pencairan',
    [DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Pengajuan Melebihi batas peminjaman uang',
    [DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Masalah Pembayaran terlambat',
    [DOF2_REQUEST_ON_HOLD_REASON.OTHER]: 'Lainnya',
  },
  pol: 'POL',
  postcode: 'Kodepos',
  pph: 'PPH',
  pph23: 'PPh 23',
  pph23_deductor: 'Pemotong PPh23',
  pph23_deductors: {
    [PPH23_DEDUCTOR.PARTNER]: 'Customer',
    [PPH23_DEDUCTOR.SELF]: 'PT saya',
  },
  pph23_detail: 'Detail PPh23',
  pph23_discount: 'Potongan PPh 23 kepada',
  ppn: 'PPN',
  ppn_detail: 'Tambah Detail PPN',
  preview: 'Preview',
  price: 'Harga',
  price_calculation: 'Kalkulasi Harga',
  price_per_route_status_map: {
    [PRICE_STATUS.ACTIVE]: 'Aktif',
    [PRICE_STATUS.DELETED]: 'Terhapus',
    [PRICE_STATUS.INACTIVE]: 'Tidak aktif',
  },
  price_rate_type: 'Price Rate Type',
  prices: 'Harga',
  pricing: 'Harga',
  pricing_type: 'Tipe Harga',
  priority_level: 'Tingkat Prioritas',
  product_category: 'Kategori Produk',
  product_category_map: {
    [PRODUCT_CATEGORY.ALCOHOLIC_BEVARAGES]: 'Minuman Beralkohol',
    [PRODUCT_CATEGORY.BAKERY]: 'Toko Roti',
    [PRODUCT_CATEGORY.COFFEE]: 'Kopi',
    [PRODUCT_CATEGORY.CONDIMENTS_SOUCES]: 'Bumbu & Saus',
    [PRODUCT_CATEGORY.COOKING_BAKING_INGREDIENTS]: 'Bahan Memasak & Memanggang',
    [PRODUCT_CATEGORY.DAIRY]: 'Produk Susu (Dingin)',
    [PRODUCT_CATEGORY.DIPS]: 'Dips & Spread',
    [PRODUCT_CATEGORY.DRINKING_WATER]: 'Air Minum',
    [PRODUCT_CATEGORY.FITNESS_AND_NUTRITION]: 'Kebugaran & Nutrisi',
    [PRODUCT_CATEGORY.FMCG]: 'FMCG',
    [PRODUCT_CATEGORY.FOOD]: 'Keranjang Hadiah Makanan',
    [PRODUCT_CATEGORY.FROZEN]: 'Makanan Penutup & Hal Baru Beku',
    [PRODUCT_CATEGORY.FRUIT_FLAVORED_DRINKS]: 'Minuman Rasa Buah',
    [PRODUCT_CATEGORY.FRUITS]: 'Buah & Sayuran',
    [PRODUCT_CATEGORY.GRAINS]: 'Biji-bijian, Beras & Sereal',
    [PRODUCT_CATEGORY.JUICE]: 'Jus',
    [PRODUCT_CATEGORY.MEAT]: 'Daging, Makanan Laut & Telur',
    [PRODUCT_CATEGORY.MEDICINE_AND_DRUGS]: 'Obat & Obat',
    [PRODUCT_CATEGORY.MILK]: 'Susu',
    [PRODUCT_CATEGORY.NON_DAIRY]: 'Susu Non-Susu',
    [PRODUCT_CATEGORY.NUTS]: 'Kacang & Biji',
    [PRODUCT_CATEGORY.PACKAGING]: 'Kemasan',
    [PRODUCT_CATEGORY.PASTA]: 'Pasta & Mie',
    [PRODUCT_CATEGORY.PLASTIC]: 'Pellet Plastik',
    [PRODUCT_CATEGORY.POWDERED_BEVERAGES_MIXES]: 'Campuran Minuman Serbuk',
    [PRODUCT_CATEGORY.PREPARED]: 'Makanan Jadi',
    [PRODUCT_CATEGORY.SNACKS]: 'Makanan Ringan',
    [PRODUCT_CATEGORY.SNAKES]: 'Makanan Ringan',
    [PRODUCT_CATEGORY.SODA]: 'Soda',
    [PRODUCT_CATEGORY.SOUPS]: 'Sup & Kaldu',
    [PRODUCT_CATEGORY.SPICES]: 'Bumbu & Rempah',
    [PRODUCT_CATEGORY.SPORTS_ENERGY_DRINKS]: 'Olahraga & Minuman Energi',
    [PRODUCT_CATEGORY.TEA_INFUSIONS]: 'Teh & Infus',
    [PRODUCT_CATEGORY.TOUFU]: 'Produk Tahu, Kedelai & Vegetarian',
    [PRODUCT_CATEGORY.WATER]: 'Air',
  },
  proforma_invoice: 'Proforma Invoice',
  progressive_pricing_type: 'Progresif',
  proof_of_cancellation: 'Lampiran bukti pembatalan',
  proof_of_cancellation_type: {
    DRIVER_APP_GPS: 'GPS aplikasi Driver',
    EMAIL_APPROVAL: 'Persetujuan lewat Email',
    WHATSAPP_APPROVAL: 'Persetujuan lewat WhatsApp',
  },
  province: 'Provinsi',
  pt: 'PT',
  queueing_time: 'Queueing Time',
  quota_total: 'Total Kuota',
  reactivate: 'Aktifkan',
  readiness_shipment_status_map: {
    [READINESS_SHIPMENT_STATUS.ABOUT_TO_BE_READY]: 'Segera Ready',
    [READINESS_SHIPMENT_STATUS.READY]: 'Ready',
  },
  real_add_additional_fee: 'Masukan Biaya Tambahan',
  reason: 'Alasan',
  receipt_details: 'Detail Tanda Terima',
  receiver_name: 'Nama Penerima',
  register: 'Daftar',
  register_transporter: 'Daftarkan Transporter',
  register_transporter_account: 'Daftarkan Akun',
  registered_company_name: 'Nama Perusahaan Terdaftar',
  registered_to_swift_payment_program: 'Terdaftar Program Pembayaran Cepat',
  registration_detail_copied: 'Detail Pendaftaran Berhasil Disalin',
  reimbursement_percentage: 'Persentase reimbursement',
  reimbursement_requirement: 'Syarat reimbursement',
  reimbursement_requirement_description: 'Ketik syarat reimbursement di sini',
  reimbursement_requirement_type_map: {
    [REIMBURSEMENT_REQUIREMENT_TYPE.NOT_REIMBURSABLE]: 'Tidak bisa direimburse',
    [REIMBURSEMENT_REQUIREMENT_TYPE.OTHER]: 'Lainnya',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE]: 'Bisa direimburse',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT]: 'Reimburse dengan tanda terima',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT_AND_STAMP]: 'Reimburse dengan tanda terima dan cap',
  },
  reimbursement_type: 'Tipe reimbursement',
  reimbursement_type_description: 'Ketik tipe reimbursement di sini',
  reimbursement_type_map: {
    [REIMBURSEMENT_TYPE.KG]: '{{shipmentType}}-Kg',
    [REIMBURSEMENT_TYPE.OTHER]: 'Lainnya',
    [REIMBURSEMENT_TYPE.ROUTE]: '{{shipmentType}}',
    [REIMBURSEMENT_TYPE.UNIT]: '{{shipmentType}}-Unit',
  },
  rejected: 'Ditolak',
  rejected_dof_shipment_list_details: 'Rincian Pengiriman yang Ditolak',
  rejected_podf_invoice_details: 'Rincian Invoice Ditolak',
  rejection_category: 'Kategori Penolakan',
  rejection_reason: 'Alasan Penolakan',
  remaining_do: 'DO Tersisa',
  remaining_lead_time: 'Sisa Lead Time',
  remarks: 'Keterangan',
  remove_location: 'Hapus Lokasi',
  resend_verification_code: 'Kirim ulang kode',
  return: 'Pengembalian',
  return_address: 'Return Address',
  return_deadline: 'Batas Waktu Pengembalian',
  return_fee: 'Biaya Pengembalian',
  return_schedule: 'Jadwal Kembali',
  retype_new_password: 'Ulangi Password Baru',
  retype_password: 'Ulangi Password',
  reupload: 'Unggah Ulang',
  reupload_file: 'Reupload File',
  review: 'Ulasan',
  review_document: 'Review Dokumen',
  review_pod: 'Review SJ Bongkar',
  revised_invoice: 'Invoice Revisi',
  revisi_invoice: 'Revisi Invoice',
  role: 'Wewenang',
  route: 'Rute',
  routes: 'Rute',
  safety_req: 'Ketentuan Keamanan',
  sales: 'Penjualan',
  sales_support: 'Dukungan Penjualan',
  save: 'Simpan',
  save_changes: 'Simpan Perubahan',
  save_customer: 'Simpan Customer',
  save_driver: 'Simpan Supir',
  save_location: 'Simpan Lokasi',
  save_password: 'Simpan Password',
  save_shipment: 'Simpan Pengiriman',
  save_status: 'Simpan Status',
  save_transporter: 'Simpan Transporter',
  save_success: 'Berhasil menyimpan perubahan',
  save_warehouse: 'Simpan Gudang',
  saved_warehouse: 'Gudang Tersimpan',
  scheme: 'Skema',
  scheme_detail: 'Detail Skema',
  scheme_information: 'Informasi Skema',
  scheme_name: 'Nama Skema',
  search: 'Cari',
  search_address: 'Alamat Pencarian',
  second: 'Kedua',
  second_disbursement: 'Pencairan Kedua',
  see_details: 'Lihat Detail',
  see_document_claim: 'Lihat Dokumen Claim',
  see_invoice_receipt: 'Lihat Tanda Terima Invoice',
  see_list_of_units: 'Lihat Daftar Unit',
  see_more: 'Lihat selengkapnya',
  see_revised_invoice: 'Lihat Revisi Invoice',
  select_as: 'pilih sebagai',
  select_file: 'Select File',
  select_idempotent_variables: 'Select Idempotent Variables',
  select_rate_type: 'Select a Rate Type',
  select_required_variables: 'Select Required Variables',
  send_email: 'Kirim Email',
  send_invoice_reminder: 'Kirim pengingat faktur ke kontak ini',
  settings_changes: 'Settings Changes',
  shipment: 'Pengiriman',
  shipment_denied: 'Pengiriman Ditolak',
  shipment_document_type_map: {
    [SHIPMENT_DOCUMENT_TYPES.CLAIMS_AND_RETURNS]: 'Klaim dan Pengembalian',
    [SHIPMENT_DOCUMENT_TYPES.INTERNAL_POD]: 'SJ Bongkar',
    [SHIPMENT_DOCUMENT_TYPES.INTERNAL_POL]: 'SJ Muat',
  },
  shipment_drop: 'Multi-Drop',
  shipment_no: 'No. Pengiriman',
  shipment_no_table: 'No. Pengiriman',
  shipment_number: 'Nomor Pengiriman',
  shipment_pickup: 'Jadwal Muat',
  shipment_price: 'Biaya Trucking',
  shipment_status_map: {
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di Lokasi Bongkar',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Tiba di Lokasi Muat',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truk Terpilih',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Customer Membatalkan',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Dibatalkan',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Konfirmasi Terlewat',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Selesai Proses Muat',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Selesai Proses Bongkar',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Dalam Perjalanan',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Menuju Lokasi Bongkar',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Menuju Lokasi Muat',
    [SHIPMENT_STATUSES.PLANNED]: 'Truk Belum Dipilih',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Mengalihkan Penugasan',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Pengiriman Selesai',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai Proses Muat',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai Proses Bongkar',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.UPCOMING]: 'Pengiriman Dibuat',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'POD Uploaded',
  },
  shipment_still_on_first_disbursement: 'Pengiriman masih pada Pencairan ke-1',
  shipment_summary: 'Ringkasan Kiriman',
  shipment_type: 'Jenis Pengiriman',
  shipments_already_on_2nd_disbursement: 'Pengiriman sudah pada Pencairan ke-2',
  shipper: 'Shipper',
  shipper_cancellation_fee: 'Biaya Pembatalan Shipper',
  shipper_category: 'Shipper Category',
  shipper_created: 'Shipper created',
  shipper_fault: 'Kesalahan Shipper',
  shipper_information: 'Informasi Shipper',
  shipper_list: 'Daftar Shipper',
  shipper_location_operating_day_map: {
    [SHIPPER_LOCATION_OPERATING_DAY.FRIDAY]: 'Jumat',
    [SHIPPER_LOCATION_OPERATING_DAY.HOLIDAY]: 'Libur nasional',
    [SHIPPER_LOCATION_OPERATING_DAY.MONDAY]: 'Senin',
    [SHIPPER_LOCATION_OPERATING_DAY.SATURDAY]: 'Sabtu',
    [SHIPPER_LOCATION_OPERATING_DAY.SUNDAY]: 'Minggu',
    [SHIPPER_LOCATION_OPERATING_DAY.THURSDAY]: 'Kamis',
    [SHIPPER_LOCATION_OPERATING_DAY.TUESDAY]: 'Selasa',
    [SHIPPER_LOCATION_OPERATING_DAY.WEDNESDAY]: 'Rabu',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKDAY]: 'Hari kerja',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKEND]: 'Akhir pekan',
  },
  shipper_name_slash_company: 'Nama Shipper / Perusahaan',
  shipper_overnight_fee: 'Biaya Inap Shipper',
  shipper_payment_date: 'Tanggal Pembayaran Shipper',
  shipper_payment_info: 'Info Pembayaran Shipper',
  // TODO: Add constants for these keys
  shipper_platform_access_type_map: {
    ACCOUNT_PAYABLE: 'Account Payable',
    ADMIN: 'Admin Access',
    AR: 'Account Receivable',
    CANVASSER: 'Canvasser',
    CONSIGNEE: 'Consignee',
    DOCUMENT_COLLECTION: 'Document Collection',
    FINANCE_ADMIN: 'Finance Admin',
    FREIGHT_FORWARDER: 'Freight Forwarder',
    FULL_ACCESS: 'Akses Tidak Terbatas',
    RESTRICTED_ACCESS: 'Akses Terbatas',
    SALES: 'Sales',
    SALES_SUPPORT: 'Sales Support',
    SME_SALES_EXECUTIVE: 'SME Sales Executive',
    SME_TEAM_LEADER: 'SME Team Leader',
    SME_TELESALES: 'SME Tele Sales',
    TRAFFIC_CONTROLLER: 'Traffic Controller',
    TRANSPORT_PLANNER: 'Transport Planner',
    VENDOR_MANAGEMENT: 'Vendor Management',
  },
  shipper_price: 'Harga Pengiriman',
  shipper_registration_id: 'ID Pendaftaran Pengirim',
  shipper_remarks: 'Catatan',
  shipper_status: 'Status Pengirim',
  shipper_status_map: {
    [SHIPPER_STATUSES.ACTIVE]: 'Aktif',
    [SHIPPER_STATUSES.CHURN]: 'Churn',
    [SHIPPER_STATUSES.DEACTIVATED]: 'Nonaktif',
    [SHIPPER_STATUSES.INACTIVE]: 'Tidak aktif',
    [SHIPPER_STATUSES.PENDING_ONBOARDING]: 'Orientasi Tertunda',
    [SHIPPER_STATUSES.REGISTERED]: 'Aktif',
    [SHIPPER_STATUSES.REJECTED]: 'Ditolak',
    [SHIPPER_STATUSES.SUSPENDED]: 'Tergantung',
  },
  shipper_type: 'Jenis Pengirim',
  shipper_types_map: {
    [SHIPPER_TYPES.A1]: 'A1',
    [SHIPPER_TYPES.A2]: 'A2',
    [SHIPPER_TYPES.A3]: 'A3',
  },
  show: 'Tampilkan',
  show_claims_in: 'Tunjukkan Klaim dalam',
  show_claims_in_map: {
    [SHOW_CLAIMS_IN.BERITA_ACARA]: 'Berita Acara',
    [SHOW_CLAIMS_IN.LOAD_ITEMS]: 'Load Items',
    [SHOW_CLAIMS_IN.POD]: 'POD',
  },
  signature_name: 'Nama Tertanda',
  signature_title: 'Jabatan',
  sim: 'SIM',
  skip: 'Nanti Saja',
  sku: 'SKU',
  source_of_fund: 'Source of Fund',
  source_of_leads: 'Source of Leads',
  special_day_inclusion_type: {
    [SPECIAL_DAY_INCLUSION_TYPE.NATIONAL_HOLIDAYS]: 'Hari Libur Nasional',
    [SPECIAL_DAY_INCLUSION_TYPE.SUNDAYS]: 'Hari Minggu',
  },
  sppkp: 'SPPKP',
  stage: 'Tahap',
  start: 'Mulai',
  start_date: 'Tanggal Mulai',
  start_period: 'Mulai Periode',
  start_time: 'Waktu Mulai',
  status: 'Status',
  status_change_timestamp: 'Waktu Perubahan Status',
  status_log: 'Status Log',
  stnk: 'STNK',
  store: 'Store',
  subject: 'Subject',
  submission_summary: 'Ringkasan Pengajuan',
  submission_type: 'Tipe',
  submissions_accepted: 'Pengiriman Diterima',
  submissions_status: 'Status Pengajuan',
  submissions_submitted: 'Pengiriman Diajukan',
  submit_disbursement_details: 'Kirim Detail Pencairan',
  submit_receipt_results: 'Kirim Hasil Penerimaan',
  submit_review_results: 'Kirim Hasil Review',
  submitted_bid: 'Submitted Bid',
  submitted_date: 'Tanggal Diajukan',
  submitted_payment: 'Pendanaan yang Diajukan',
  subtotal: 'Subtotal',
  successfully_saved_changes: 'Successfully saved changes',
  summary: 'Ringkasan',
  swift_payment: 'Pembayaran Cepat',
  swift_payment_price_adjustment_type_map: {
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.CLAIM]: 'Claim',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.OTHERS]: 'Lainnya',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.ADDITION_IN_DO_PRICE]: 'Penambahan Harga DO',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.REDUCE_IN_DO_PRICE]: 'Pengurangan Harga DO',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.TRANSFER_FEE]: 'Transfer Fee',
  },
  swift_payment_request_status_map: {
    [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'Pencairan ke-1 Diproses',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'Pencairan ke-1 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'Pencairan ke-2 diajukan sebagian',
    [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'Pengajuan Ditunda',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'Ditolak',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'Diajukan',
    [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'Pencairan ke-2 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'Pencairan ke-2 Diproses',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'Pencairan ke-3 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'Pencairan ke-2 Ditransfer',
  },
  swift_payment_section: {
    [SWIFT_PAYMENT_TYPES.DOF]: 'DOF',
    [SWIFT_PAYMENT_TYPES.DOF2]: 'DOF Pencairan ke-2',
    [SWIFT_PAYMENT_TYPES.PODF]: 'PODF',
  },
  tags: 'Tag',
  tax_details: 'Rincian Pajak',
  tax_document: 'Faktur Pajak',
  tax_invoice: 'Faktur Pajak',
  telephone_no: 'No. Telepon',
  term_of_payment: 'Term of Payment',
  terms_of_payment: 'Terms of Payment',
  terms_of_payment_due_date: 'Tgl. Jatuh Tempo',
  terms_of_payment_short: 'ToP',
  terms_of_payments: 'Ketentuan Pembayaran (TOP)',
  terms_of_payments_due_date: 'Tgl Jatuh Tempo TOP',
  terms_of_payments_in_days: 'Jangka Waktu',
  terms_of_payments_required_error: 'Mohon isi jumlah hari TOP dalam angka',
  things: 'hal',
  third_defrost: 'Ketiga',
  third_disbursement: 'Pencairan Ketiga',
  tila: 'TILA',
  time_to_check_proforma_invoice: 'Waktu Pengecekan Proforma invoice',
  invoice_performance_tracking_time: 'Waktu Pelacakan Performa Invoice',
  timestamp_reference: 'Referensi Waktu',
  tin: 'NPWP',
  title: 'Judul',
  to: 'Kepada',
  to_be_attached_in_invoice: 'Untuk dilampirkan dalam Faktur',
  to_be_returned_to_shipper: 'Untuk dikembalikan ke pengirim',
  tonnage: 'Tonase',
  tonnage_or_cubication: 'Tonase / Kubikasi',
  top_label: 'Jumlah Hari TOP',
  total: 'Jumlah',
  total_adjustment: 'Total Adjustment',
  total_adjustments: 'Total Adjustment',
  total_amount: 'Jumlah Total',
  total_approved_request: 'Total Pengajuan Disetujui',
  total_approved_shipment: 'Total Pengiriman Disetujui',
  total_cost: 'Total Biaya',
  total_disbursement: 'Total Pencairan',
  total_first_disbursement: 'Total Pencairan Pertama',
  total_first_disbursement_approved: 'Total Pencairan Pertama Disetujui',
  total_first_disbursement_formula: 'Pencairan Pertama - Kargo Fee - VAT',
  total_invoice: 'Jumlah Invoice',
  total_payment: 'Total Pembayaran',
  total_payment_by_shipper: 'Jumlah Pembayaran Shipper',
  total_percentage: 'Total Persentase',
  total_second_disbursement: 'Total Pencairan Kedua',
  total_second_disbursement_approved: 'Total Pencairan Kedua Disetujui',
  total_shipment: 'Total Pengiriman',
  total_submission: 'Total Pengajuan',
  total_swift_payment_submission: 'Total Pengajuan Pembayaran Cepat',
  total_third_disbursement_approved: 'Total Pencairan Ketiga Disetujui',
  transfer_date: 'tanggal transfer',
  transport_overnight_fee: 'Biaya Inap Transporter',
  transporter: 'Transporter',
  transporter_cancellation_fee: 'Biaya Pembatalan Transporter',
  transporter_details: 'Detail Transporter',
  transporter_information: 'Informasi Transporter',
  transporter_list: 'Daftar Transporter',
  transporter_name: 'Nama Transporter',
  transporter_registration_title: 'Pendaftaran Akun Transporter',
  travelling_expenses: 'Uang Jalan',
  truck: 'Truk',
  truck_and_driver: 'Truk & Supir',
  truck_doc_req: 'Ketentuan Dokumen Truk',
  truck_not_found: 'Truk tidak ditemukan',
  // TODO: Add constants for these keys
  truck_requirement_kir_option: {
    ACTIVE_KIR: 'KIR Aktif',
    EXPIRED_KIR: 'KIR mati',
    EXPIRED_PROOF_OF_RENEWAL: 'Bukti pembaharuan mati',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    VALID_PROOF_OF_RENEWAL: 'Bukti pembaharuan aktif',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  // TODO: Add constants for these keys
  truck_requirement_lembar_pajak_option: {
    ACTIVE_LEMBAR_PAJAK: 'Lembar Pajak Aktif',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL: 'Bukti pembaharuan aktif',
    PROOF_OF_EXPIRED_TAX: 'Bukti Pajak mati',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  // TODO: Add constants for these keys
  truck_requirement_sim_option: {
    ACTIVE_SIM: 'SIM Aktif',
    EXPIRED_SIM: 'SIM mati',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    PHOTOCOPY_OF_SIM: 'Fotokopi SIM',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  // TODO: Add constants for these keys
  truck_requirement_stnk_option: {
    ACTIVE_STNK: 'STNK Aktif',
    EXPIRED_STNK: 'STNK mati',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    TRAFFIC_TICKET_PHOTOCOPY: 'Fotokopi Tiket tilang',
    VALID_PROOF_OF_RENEWAL: 'Bukti pembaharuan aktif',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  truck_requirements: 'Persyaratan Truks',
  truck_safety_req: 'Ketentuan Keamanan Truk',
  truck_type: 'Jenis Truk',
  type: 'Jenis',
  type_of_access: 'Jenis Akses',
  type_of_business: 'Jenis Bisnis',
  type_of_company: 'Jenis Perusahaan',
  type_to_search_driver: 'Ketik 1 huruf untuk mencari supir',
  type_to_search_license_plate: 'Ketik 1 huruf untuk mencari nomor polisi',
  unit_info: 'Informasi Unit',
  unit_list: 'Daftar Unit',
  unload: 'Bongkar',
  unload_date_and_time: 'Tanggal & Waktu Bongkar',
  unload_location: 'Lokasi Bongkar',
  unloading_date: 'Tanggal Bongkar',
  unloading_time: 'Waktu Bongkar',
  upcoming_shipment: 'Pengiriman Selanjutnya',
  upload: 'Unggah',
  upload_csv: 'Unggah CSV',
  upload_document: 'Unggah Dokumen',
  upload_driving_licence: 'Unggah SIM',
  upload_file: 'Upload File',
  upload_kir: 'Unggah KIR',
  upload_location_fee: 'Biaya Unggah Lokasi',
  upload_logo: 'Unggah Logo',
  upload_message: 'Untuk mengunggah file ini, Andupdatea harus mengubah',
  upload_npwp: 'Unggah NPWP',
  upload_pod: 'Unggah POD',
  upload_receipt: 'Unggah Tanda Terima',
  upload_sppkp: 'Unggah SPPKP',
  upload_stnk: 'Unggah STNK',
  upload_tax_document: 'Unggah Faktur Pajak',
  upload_the_id_card: 'Unggah KTP',
  upload_tila: 'Unggah TILA',
  uploaded_from: 'Diunggah dari',
  use_for_timestamp: 'Gunakan sebagai penanda waktu',
  use_goods_capacity: 'Use Goods Capacity',
  user: 'User',
  user_access_type_map: {
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.ACCOUNT_PAYABLE]: 'Account Payable',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.AR]: 'Account Receivable',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.DOCUMENT_COLLECTION]: 'Document Collection',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.FINANCE_ADMIN]: 'Finance Admin',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.FINANCE]: 'Akses Finansial',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.SALES_SUPPORT]: 'Sales Support',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.SALES]: 'Sales',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRAFFIC_CONTROLLER]: 'Traffic Controller',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRANSPORT_PLANNER]: 'Transport Planner',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.VENDOR_MANAGEMENT]: 'Vendor Management',
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: 'Consignee',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: 'Freight Forwarder',
    [PANTHERA_PIC_DIVISION.CANVASSER]: 'Canvasser',
    [PLATFORM_ACCESS_TYPE.KARGO_SHIPPER_SALES]: 'Kargo Team',
    [USER_ACCESS_TYPE.ADMIN]: 'Admin',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Akses Tidak Terbatas',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Akses Terbatas',
    [USER_SALES_ROLE.SME_SALES_EXECUTIVE]: 'SME Sales Executive',
    [USER_SALES_ROLE.SME_TEAM_LEADER]: 'SME Team Leader',
    [USER_SALES_ROLE.SME_TELESALES]: 'SME Tele Sales',
  },
  user_status_map: {
    [USER_STATUSES.ACTIVE]: 'Aktif',
    [USER_STATUSES.INACTIVE]: 'Nonaktif',
    [USER_STATUSES.REGISTERED]: 'Belum Aktif',
  },
  valid_until: 'Berlaku Hingga',
  vat_kargo_fee: 'VAT Kargo Fee ',
  vehicle: 'Armada',
  vehicle_body_type: 'Karoseri',
  vehicle_status_map: {
    [VEHICLE_STATUSES.ACTIVE]: 'Aktif',
    [VEHICLE_STATUSES.DEACTIVATED]: 'Dinonaktifkan',
    [VEHICLE_STATUSES.DRAFT]: 'Draft',
    [VEHICLE_STATUSES.EXPIRED]: 'Expired',
    [VEHICLE_STATUSES.PENDING]: 'Ditunda',
    [VEHICLE_STATUSES.REGISTERED]: 'Terdaftar',
    [VEHICLE_STATUSES.REJECTED]: 'Ditolak',
  },
  vehicle_type: 'Jenis Truk',
  vehicles: 'Armada',
  vendor: 'Vendor',
  vendor_code: 'Kode vendor',
  vendor_id: 'ID vendor',
  vendor_name: 'Nama pedagang',
  verification_code: 'Kode Verifikasi',
  version: 'Versi',
  view_changes: 'Lihat Perubahan',
  view_delivery: 'Lihat Pengiriman',
  view_gmv_history: 'Lihat Riwayat GMV',
  view_notification_history: 'Lihat Riwayat Notifikasi',
  waiting_for_admin_approval: 'Menunggu Persetujuan Admin',
  waiting_for_admin_calculation: 'Menunggu Perhitungan Admin',
  warehouse: 'Gudang',
  warehouse_information: 'Informasi Gudang',
  warehouse_name: 'Nama Gudang',
  working_days: 'Hari kerja',
  wrong_goods: 'Salah Kirim barang',
  ya_sure: 'Ya, Yakin',
  year_of_manufacturing: 'Tahun Produksi',
  yes: 'Ya',
  yes_add: 'Ya, Tambah',
  yes_deactivate: 'Ya, Nonaktifkan',
  yes_fulfilled: 'Ya, Memenuhi',
  fast_transfer_deduction: 'Fast Transfer Deduction',
};
