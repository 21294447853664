import './local-env';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import '@kargotech/tms-common/Assets/Fonts/Roboto/roboto.css';
import '@kargotech/tms-ui/_settings/app.css';
import './Services/i18n';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  // const { worker } = require('./Mocks/browser');
  // worker.start({ serviceWorker: { url: '/mockServiceWorker.js' }, onUnhandledRequest: 'bypass' });
}

ReactDOM.render(
  /**
   * Currently there is a problem with antd Button that it produces warning on console's browser.
   * Track github issue here: https://github.com/ant-design/ant-design/issues/22493.
   * We will remove React.StrictMode temporaryly until it got resolved.
   */
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root')
);
