/* eslint-disable max-len */
import { FIRST_MILE_USER_ACCESS_TYPE, USER_ACCESS_TYPE } from '~/Configurations/constants';

/**
 * Message: for more complex or long sentence
 */
export default {
  add_account_helper_text: 'Tambah rekening bank yang akan dicantumkan di invoice',
  add_prices_profile: 'Mari tambahkan biaya pengiriman untuk melengkapi profil pengirim Anda',
  add_shipper_profile: 'Mari Tambahkan biaya lokasi untuk melengkapi profil pengirim Anda',
  add_shipper_team_confirmation: 'By confirming this action, you’re enabling your shipper to use Shipper Platform. Ask your shipper to open ',
  add_shipper_warehouse: 'Mari Tambahkan warehouse untuk melengkapi profil shipper ini',
  already_have_an_account: 'Sudah mempunyai akun?',
  are_u_sure_about_scheme: 'Sudah yakin dengan skema yang dibuat?',
  bank_account_successfully_saved: 'Rekening bank tersimpan',
  before_preceded_by_another_vendor: 'sebelum Anda didahului vendor lain',
  bid_loose_notification_msg_end: 'belum terpilih.',
  bid_loose_notification_msg_start: 'Tawaran Anda untuk',
  bid_win_notification_msg_end: 'terpilih!',
  bid_win_notification_msg_start: 'Selamat, tawaran Anda untuk',
  by_disabling_nfs_access_shipper_wont_be_able_to_login_as_listed_below: 'Dengan menonaktifkan akses NFS, Shipper tidak akan dapat login untuk keseluruhan project atas Induk Perusahaan yang sama seperti pada daftar di bawah ini:',
  by_reenabling_the_list_of_shipper_projects_is_as_follows: 'Dengan pengaktifan ulang akses NFS, daftar projek shipper dibawah ini:',
  can_using_nfs_in_full_make_sure_you_and_the_team_have_informed: 'Dapat kembali menggunakan NFS secara penuh. Pastikan Anda telah menginformasikan pemberitahuan ini kepada shipper dan tim terkait.',
  cancel_do_warning: 'Pengiriman ini akan tetap tersimpan, namun tidak akan bisa diaktifkan kembali.',
  change_limit_user_registration_according_to_contractual_agreement: 'Silakan ubah batas pendaftaran pengguna NFS untuk memberikan akses sesuai persetujuan kontrak dengan shipper terkait',
  change_shipment_status_message_title: 'Mengubah status akan....',
  choose_account_number: 'Pilih Nomor Rekening',
  choose_customer: 'Pilih Customer',
  choose_shipment_to_create_invoice: 'Pilih pengiriman dari satu customer',
  choose_type_of_goods: 'Pilih Jenis Barang',
  created_by_table: 'Created by {{name}} on {{date}}',
  customer_central_address_help_field: 'Alamat ini akan ditampilkan sebagai alamat customer saat Anda membuat invoice',
  deactivate_commitment_message: 'Komitmen akan dinonaktifkan dan tidak akan bisa diaktifkan kembali. Transporter ini tidak dapat membuat DO di NFT setelah komitmen dinonaktifkan',
  deactivate_commitment_success_message: 'Komitmen DO telah dinonaktifkan',
  deactivate_commitment_warning: 'Nonaktifkan komitmen sekarang?',
  deactivate_contract_message: 'Kontrak akan dinonaktifkan dan tidak akan bisa diaktifkan kembali. Transporter ini tidak dapat membuat DO di NFT setelah kontrak dinonaktifkan',
  deactivate_contract_success_message: 'Kontrak telah dinonaktifkan',
  deactivate_contract_warning: 'Nonaktifkan kontrak sekarang?',
  delete_shipment_from_invoice_warning: 'Pengiriman dan dokumen yang Anda telah masukkan untuk Invoice ini akan hilang.',
  does_document_meet_requirements: 'Apakah dokumen sudah memenuhi syarat?',
  dof2_total_disbursement_formula: 'Pencairan Pertama + Kedua + Ketiga',
  dof2_total_second_disbursement_formula: 'Pencairan Pertama + Kedua',
  download_locations_csv_msg: 'Atau unduh templatenya ',
  enter_date_to_calculate_top: 'Masukkan tanggal untuk menghitung Actual ToP',
  enter_invoice_number: 'Masukkan Nomor Invoice',
  enter_verification_code_that_we_sent_to: 'Masukkan kode verifikasi yang kami kirim ke',
  errors: {
    backend: {
      acceptDeliveryOrder: {
        9: 'Mohon maaf, pengiriman ini tidak tersedia karena sudah diterima'
      },
      activateInactiveProject: {
        460: 'Financing Project has SUBMITTED status',
      },
      approveDofRequest: {
        83: 'Pengajuan pembayaran cepat tidak terdaftar di sistem',
        84: 'Satu atau beberapa pengiriman yang ditolak bukan bagian dari pengajuan pembayaran ini'
      },
      assignPlannedShipment: {
        108: 'Shipment is not assignable(current status is not PLANNED)',
        131: 'Company Vehicle has ongoing shipment',
        132: 'Company Driver has ongoing shipment'
      },
      assignShipperSalesAccess: {
        465: 'Email sudah diambil di dalam perusahaan'
      },
      common: {
        401: 'Anda tidak memiliki izin',
        500: 'Terjadi kesalahan internal',
        '018-5500': 'Terjadi kesalahan internal'
      },
      createBillOfLading: {
        94: 'No. Bill of Lading sudah pernah digunakan sebelumnya'
      },
      createFirstMileInvoice: {
        422: 'Nomor invoice sudah ada di sistem, nomor invoice harus unik'
      },
      createSmeSales: {
        465: 'Email sudah diambil di dalam perusahaan'
      },
      dryRunNotificationScheduler: {
        422: 'Sql Query needs to be sql format'
      },
      editBillOfLading: {
        94: 'No. Bill of Lading sudah pernah digunakan sebelumnya',
        422: 'Store tidak bisa diubah'
      },
      generateCompanyHandles: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil'
      },
      inactivateActiveProject: {
        460: 'Financing Project has SUBMITTED status',
      },
      saveBrokerageVendor: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil'
      },
      saveCompanyDetails: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil'
      },
      saveCompanyVehicle: {
        1: 'Perusahaan tidak terdaftar',
        2: 'Armada belum tidak terdaftar',
        3: 'Gagal mengunggah STNK',
        4: 'Gagal mengunggah KIR',
        14: 'Armada sudah terdaftar',
        15: 'Tipe kendaraan tidak valid'
      },
      saveCompanyVehicles: {
        422: 'Ada beberapa data yang tidak valid'
      },
      saveCustomer: {
        1: 'Perusahaan tidak terdaftar',
        5: 'Perusahaan customer tidak terdaftar'
      },
      saveDeliveryOrder: {
        53: 'Lokasi bongkar tidak valid',
        54: 'Lokasi muat tidak valid',
        63: 'Tanggal bongkar tidak valid',
        66: 'Customer tidak valid',
        422: 'Invalid CSV parameter/attributes',
        482: 'Hmm you need to input this field if it is a single shipment type',
        483: 'Input a date and time format using DD-MM-YYYY HH:MM',
        484: 'Input an active Location registered in B-TMS',
        485: 'Input an active Location registered in B-TMS',
        486: 'Hmm, tidak ada pengirim dengan nama ini.',
        488: 'Hmm, this truck type doesn\'t have Overnight Fees registered for this Shipper and Shipment Type. Let\'s pick another one.',
        490: 'Hmm..this Location doesnt have a Loading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        491: 'Hmm..this Location doesnt have a Unloading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        493: 'Deadline datetime must be in DD/MM/YYYY format.',
        494: 'Input a date and time format using DD-MM-YYYY HH:MM',
        497: 'Hmm all Group Loads must be posted to Marketplace, try again with "Yes" or "TRUE"',
        498: 'Hmm Per Unit Price Rate Type cannot be posted to Marketplace, try again with "No" or "FALSE"',
        499: 'Hmm, for a Group Load, number of external identifiers must match the number of shipments (with each external identifier separated by semicolon) or must be left blank; for a non-Group Load, enter one external identifier or leave blank',
        501: 'Posted DO  must have origin or posted price.',
        502: 'Isi harga yang mau Anda posting ke Marketplace atau pakai harga rekomendasi kami.',
        505: 'Hmm, this price_rate_type doesn\'t match the Goods Capacity Unit. Pick "Per Route" (or "Route"), "Per {Capacity Unit}" (or "Capacity Unit")',
        506: 'Cannot post capacity unit.',
        507: 'Input YES/TRUE or NO/FALSE',
        508: 'Only posted DO have max transported bid price',
        509: 'Hmm the value here must match the Max Bid Limit (%)',
        510: 'Only posted DO have max bid limit percentage.',
        512: 'Hmm, enter 1 or leave blank to create a Group Load; enter more than 1 and less than 21 to create a Group Load',
        513: 'Hmm, tipe pengirimannya belum tepat. Pilih "f2w", "w2w", "f2dist", "w2dist", "f2mt", "w2depo", "f2depo", "w2mt", "mt", "gt", "multidrop", "multipickup", atau "multidrop_multipickup".',
        516: 'Hmm, tipe truk ini tidak ada. Pilih tipe truk lain, ya.',
        517: 'Hmm, subtipe truk ini tidak ada. Pilih subtipe truk lain, ya.',
        518: 'Gunakan angka 0 atau angka positif dengan maksimal 8 angka di belakang koma.',
        519: 'Hmm, this capacity_unit doesn\'t seem right. Pick "Kg", "CBM", "Unit"',
        523: 'Hmmm you must input a positive number up to 8 decimal points',
        524: 'Hmmm you must input a number from 0-100',
        525: 'Hmmm you must input a positive number up to 8 decimal points',
        526: 'Hmm, this Shipper doesn\'t have this price_rate_type registered for this shipment. Let\'s register the Shipper Price',
        530: 'Input valid shipment type',
        531: 'Invalid point type',
        532: 'Input a date and time format using YYYY-MM-DD HH:MM:SS',
        533: 'Hmm number of locations inputted must be equal to the number of pickup and dropoff points and they must be unique from each other',
        534: 'Input a Location registered in B-TMS'
      },
      saveEmployee: {
        26: 'Email atau telepon sudah digunakan'
      },
      savePartnershipPricing: {
        1001: 'Transporter Tidak Valid',
        1002: 'Harga Duplikat',
        1003: 'Harga kemitraan tidak ditemukan',
        1004: 'Gagal menghapus harga kemitraan'
      },
      saveWarehouseFee: {
        45: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
        46: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama'
      },
      uploadContainers: {
        422: 'Invalid container file'
      }
    },
    different_origin_and_destination: 'Asal dan tujuan harus berbeda',
    disbursement_form_wrong_input: 'Kesalahan bidang masukan',
    email_already_registered: 'Email sudah terdaftar',
    error_code: {
      401: 'Anda tidak memiliki izin',
      445: 'Nomor belum terdaftar.',
      500: 'Terjadi kesalahan internal'
    },
    network: {
      500: 'Terjadi kesalahan internal',
      common: 'Periksa kembali koneksi internet Anda'
    },
    otp_required_please_retry: 'Kode verifikasi tidak ditemukan. Silakan ulangi',
    phone_number_already_registered: 'Nomor sudah terdaftar',
    status_code: {
      401: 'Anda tidak memiliki izin',
      500: 'Terjadi kesalahan internal',
      '018-5500': 'Terjadi kesalahan internal'
    },
    unexpected: 'Terjadi kesalahan, Tim Kargo segera menyelidiki hal ini',
    verification_code_mismatch: 'Kode verifikasi yang anda masukkan tidak sesuai'
  },
  file_has_not_been_selected: 'File belum dipilih',
  file_should_not_contain_mixed_file_types: 'Tidak dapat mengunggah dokumen dengan jenis file yang berbeda-beda',
  fill_account_details: 'Isi Detail Akun',
  financing_adjustment_microcopy: 'Perubahan Harga Customer - Claim - Transfer Fee',
  financing_project_reactivation_success_message: 'berhasil diaktifkan',
  general_successfully_saved: '{{name}} berhasil disimpan',
  generic_add: 'Tambah {{name}}',
  generic_add_more: 'Tambah Lebih {{name}}',
  generic_edit: 'Ubah {{name}}',
  generic_select: 'Pilih {{name}}',
  generic_upload: 'Upload {{name}}',
  ineligible_dof_shipment_status: 'Hanya bisa mengajukan Pengiriman yang paling tidak POL-nya sudah diterima',
  insert_date_to_calculate_actual_top: 'Masukkan tanggal untuk menghitung Actual ToP',
  invalid_bank_account: 'Masukkan nomor rekening yang valid',
  invalid_bank_account_holder_name: 'Masukkan nama pemegang rekening yang valid',
  invalid_phone_number: 'Harus dimulai dengan tanda +',
  invoice_pdf_should_only_one_file: 'Tidak dapat menunggah lebih dari satu dokumen PDF untuk invoice',
  invoice_receipt_successfully_added: 'Tanda Terima Invoice berhasil ditambahkan',
  invoice_will_be_shown_here: 'Invoice akan tampil disini',
  kargo_fee_tooltip_msg: 'Kargo fee akan dikenakan dari hari pertama pencairan sampai pembayaran dari shipper',
  kargo_will_process_your_do: 'Kargo akan memproses pesananan Anda. Mohon menunggu untuk proses konfirmasi.',
  late_fee_tooltip_msg: 'Late fee akan dikenakan setelah hari ke-16 (dihitung dari hari pertama setelah ToP Kontraktual)',
  leave_dof2_disbursed_form_warning: 'Detail pencairan yang sudah diisi akan hilang.',
  leave_form_warning: 'Semua informasi yang telah Anda masukkan akan hilang',
  leave_post_registration_form_warning: 'Detail login tidak dapat disimpan. Pastikan Anda telah menyalin detail pendaftaran sebelum meninggalkan halaman ini',
  leave_registration_form_warning: 'Semua perubahan yang Anda buat akan hilang jika Anda meninggalkan halaman ini',
  leave_shipment_form_warning: 'Pengiriman ini tidak akan tersimpan jika Anda meninggalkan halaman ini sekarang.',
  leave_shipper_form_warning: 'Pengirim tidak akan disimpan jika Anda meninggalkan halaman ini sekarang.',
  leave_this_page_question: 'Tinggalkan halaman ini?',
  logo_upload_helper: 'Logo harus format .jpg atau .png, minimal 300 px.',
  nfs: {
    confirm_remove_plan_message: 'This will remove your plan from the list and it cannot be undone.',
    confirm_remove_plan_title: 'You’re about to remove this plan',
    form_errors: {
      committed_do_period_exists: 'You cannot have the same effective date which has existed. Please choose another start date.',
    },
  },
  nfs_maximum_access_updated_successfully: 'Akses Maksimum NFS berhasil diperbarui',
  nft: {
    alert_cant_create_commited_do_desc: 'Kontrak untuk periode ini sudah dibuat atau sedang aktif. Silakan buat kontrak di periode lain',
    alert_cant_create_commited_do_title: 'Tidak Bisa Buat Kontrak di Periode Ini',
    committed_period: 'Periode Kontrak',
    created_do: 'DO Dibuat',
    edit_committed_period: 'Periode Kontrak Baru',
    enterprise: {
      modal_create_contract_title: 'Buat Kontrak - Enterprise',
      modal_edit_period_title: 'Ubah Periode Kontrak',
      modal_edit_committed_title: 'Ubah Komitmen DO',
    },
    non_enterprise: {
      kuota_do: 'Kuota DO',
      free_kuota: 'Kuota Gratis',
      minimum_free_kuota: 'Minimum Kuota Gratis 10% dari Kuota DO',
      start_period: 'Untuk Periode',
      modal_edit_incremental_title: 'Ubah Increment DO',
      incremental_do_label: 'Incremental Kuota DO',
      incremental_multiple: '*Kelipatan 50',
      initial_kuota_do: 'Kuota DO',
      kuota_upgrade: 'Upgrade Kuota DO',
      total_kuota_upgrade: 'Total Kuota DO per Bulan',
      increment_detail: 'Rincian Increment',
    },
    upgrade_quota_period: 'Berlaku untuk Periode Kontrak',
    incremental_do: 'Incremental',
    message_committed_created: 'Kontrak berhasil dibuat',
    message_committed_updated: 'Komitmen DO berhasil diperbarui',
    message_committed_period_updated: 'Periode Komitmen berhasil diubah',
    message_upgrade_quota_succeed: 'Upgrade kuota berhasil',
    message_increment_succeed: 'Increment berhasil diperbarui',
    new_committed_period: 'Periode Komitmen Baru',
    renew_committed_period: 'Perpanjang Periode',
    start_committed_period: 'Periode Komitmen Awal',
    update_committed: {
      period_title: 'Periode Perubahan Komitmen DO',
      period_start: 'Mulai aktif di bulan ke',
      period_end: 'hingga bulan ke',
    }
  },
  no_invoice_approved: 'Tidak ada Invoice yang disetujui',
  no_invoice_rejected: 'Tidak ada Invoice yang ditolak',
  nominal_will_limit_user_access_creations_via_nfs: 'Nominal yang diinput akan membatasi jumlah pembuatan akun/user akses yang dapat dilakukan oleh shipper melalui NFS',
  not_registered_with_kargo_dof_program: 'Anda belum terdaftar program DOF Kargo',
  not_registered_with_kargo_podf_program: 'Anda belum terdaftar program PODF Kargo',
  on_hold_reason: 'Pengajuan ditunda, segera Hubungi admin Kargo',
  only_kargo_admin_can_edit: 'Hanya admin Kargo yang dapat mengubah informasi ini.',
  otp_instruction: 'Ketik password baru, lalu masukkan kode\nverifikasi yang kami kirim ke',
  panthera_do_number_info: 'Nomor DO Panthera ini akan digunakan untuk menampilkan lokasi GPS di TMS Shipper.',
  password_doesnt_match: 'Kedua password yang anda masukkan tidak sesuai',
  password_successfully_changed: 'Password berhasil diubah',
  payment_scheme_allready_taken: 'Buat skema baru dengan nama yang berbeda?',
  payment_scheme_name_duplicate: 'Nama Skema sudah digunakan',
  phone_number_placeholder: 'Ketik Nomor Handphone',
  please_make_arrangements_in_advance: 'Silakan lakukan pengaturan Induk Perusahaan Terlebih Dahulu',
  please_resubmit_rejected_documents: 'Silahkan buat pengajuan baru dan perbaiki dokumen yang ditolak',
  please_resubmit_rejected_requests: 'Silahkan buat pengajuan baru dan perbaiki dokumen untuk pengiriman yang ditolak',
  please_update_gmv_value_according_current_month: 'Mohon perbaharui nilai komitmen GMV sesuai dengan bulan berjalan',
  project_linked_with_btms: 'Projek ini bertautan dengan BTMS',
  reenable_nexus_access_for_shipper: 'Aktifkan Kembali Akses Nexus for Shipper?',
  reset_password_success: 'Password Anda berhasil diubah.',
  schema_create_confirmantion: 'Skema pembayaran akan memengaruhi uang yang ditransfer ke transporter. Pastikan semua informasi sudah benar sebelum membuat skema baru',
  search_by_company_name: 'Cari Nama Perusahaan',
  search_by_invoice_number: 'Cari No. Invoice',
  search_by_license_plate: 'Cari No. Polisi',
  search_by_name: 'Cari nama',
  search_customer: 'Cari Customer',
  search_disbursement_scheme_name_placeholder: 'Cari nama skema',
  search_shipment_placeholder: 'Cari No. Pengiriman, No. Polisi',
  search_shipper: 'Cari Shipper',
  search_transporter: 'Cari Transporter',
  search_warehouse: 'Cari Gudang',
  send_verification_code_failed: 'send_verification_code_failed',
  send_verification_code_success: 'Kode verifikasi terkirim.',
  setting_nfs_shipper_project_based_on_monthly_gmv: 'Pengaturan semua akses projek shipper NFS berdasarkan nilai komitmen GMV bulanan induk Perusahaan',
  shipment_allocation_choose_shipper_helper: 'Supir akan menerima notifikasi untuk pengarahan pengiriman ini.',
  shipment_already_requested_for_financing: 'Pengiriman sudah pernah diajukan untuk pembayaran cepat',
  shipment_fee_successfully_added: 'Biaya trucking berhasil ditambahkan.',
  shipment_successfully_cancelled: 'Pengiriman berhasil dibatalkan',
  shipment_successfully_saved: 'Pengiriman berhasil disimpan',
  shipper_already_included_on_selected_dof_request: 'Hanya bisa mengajukan untuk satu customer dalam satu pengajuan',
  shipper_failed_to_add: 'Gagal menambahkan Pengirim Perusahaan',
  shipper_failed_to_edit: 'Gagal mengedit Pengirim Perusahaan',
  shipper_gmv_successfully_set_up: 'GMV Pengirim berhasil diterapkan',
  shipper_nfs_access_successfully_deactivated: 'Akses NFS shipper berhasil dinonaktifkan',
  shipper_nfs_access_successfully_reactivated: 'Akses NFS shipper berhasil diaktifkan ulang',
  shipper_successfully_edit: 'Pengirim Perusahaan diedit',
  shipper_successfully_saved: 'Pengirim Perusahaan ditambahkan',
  shippers_able_to_log_in_when_access_is_reopened_make_sure_you_alerted: 'Shipper hanya akan dapat login setelah aktivasi akses dibuka kembali. Pastikan Anda telah memperingatkan Shipper dan tim Bisnis terkait informasi di atas.',
  showing_payment_scheme_taken_name: 'Skema yang sama pernah dibuat dengan nama {{name}}',
  skip_truck_requirement_question: 'Skip Truck Requirement?',
  skip_warehouse_question: 'Apakah Anda yakin ingin melewatkan Lokasi?',
  sme_shipper_successfully_edit: 'SME Shipper edited',
  some_file_download_failed: 'Beberapa file tidak dapat diunduh.',
  some_shipments_are_not_eligible_for_dof2: 'Pengiriman tidak akan dihitung pada pencairan ke-2. Untuk mencairkan sisa pengiriman, silahkan buat pengajuan baru ketika pengiriman sudah memenuhi syarat',
  status_successfully_changed: 'Status berhasil diubah',
  swift_payment_price_tnc: 'Nilai pencairan akan dihitung berdasarkan evaluasi dan perjanjian kerjasama Anda',
  to_get_started_fill_gmv_commitment_value: 'Untuk memulai, mohon isi Nilai Komitmen GMV dengan menekan tombol dibawah ini',
  total_percentage_scheme_text: 'Pastikan total persentase sama dengan 100% untuk membuat skema baru',
  transporter_address_for_invoice_helper: 'Tambah alamat customer yang akan dicantumkan di Invoice.',
  update_shipment_status_warning: 'Urutan status yang Anda pilih adalah sebelum status pengiriman sekarang. Apakah anda yakin ingin menambahkan status?',
  user_access_type_description_map: {
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: '-',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: '-',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Akses untuk melihat, merubah, melakukan apapun, dan mengelola karyawan lain.',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Akses untuk hanya melihat informasi pengiriman dan pembayaran.',
  },
  using_this_phone_number_to_activate: 'using this phone number to activate their account.',
  we_will_send_temporary_password: 'Kami akan mengirimkan password sementara ke nomor handphone {{name}}. {{name}} dapat masuk ke {{systemName}} dan mengganti passwordnya.',
  welcome_to_platform: 'Masuk {{name}}',
  welcome_to_vendor_central: 'Selamat datang di {{name}}!',
  welcome_to_vendor_central_description: 'Isi detail perusahaan Anda untuk mulai mengelola bisnis Anda dengan {{name}}',
  fast_transfer_deduction_tooltip_msg: 'Applied for first payment, calculated from the final PR Amount',
  irreversible_after_saving: 'Irreversible after saving the scheme',
};
