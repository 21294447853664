import React, { createContext } from 'react';
import platformConfig from '~/Configurations/Platforms';

export const AppContext = createContext(platformConfig);

function AppProvider({ children }) {
  return (
    <AppContext.Provider value={platformConfig}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
